@import '../../styles/mixins';

.weeksYear {
    @include flexBox();
    @include flexFlow(wrap);
    @include itemsGap;
}

.weeksYear__item {
    :global {
        .MuiIconButton-colorSecondary {
            display: none;
        }
        .MuiTypography-body1 {
            min-width: 45px !important;
            width: auto !important;
            padding: 0px 5px !important;
            @include flexBox(center, center);
            @include itemsFlow();
        }
        .MuiFormControlLabel-root {
            margin: 0;
        }
    }
}

.active {
    :global {
        .MuiTypography-body1 {
            @include itemsActiveFlow;
        }
    }
}
