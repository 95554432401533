@import '../../../../../styles/variables';

.validatedIconBox {
    position: absolute;
    right: -35px;
    top: 10px;
    transition: all 1s ease-in-out;
    :global {
        .MuiSvgIcon-root {
            width: 30px;
            height: auto;
        }
    }
    .gppMaybeIcon {
        fill: $text-color-warning;
    }
    &.visibleIcon {
        opacity: 1;
    }
    &.hiddenIcon {
        opacity: 0;
    }
}
