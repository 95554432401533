@import '../../../../../styles/variables.scss';

.triggersSection {
    display: flex;
    gap: 17px;
    button {
        min-width: 164px;
        height: 40px;
        color: $color-white;
        background-color: $meshbot-trigger-section;
        :global {
            .MuiSvgIcon-root {
                font-size: 24px;
            }
        }
        &:hover {
            background-color: darken($meshbot-trigger-section, 10%);
        }
    }
}

.triggersSection_exception {
    button {
        color: $color-white;
        background-color: $trigger_exception_button;

        &:hover {
            background-color: darken($trigger_exception_button, 10%);
        }
    }
}

.actionsSection {
    button {
        min-width: 164px;
        height: 40px;
        text-transform: capitalize;
        color: $color-white;
        background-color: $meshbot-action-section;

        &:hover {
            background-color: darken($meshbot-action-section, 10%);
        }

        :global {
            .MuiSvgIcon-root {
                font-size: 24px;
            }
        }
    }
}
