@import '../../../../../styles/variables';
@import '../../../../../styles/mixins';

.pluginHeader {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .pluginHeader__group {
        @include flexBox();
    }
    .pluginHeader__buttons {
        margin-bottom: 20px;
        margin-right: 20px;
    }
    .addButton {
        margin-bottom: 18px;
    }
    :global {
        .MuiButton-root {
            font-size: 14px;
            background: $color-white;
            color: $color-black;
            margin-right: 2px;
            &:hover {
                background: $color-white;
            }
            &.active,
            &.addButton {
                color: $color-white;
                background: $color-button-blue;
            }
            &.addButton {
                margin-bottom: 18px;
            }
        }
    }
}
