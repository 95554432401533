@import '../../../../../styles/variables';
@import '../../../../../styles/mixins';

.modal-script {
    &__group {
        @include flexBox();
    }

    &__confirmation {
        width: 360px;
        margin-top: 14px;
        padding: 20px;

        &-title {
            font-size: 18px;
        }

        &-buttons {
            @include flexBox(space-between);

            button {
                width: 48%;
                font-size: 14px;
            }
        }
    }
}
