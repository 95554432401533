@import 'src/styles/variables';

.expressions-wrapper {
    font-size: $field-font-size;
    padding: 6em 2.5em;
    position: relative;

    .list-header {
        margin-bottom: 2em;
    }

    .notifications-component {
        right: 0;
    }
}

.expression-item {
    background-color: $color-light-blue;
    border-radius: 1em;
    padding: 1.25em 1.25em 1.25em 2.5em;
    display: flex;
    justify-content: space-between;
    margin-bottom: 1em;

    &__data {
        display: flex;
        align-items: center;
        flex-grow: 1;
    }

    &__title,
    &__body {
        border-radius: 0.25em;
        border: 1px solid $secondary-text-color;
        padding: 0.5em 0.75em 0.5em 0.75em;
        background-color: $color-white;
    }

    &__title {
        width: 100%;
        max-width: 200px;
    }

    &__body,
    &__info {
        width: 100%;
        max-width: 600px;
    }

    &__chips-wrapper {
        margin-bottom: 5px;
    }

    &__chip {
        &.MuiChip-root.MuiChip-clickable {
            background-color: $color-button-blue;
            color: $color-white;
            font-size: 14px;
            margin-right: 5px;
            &:hover {
                background-color: $color-button-blue-hover;
            }

            svg {
                fill: $color-white;
            }
        }
    }
}

.expression-add-button {
    font-size: 12px !important;
    padding: 9px 20px !important;
    color: $color-white;
    background-color: $color-button-blue;
    border: none;
    border-radius: 0.25em;
    margin-top: 2em;
    &:hover {
        cursor: pointer;
        background-color: $color-button-blue-hover;
    }
}

form {
    &.expression-item {
        flex-direction: column;
    }
}
