.fieldWrapper {
    padding: 10px 12px;
    :global {
        .MuiInputBase-root {
            font-size: 14px;
        }
    }
    svg {
        width: 17px;
        height: 17px;
        margin-bottom: 4px;
    }
}
