.access-revoked-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    height: 100vh;
}

.box {
    width: 60%;
    border: 1px solid #ccc;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    margin-top: 20px;
}

.access-revoked-title {
    font-size: 3rem;
    margin-bottom: 10px;
    font-weight: 700;
}

.access-revoked-paragraph {
    font-size: 1.5rem;
    color: #555;
}
