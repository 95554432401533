.scalableTypesAutocomplete {
    min-width: 160px;
    margin-right: 24px;
    margin-bottom: 5px;
}

.scalableTypesInput {
    &:global(.MuiFormControl-root) {
        margin-right: 24px;
        margin-bottom: 5px;
        max-width: 100px;
    }
}
