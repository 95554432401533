@import '../../../../../styles/fonts';
@import '../../../../../styles/mixins';

.formContainer {
    max-width: 500px;
    :global {
        .MuiFormHelperText-root {
            font-size: 11px;
            @include font-normal();
        }
        .MuiFormLabel-root {
            font-size: 16px;
            @include font-medium();
            text-transform: capitalize;
        }
    }
}

.addressColumn {
    margin: 30px 0 40px;
}

.addressRow {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 20px;
    margin-top: 15px;
}

.addressRowRow {
    margin-top: 15px;
}
.accordionBox {
    margin-bottom: 30px;
    &:global(.MuiPaper-elevation1) {
        box-shadow: none;
    }
    &:global(.MuiAccordion-root:before) {
        background-color: transparent;
    }
    :global {
        .MuiAccordionSummary-root {
            padding: 0;
        }
        .MuiAccordionSummary-content {
            margin: 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
        }
        .MuiAccordionSummary-content.Mui-expanded {
            margin: 0;
        }
        .MuiAccordionSummary-root.Mui-expanded {
            min-height: 35px;
        }
        .MuiAccordionDetails-root {
            padding: 0;
        }
        .MuiButton-root {
            margin: 0 5px 0 0;
            font-size: 13px;
        }
    }
    svg {
        width: 16px;
    }
    .addressColumn {
        width: 100%;
        margin: 10px 0 20px;
    }
    h2 {
        min-width: 150px;
        margin-right: 10px;
    }
}
