@import '../../../../../styles/variables';
@import '../../../../../styles/mixins';

.prefixTemplate {
    @include flexBox(space-between, center);
    width: 240px;
    .prefixTemplate__name {
        font-size: 16px;
        word-break: break-all;
        margin-right: 10px;
    }
}
