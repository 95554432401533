@import '../../../../../../../styles/variables';
@import '../../../../../../../styles/mixins';
@import '../../../../../../../styles/fonts';

.select_container {
    @include flexBox(flex-start, center);
    @include flexFlow(wrap);
    gap: 25px;
}

.fullWidthBlock {
    width: 100%;
}
