@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';

.settings-controllers {
    display: flex;
    flex-direction: column;
    position: relative;
    min-height: 100%;
    @include font-regular();

    .settings-controllers__header {
        justify-content: start;
        padding: 19px 10px 5px;

        .header__title {
            padding-left: 24px;
            margin-bottom: 7px;
        }
    }

    .header {
        &.MuiAppBar-root {
            z-index: 999;
        }

        &__title {
            font-size: 32px;
            line-height: 39px;
            @include font-semibold();
        }

        &__btn {
            @include font-normal();
            display: flex;
            justify-content: flex-start;
            width: 240px;
            box-sizing: content-box;
            text-transform: none;
            border: 1px solid rgba($text-color-grey, 0.25);
            background-color: $color-primary-button;
            color: $color-white;
            padding: 0;

            .icon-container {
                height: 54px;
                width: 54px;
                svg {
                    fill: $color-white;
                    height: 24px;
                    width: 24px;
                }

                .disabled {
                    fill: rgba($color-black, 0.26);
                }
            }

            span {
                font-size: 16px;
                display: flex;

                a {
                    color: $text-color-blue;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 100%;
                    width: 100%;
                }
            }

            .title-container {
                flex-grow: 1;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 70%;
            }
        }

        &__btn:hover {
            background-color: $color-primary-button;
            box-shadow: 0 8px 16px rgba($text-color-grey, 0.3);
        }
    }

    .ezlo-section-content {
        padding: 20px 40px 24px;
        position: relative;
        display: flex;
        flex-direction: column;
        height: calc(100vh - 108px);
        overflow: auto;
        @include inputScrollbar;
    }
}
