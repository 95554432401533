@import '../../../../../styles/fonts.scss';
@import '../../../../../styles/variables.scss';

.templateTypesWrapper {
    h2 {
        @include font-semibold();

        text-align: center;
        color: $text-color-black;
    }

    &:global(.MuiBox-root) {
        max-width: 500px;
        min-width: 500px;
    }
}

.templateTypesItemWrapper {
    display: flex;
    justify-content: space-between;
}

.templateTypesCloseBtn {
    &:global(.MuiButtonBase-root) {
        svg {
            &:global(.MuiSvgIcon-root) {
                font-size: 20px;
            }
        }
    }
}
