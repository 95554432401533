@import '../../../../../styles/fonts';
@import '../../../../../styles/variables';

.invalidPassResetLink {
    display: flex;
    align-items: center;
    flex-direction: column;
    :global {
        .MuiButton-contained {
            color: $color-white;
            background-color: $text-color-warning;
            @include font-medium();
            height: 56px;
            font-size: 16px;
            box-shadow: none;
            &:hover {
                background-color: #fe5c15;
                box-shadow: none;
            }
        }

        .MuiButtonBase-root {
            margin-top: 68px;
            @media (max-width: $break-m-l) {
                margin-top: 30px;
            }
        }
    }
    .img {
        margin: 52px;
        @media (max-width: $break-m-l) {
            margin: 30px;
        }
    }
}
