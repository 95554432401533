@import '../../../../../../../../../styles/variables.scss';

.controllerWrapper {
    display: flex;
    width: 100%;
    align-items: center;

    .controllerIcon {
        position: relative;
        margin-right: 10px;
        &:after {
            position: absolute;
            top: -8px;
            right: -6px;
            content: '';
            border-radius: 50%;
            border: 2px solid white;
            box-sizing: content-box;
            width: 8px;
            height: 8px;
            background: #ec6732;
        }
        .controllerIconSvg {
            position: relative;
            height: 24px;
            width: 24px;
            fill: rgba(88, 88, 88, 0.8);
        }
    }

    .controllerIcon.online {
        &:after {
            background-color: $color-online;
        }
        .controllerIconSvg {
            fill: $text-color-blue;
        }
    }

    .controllerSerial {
        margin-right: 24px;
        font-family: 'Ezlo Sans';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
        color: #000;
    }

    .controllerSerial.offline {
        opacity: 0.5;
    }

    .dividerWrapper {
        width: 100%;
        :global {
            .MuiDivider-root {
                width: 100%;
                background-color: #e4e4e4;
            }
        }
    }
}
