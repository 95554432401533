@import '../../../../styles/variables.scss';

.installer-access-rights {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
}

.installer-dealer-text {
    font-size: 18px;
    color: #1c1e21;
}

.installer-access-time {
    padding: 16px;
    font-size: 13px;
    color: #fe5c15;
    display: flex;
    align-items: center;

    .control-info-icon {
        margin-right: 5px;
    }
}

.dropdown {
    font-size: 18px;
}

.dropdown-container {
    width: 35%;
}

.installer-access-container {
    width: 85%;
    background-color: white;
    box-shadow: 1px 1px 15px rgba(160, 160, 160, 0.3);
    border-radius: 16px;
    padding: 15px;
    margin-top: 20px;
}

.button-container {
    text-align: right;
    padding-top: 20px;
    width: 100%;
    button {
        @media (min-width: $break-d-x) {
            width: 12%;
            font-size: 1.5rem;
        }

        @media (max-width: $break-d-l) {
            width: 14%;
            font-size: 1.4rem;
        }

        @media (max-width: $break-d-s) {
            width: 20%;
            font-size: 1.4rem;
        }

        @media (max-width: $break-m-l) {
            width: 35%;
            font-size: 1.3rem;
        }

        @media (max-width: $break-m-s) {
            width: 45%;
            font-size: 1.2rem;
        }
    }
}
.dropdownchild {
    padding-left: 10px !important;
    border-radius: 18px;
}
