@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.plugin-edge {
    padding: 40px;
    position: relative;
    height: calc(100vh - 30px);
    @media (max-width: $break-d-xx) {
        padding: 60px 40px;
    }

    .notifications-component {
        right: 0;
    }

    .plugins-list {
        .MuiDataGrid-cell {
            &:focus,
            &:focus-within {
                outline: none;
            }
        }
    }

    &__spinner {
        @include flexBox(center, center);
        width: 100%;
        min-height: 300px;

        .spinner {
            width: 60px;
            height: 60px;
            position: static;
            transform: none;
        }
    }
}
