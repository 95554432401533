.confirmation-modal-content {
    background-color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 450px;
    max-width: 500px;
    padding: 20px;
    border-radius: 4px;
    font-family: 'Ezlo Sans';

    h3 {
        font-size: 24px;
    }

    p {
        font-size: 15px;
        padding-bottom: 15px;
    }

    .button-container {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
    }

    .button-enrollByTokenContainer {
        display: flex;
        justify-content: center;
        margin: 40px 0 20px;
    }

    .paas__token-input {
        max-width: 360px;
        width: 100%;
        margin: 0 auto;
    }

    .MuiButtonBase-root {
        font-family: 'Ezlo Sans';
        width: 48%;
        height: 48px;
        text-transform: none;

        .MuiButton-label {
            font-size: 16px;
        }
    }
}
