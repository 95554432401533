@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';

.ezlo-backups-page {
    @media (max-width: $break-d-xx) {
        padding-top: 40px;
    }

    .left-panel {
        max-width: 40rem;
        height: calc(100vh - 70px);
        border-right: 1px solid rgba(144, 160, 175, 0.25);
        @include font-normal();

        .title {
            margin: 0 3rem;
            font-weight: 600;
            color: #000;
            font-size: 4rem;
        }

        .text {
            margin: 0 3rem;
            text-align: justify;
            color: #000;
            font-size: 1.6rem;
        }

        .secondary-btn.create-backup {
            margin: 3.2rem 0 1.2rem;
            padding: 10px 10px 10px 30px;
            height: 5.6rem;
            width: 100%;
            box-sizing: border-box;
            background-color: #fff;
            border: none;
            font-weight: 500;
            font-size: 1.8rem;
            color: $text-color-blue;
            text-align: left;
            letter-spacing: 0;
            display: flex;
            align-items: center;
            justify-content: flex-start;

            svg {
                fill: $text-color-blue;
                margin: 0 25px 0 0;
            }

            &:hover {
                background-color: rgba(144, 160, 175, 0.15);
            }
        }

        .warning {
            margin: 0.4rem 1rem 0;
            text-align: justify;
            color: #ff7070;
            font-size: 1.6rem;
        }

        .spinner-wrapper {
            width: 100%;
            position: relative;
            height: 4rem !important;
            display: flex;
            justify-content: center;
            margin-top: 4rem !important;

            .spinner {
                width: 4rem !important;
                height: 4rem !important;

                .spinner-circle:before {
                    background: $color-white;
                }
            }
        }

        .empty {
            margin-top: 2.4rem;

            .ant-empty-image {
                height: 3rem;
                margin-bottom: 0.4rem;

                svg {
                    height: 3rem !important;
                    width: 4.2rem !important;
                    fill: $color-white;
                }
            }
        }

        .backup-list {
            color: $text-color-black;
            overflow-y: hidden;
            @include listScrollbar();

            &:hover {
                overflow-y: auto;
            }

            .disabled {
                pointer-events: none;
            }

            &-item {
                cursor: pointer;
                margin-bottom: 10px;
                background: $color-white;
                padding: 1.7rem 2.6rem;
                font-size: 1.8rem;
                line-height: 2.2rem;
                display: flex;
                align-items: center;

                .item_icon {
                    width: 2.8rem !important;
                    height: 2rem !important;
                    margin-right: 1.6rem;
                }

                .date {
                    flex-grow: 1;
                    font-size: 1.8rem;
                    color: $text-color-black;
                }

                .arrow {
                    width: 1.3rem !important;
                    height: 2rem !important;
                }

                &:hover {
                    background: rgba(144, 160, 175, 0.15);
                }
            }

            &-item.active {
                background-color: rgba(144, 160, 175, 0.15);
            }

            scrollbar-width: thin;

            &::-webkit-scrollbar-track {
                -webkit-box-shadow: inset 0 0 6px rgba(168, 178, 208, 0.37);
                border-radius: 8px;
                background-color: #f5f5f5;
            }

            &::-webkit-scrollbar {
                width: 10px;
                background-color: #f5f5f5;
            }

            &::-webkit-scrollbar-thumb {
                border-radius: 0;
                background-color: rgba(133, 141, 166, 0.37);
            }
        }
    }

    .ezlo-section-content {
        padding: 2.4rem 4rem 3.4rem 4rem;
        background-color: $ezlo-section-bg-color;
        display: flex;
    }
}
