@import '../../../../../styles/variables';

.gridToolbar {
    flex-wrap: wrap;
}
.labelsPanelOpen {
    :global {
        .MuiButton-root {
            @media (max-width: $break-d-xl) {
                min-width: 50px;
            }
            .MuiButton-label {
                @media (max-width: $break-d-xl) {
                    font-size: 0;
                    height: 25px;
                    margin-left: 0;
                    .MuiButton-startIcon {
                        margin: 0;
                    }
                    svg {
                        margin-right: 0;
                    }
                }
            }
        }
    }
}
.meshBotsToolbarBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 5px;

    :global {
        .MuiButton-label {
            white-space: nowrap;
            margin-left: 4px;
        }
        .MuiTablePagination-caption {
            font-family: $ezloSans;
            font-size: 14px;
            font-weight: 400;
            @media (max-width: $break-t-s) {
                font-size: 14px;
            }
        }
        .MuiTablePagination-toolbar {
            min-height: 30px;
        }
        .MuiTablePagination-root {
            min-width: 216px;
            overflow: visible !important;
            @media (max-width: $break-t-s) {
                min-width: 160px;
            }
        }
        .MuiTablePagination-actions {
            margin-left: 13px;
            @media (max-width: $break-t-s) {
                margin-left: 10px;
            }
            button {
                @media (max-width: $break-t-s) {
                    padding: 8px;
                }
            }
        }
        .MuiToolbar-gutters {
            padding: 0;
        }

        .MuiDataGrid-toolbarContainer {
            height: auto !important;
            padding-left: 0 !important;
        }
        .MuiButton-root {
            @media (max-width: $break-d-xx) {
                min-width: 50px;
            }
            @media (max-width: $break-t-s) {
                min-width: 40px;
            }
            .MuiButton-label {
                @media (max-width: $break-d-xx) {
                    font-size: 0;
                    height: 25px;
                    margin-left: 0;
                    .MuiButton-startIcon {
                        margin: 0;
                    }
                    svg {
                        margin-right: 0;
                    }
                }
            }
        }
    }
    button {
        font-family: $ezloSans;
        font-size: 14px;
    }
}
