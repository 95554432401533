@import "../../styles/fonts";

$base-background: #000;
$error-background: #f6dede;

.border-top-transparent { border-top-color: transparent!important; }
.border-bottom-transparent { border-bottom-color: transparent!important; }
.border-left-transparent { border-left-color: transparent!important; }
.border-right-transparent { border-right-color: transparent!important; }

.tooltip {
  position: absolute;
  padding: 1.4rem;
  background: $base-background;
  color: #fff;
  width: 25rem;
  display: none;
  border-radius: .4rem;
  z-index: 20;
  text-transform: none;
  font-size: 1.4rem;
  line-height: 1.8rem;
  @include font-regular();

  &:before,
  &:after {
    content: '';
    border: .6rem solid $base-background;
    position: absolute;
  }

  &.show {
    display: block;
  }

  &.position-top {
    margin-top: -1.2rem;
    &:before,
    &:after {
      bottom: -1.2rem;
      border-top-color: transparent!important;
      border-bottom-color: transparent!important;
      border-left-color: transparent!important;
      border-right-color: transparent!important;
      left: 50%;
      margin-left: -.6rem;
    }
  }

  &.position-bottom {
    margin-top: 1.2rem;

    &:before,
    &:after {
      top: -1.2rem;
      border-top-color: transparent!important;
      border-left-color: transparent!important;
      border-right-color: transparent!important;
      left: 50%;
      margin-left: -.6rem;
    }
  }

  &.position-right {
    margin-left: 1.2rem;
    &:before,
    &:after {
      top: 50%;
      border-top-color: transparent!important;
      border-bottom-color: transparent!important;
      border-left-color: transparent!important;
      left: -1.2rem;
      margin-top: -.6rem;
    }
  }

  &.position-left {
    margin-left: -1.2rem;

    &:before,
    &:after {
      top: 50%;
      border-top-color: transparent!important;
      border-bottom-color: transparent!important;
      border-right-color: transparent!important;
      right: -1.2rem;
      margin-top: -.6rem;
    }
  }

  &.error {
    background: $error-background;
    color: #000;

    &:before,
    &:after {
      border: .6rem solid $error-background;
    }
  }
}
