@import '../../../../../styles/variables';

.resendButton {
    position: absolute;
    right: -160px;
    bottom: 0;
    width: 110px;
    @media (max-width: $break-t-s) {
        position: static;
        margin-top: 15px;
        width: 100%;
    }
    button {
        margin-right: 20px;
    }
}
