@import '../../../../../styles/variables.scss';

.wrapper {
    display: flex;
    justify-content: center;
    padding: 0 0 20px;
    button {
        font-size: 16px;
        width: 250px;
        height: 56px;
    }

    :global {
        .MuiButton-contained {
            &.Mui-disabled {
                background-color: rgba(0, 0, 0, 0.12);
            }
        }
    }
}
