.color-picker__input {
    width: 90px;
    height: 50px;
    border: 0px;
    margin: 0px 10px 0 0;
}
.sliders-box {
    width: 300px;
    margin: 10px;
}
.color-picker {
    overflow: hidden;
    height: 30px;
    min-width: 300px;
    border: 1px solid rgba(246, 243, 243, 0.98);
    .MuiSlider-root {
        width: 100%;
    }
    .MuiSlider-thumb {
        width: 18px;
        height: 18px;
        margin-top: -7px;
        background: transparent;
        border: 3px solid #a8a0a0;
    }
    .slider__inactive {
        .MuiSlider-thumb {
            opacity: 0;
        }
    }
    .MuiSlider-rail {
        color: transparent;
        opacity: 1;
    }
    &__wwhite, &__target_warm_white {
        margin-bottom: 5px;
        background: -webkit-linear-gradient(270deg, rgb(255, 108, 0) 9%, rgb(255, 255, 255) 100%);
        background: -moz-linear-gradient(270deg, rgb(255, 108, 0) 9%, rgb(255, 255, 255) 100%);
        background: linear-gradient(270deg, rgb(255, 108, 0) 9%, rgb(255, 255, 255) 100%);
    }
    &__cwhite, &__target_cool_white {
        background: -webkit-linear-gradient(270deg, rgb(181, 205, 255) 24%, rgb(255, 255, 255) 100%);
        background: -moz-linear-gradient(270deg, rgb(181, 205, 255) 24%, rgb(255, 255, 255) 100%);
        background: linear-gradient(270deg, rgb(181, 205, 255) 24%, rgb(255, 255, 255) 100%);
    }
    &_container{
        display: flex;
    }
    &_value{
        padding: 0 0 0 5px;
        display: inline-block;
        min-width: 40px;
        border: 1px solid rgba(246, 243, 243, 0.98);
    }
    .MuiSlider-thumbColorSecondary.Mui-focusVisible, .MuiSlider-thumbColorSecondary:hover {
        box-shadow: none;
    }
}
