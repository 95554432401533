.wrapper {
    display: flex;
    flex-wrap: wrap;
}

.mode_block__make_recording {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;

    :global {
        .MuiOutlinedInput-inputMarginDense {
            padding-top: 30px;
        }
        .MuiInputBase-input {
            min-width: 95px;
        }
    }
}

.unit {
    display: inline-block;
    margin: 14px 0 0 5px;
    font-size: 16px;
}

.inputRecordingLength {
    display: flex;
    align-items: flex-end;
    margin-bottom: 5px;
}
