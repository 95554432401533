@import '../../../styles/variables';
@import '../../../styles/mixins';

.meshBotsHeader {
    :global {
        .MuiIconButton-root {
            ~ div,
            ~ svg,
            ~ img {
                @media (max-width: $break-t-s) {
                    display: none;
                }
            }
        }
    }
}
.meshBotsContainer {
    padding: 46px 16px 0 0;
    display: flex;

    :global {
        .MuiDataGrid-root .MuiDataGrid-columnsContainer,
        .MuiDataGrid-root .MuiDataGrid-cell {
            border-bottom: 1px solid $border-grey-light;
        }
        .MuiTableCell-root {
            border-bottom: 0;
        }
        .MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within,
        .MuiDataGrid-root .MuiDataGrid-cell:focus,
        .MuiDataGrid-root .MuiDataGrid-cell:focus-within {
            outline-width: 0;
        }
        .MuiTableCell-head {
            font-size: 13px;
            font-weight: 500;
            text-transform: uppercase;
        }

        .MuiTableCell-body {
            font-size: 12px;
        }
        .MuiTableRow-root {
            &.disabled {
                background-color: rgba(0, 0, 0, 0.04);
                pointer-events: none;

                .MuiIconButton-label {
                    color: $color-button-grey-2;
                }
            }
        }
        .MuiDataGrid-row:hover {
            box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
            .MuiCheckbox-root {
                color: $text-color-black;
                &.Mui-checked {
                    color: $color-primary-theme;
                }
            }
        }
        .MuiDataGrid-root .MuiDataGrid-columnHeaderCheckbox,
        .MuiDataGrid-root .MuiDataGrid-cellCheckbox {
            .MuiSvgIcon-root {
                width: 20px;
                height: 20px;
            }
            * {
                transition: color 0s;
            }
        }
        .MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer {
            overflow: visible;
        }
        .MuiBadge-badge {
            font-size: 11px;
            line-height: 17px;
            padding: 0 5px;
            height: 17px;
            min-width: 17px;
        }
        .MuiCheckbox-root {
            color: rgba(0, 0, 0, 0.24);
        }
        .MuiDataGrid-root .MuiDataGrid-columnHeader,
        .MuiDataGrid-root .MuiDataGrid-cell {
            @media (max-width: $break-d-xx) {
                font-size: 14px;
            }
        }
    }
}

.ezloMeshbotsList {
    margin-top: 20px;
    height: calc(100vh - 100px);
    flex: 1 1 auto;
}
.viewingGridTable {
    height: 100%;
}
.checkboxBadge {
    :global {
        .MuiBadge-anchorOriginTopRightRectangle {
            top: 5px;
            right: 5px;
        }
    }
}
