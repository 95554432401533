@import '../../../../styles/mixins';
@import '../../../../styles/fonts';
@import '../../../../styles/variables';

.images,
.imgSkeleton {
    height: 100px;
}

.imgSkeleton {
    width: 160px;
    animation: skeleton-loading 2s infinite;
    background: rgba($text-color-grey, 0.25);
}
.imgActionBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.copyLinkText {
    @include font-medium();
    font-size: 12px;
    margin-right: 5px;
    color: $color-primary-theme;
}

.imageCheck {
    position: absolute;
    top: 0;
    right: 0;
    padding: 3px 5px;
    background-color: #ffffff;
    opacity: 0.9;
    svg {
        opacity: 1;
    }
}
.cardTitle {
    font-size: 14px;
    word-break: break-all;
}

.imgCard {
    margin: 10px;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    :global {
        .MuiCardContent-root {
            padding: 10px 8px;
        }
        .MuiTooltip-tooltipPlacementTop {
            margin: 5px 0;
        }
        .MuiTooltip-tooltip {
            background-color: $color-primary-theme;
            font-size: 12px;
            box-shadow: 0 0 6px 2px #0c0c0c66;
            font-weight: 400;
            text-transform: capitalize;
        }
        .MuiCardActions-root {
            border-top: 1px solid #dfd8d885;
            padding: 3px 8px;
        }
        .MuiCardActionArea-root {
            text-align: center;
        }
    }
    min-width: 110px;
}
:global(.MuiPaper-elevation1) {
    &.imgCard {
        box-shadow: 0 2px 15px -3px rgba(0, 0, 0, 0.07), 0 10px 20px -2px rgba(0, 0, 0, 0.04);
    }
}
.cardActionArea {
    display: flex;
    background-color: #ffffff;
}
