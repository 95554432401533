@import '../../../../../styles/variables';
@import '../../../../../styles/mixins';

.script-list {
    height: calc(100vh - 330px);
    margin-top: 30px;
    &__header {
        @include flexBox(space-between, center);
        margin-bottom: 20px;
    }
    &__buttons {
        .MuiButton-root {
            margin-right: 2px;
            padding: 8px 30px;
            font-size: 14px;
            &.active {
                background: $color-button-blue-hover;
            }
        }
    }
    .script-list__create {
        font-size: 14px;
        padding: 8px 20px;
    }
    &__empty-field {
        svg {
            width: 24px;
            height: 24px;
        }
    }
}
