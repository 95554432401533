@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.pluginSettings {
    padding: 40px;
    h2 {
        font-size: 26px;
        margin-bottom: 30px;
    }
    .pluginSettings__group {
        @include flexBox();
    }
    .pluginSettings__block {
        @include flexBox(inherit, center);
    }
    .pluginSettings__block_label {
        font-size: 16px;
        color: $color-black;
        margin-right: 20px;
    }
    .pluginSettings__status {
        position: relative;
        width: 110px;
        p {
            @include flexBox(space-between, center);
            font-size: 16px;
            width: 110px;
            position: absolute;
            left: 0;
            top: 10px;
            margin: 0;
        }
        svg {
            width: 20px;
            height: 20px;
        }
    }
    .pluginSettings__input {
        label {
            font-size: 14px;
        }
        input {
            padding: 10px 15px;
            height: 20px;
        }
    }
    .pluginSettings__button {
        font-size: 14px;
        text-transform: capitalize;
        line-height: 28px;
        margin: 0 10px;
    }
    .pluginSettings__prefixes {
        padding-bottom: 40px;
        margin-top: 30px;
    }
    .pluginSettings__list {
        padding-left: 20px;
    }
    .pluginSettings__json {
        max-width: 620px;
        pre {
            padding: 5px;
            background: rgba($color-black, 0.8);
            color: $color-orange-4;
        }
    }
    .pluginSettings__json_label {
        font-size: 18px;
        color: $color-black;
    }
    .pluginSettings__json_hints {
        font-size: 14px;
        color: $color-black;
    }
    .pluginSettings__header {
        @media (max-width: $break-d-xx) {
            padding: 19px 10px 5px !important;
        }
    }
    .pluginSettings__json_img {
        width: 100%;
        margin-bottom: 20px;
    }
    :global {
        .MuiOutlinedInput-notchedOutline {
            legend {
                font-size: 10px;
            }
        }
        .MuiInputLabel-outlined {
            transform: translate(14px, 14px) scale(1);
            &.MuiInputLabel-shrink {
                transform: translate(14px, -6px) scale(0.75);
            }
        }
    }
}
