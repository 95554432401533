@import '../../../../../styles/variables';

.device-custom-settings {
    max-width: 350px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.device-custom-setting {
    display: flex;
}

.device-custom-setting-text {
    margin: 0 7px 0 0;
    font-size: 1.6rem;
}

.device-custom-setting-status {
    line-height: 1;
    text-transform: capitalize;
}

.device-custom-setting-icon-box {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: #e7e7e7;
    height: 30px;
    width: 30px;
    cursor: pointer;
    margin-right: 20px;
    transition: background-color 0.3s;

    &:hover {
        background-color: #a5a5a5;
    }

    &:last-child {
        margin-right: 0;
    }
}

.device-custom-setting-icon {
    fill: gray !important;
    width: 22px !important;
    height: 22px !important;
}

.tabs-settings-content {
    > div:first-child {
        background-color: #585ed9 !important;
    }
}

.device-alarm__wrapper {
    max-width: 300px;
    min-width: 250px;
}

.device-items-list .device-item-block .device-tile {
    z-index: 0;
}

.dialog-body {
    text-align: center;
}
