@import "../../../../../styles/variables";
@import "../../../../../styles/mixins";

.plugins-list {
    height: calc(100vh - 245px);
    margin-top: 40px;
    h2 {
        font-size: 26px;
        font-weight: bold;
    }
    &__empty-field {
        svg {
            width: 24px;
            height: 24px;
        }
    }
    &__count {
        position: relative;
    }
    &__sum-plugins {
        @include flexBox(center, center);
        position: absolute;
        right: 0;
        top: 2px;
        width: 16px;
        height: 16px;
        font-size: 10px;
        background: $favorites-color;
        border-radius: 50px;
    }
}