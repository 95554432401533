@import '../../../styles/variables.scss';
@import '../../../styles/fonts.scss';
@import '../../../styles/mixins.scss';

.notificationSettingsWrapper {
    padding: 1.5em 2.5em;
    font-size: 16px;
}

.notificationSettingsTitle {
    @include font-semibold();
    flex: 0.7;
    font-size: 28px;
    color: $text-color-black;
    margin-bottom: 0;
}

.notificationSettingsCategoriesWrapper {
    @include flexBox();
    margin: 30px 0 0 0;
}

.notificationSettingsCategoriesTitle {
    margin-right: 45px;
    padding-top: 8px;
}
