@import '../../styles/variables.scss';

.variableColorInput {
    width: 100%;
    max-width: 200px;
    background-color: $color-white;
    height: fit-content;
    margin-right: 20px !important;
    :global {
        .MuiFormLabel-root {
            font-size: 12px;
        }
        .MuiInputLabel-shrink {
            transform: translate(14px, -5px) scale(0.95) !important;
        }
        fieldset {
            font-size: 18px;
        }
    }
}
.variableColorPicker {
    margin-top: -5px;
    margin-right: 10px;
    max-width: 90px;
    height: 50px;
    border: none;
}
.variableColorSliderBox {
    max-width: 300px;
    margin-top: -1px;
    @media (max-width: $break-m-l) {
        margin-bottom: 30px;
    }
    &:global(.MuiBox-root) {
        padding: 0;
        border: 0;
        margin-top: 5px;
    }
}
.variableColorSpan {
    margin-right: 20px;
}
.flexWrap {
    display: flex;
    @media (max-width: $break-d-xxx) {
        flex-wrap: wrap;
    }
}

.meshbotColorInput {
    &:global(.MuiFormControl-root) {
        margin-right: 24px;
    }
    :global {
        .MuiFormLabel-root {
            font-size: 10px;
        }
        .MuiInputLabel-shrink {
            transform: translate(3px, -2px) scale(0.95);
        }
        .MuiInputBase-input {
            padding: 5px;
        }
    }
}
