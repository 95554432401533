@import '../../styles/variables';
@import '../../styles/mixins';

html {
    @include inputScrollbar;
}

.ezlo-page-wrapper {
    height: 100%;

    .ezlo-section {
        display: flex;
        height: calc(100vh - 30px);
        overflow-y: auto;
        @include inputScrollbar;

        &-content {
            width: 100%;

            .highlight-panel {
                padding: 1.6rem 0;
                background: $header-bg-color;

                .block-header {
                    &-title,
                    &-actions {
                        background: $header-bg-color;
                    }
                }
            }

            &::-webkit-scrollbar-track {
                -webkit-box-shadow: inset 0 0 6px rgba(168, 178, 208, 0.37);
                border-radius: 10px;
                background-color: #f5f5f5;
            }
            &::-webkit-scrollbar {
                width: 12px;
                background-color: #f5f5f5;
            }
            &::-webkit-scrollbar-thumb {
                border-radius: 10px;
                background-color: rgba(168, 178, 208, 0.37);
            }
        }

        .form-top-actions {
            padding: 0 $inside-page-padding-left;
            width: 100%;
            text-align: right;

            .btn-link {
                font-size: 1.4rem;
                margin-left: 5rem;
                font-weight: normal;
                color: $button-color;
                text-transform: capitalize;

                &.highlight {
                    font-weight: 600;

                    &:hover {
                        color: $button-hover-color;
                    }

                    &:active,
                    &:focus {
                        color: $button-pressed-color;
                    }

                    &[disabled],
                    &[disabled]:hover {
                        color: $disabled-color;
                    }
                }

                &:hover {
                    color: $button-color;
                }

                &:active,
                &:focus {
                    color: $button-pressed-color;
                }

                &[disabled],
                &[disabled]:hover {
                    color: $disabled-color;
                }
            }
        }
    }
}
