@import '../../../../../styles/variables';
@import '../../../../../styles/mixins';

.header-generator {
    @include flexBox(space-between, center);
    margin-bottom: 40px;

    h2 {
        font-size: 24px;
    }
}
