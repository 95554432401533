@import '../../../../../../styles/variables.scss';
@import '../../../../../../styles/fonts';
@import '../../../../../../styles/mixins';

.controller_item {
    width: 178px;
    height: 178px;
    border-radius: 16px;
    border: 1px solid rgba($text-color-grey, 0.25);
    padding: 16px;
    margin: 2px 12px 12px;
    background-color: $color-white;
}

.controller_block {
    @include font-regular();
    display: flex;
    flex-direction: column;
    font-size: 18px;
    line-height: 22px;
    height: 100%;
}

.icons_wrapper {
    display: flex;
    justify-content: space-between;
}

.controller_icon {
    position: relative;

    &:after {
        position: absolute;
        top: -6px;
        right: -6px;
        content: '';
        border-radius: 50%;
        border: 2px solid white;
        box-sizing: content-box;
        width: 8px;
        height: 8px;
        background-color: #ec6732;
    }
    svg {
        position: relative;
        height: 24px;
        width: 24px;
    }
}

.controller_icon.online {
    &:after {
        background-color: $color-online;
    }
}

.info_block {
    display: flex;
    flex-direction: column;
    color: $text-color-grey;
    flex: 1;
    font-size: 16px;
    margin-top: 10px;
}

.online_info_block {
    color: $text-color-black;
}

.status {
    margin-top: 4px;
    font-size: 13px;
    color: $text-color-grey;
}

.iconInfo {
    position: relative;
    cursor: pointer;

    svg {
        height: 24px;
        width: 24px;
    }
}

.popoverBody {
    position: relative;
    padding: 20px 20px 12px 12px;
    font-family: 'Ezlo Sans';
    font-size: 14px;
    font-weight: 500;
    .iconClose {
        position: absolute;
        top: 4px;
        right: 4px;
    }
}

.controller_select {
    width: 100%;

    :global {
        .Mui-disabled {
            background: $color-button-grey;

            .MuiOutlinedInput-notchedOutline {
                border-color: $color-button-grey;
            }
        }

        .MuiOutlinedInput-root {
            color: $text-color-blue;
            transition: color 0s;

            &.Mui-disabled {
                background: $color-button-grey;
                color: $text-color-grey;
            }
        }

        .MuiOutlinedInput-input {
            padding: 0;
        }
        .MuiOutlinedInput-notchedOutline {
            border-color: $text-color-blue;
        }

        .MuiInputBase-input {
            font-size: 14px;
            display: flex;
            align-items: center;
            text-align: center;
            height: 30px;
            justify-content: center;
            padding: 0 13px 0 0;
        }

        .MuiSelect-icon {
            color: $controller_function_button_icon;
        }
    }
}
