@import '../../../../../../../../../styles/variables.scss';
@import '../../../../../../../../../styles/mixins.scss';
@import '../../../../../../../../../styles/fonts.scss';

.account_list {
    margin: 20px 0;
    @include flexBox(flex-start, center);
    flex-direction: column;
    width: 320px;
    gap: 10px;
}

.paas_account {
    width: 100%;
    cursor: pointer;
    transition: box-shadow 0.3s linear;
}
.inner {
    @include flexBox(flex-start, center);
    gap: 10px;
    padding: 10px;
    background: $color-white;
    border-radius: 12px;
    width: 100%;
    border: 1px solid rgba($text-color-grey, 0.25);
    box-shadow: 0px 0px 6px 1px rgba($color-black, 0.05);
    &:hover {
        box-shadow: 0px 0px 6px 1px rgba($color-black, 0.25);
    }
}

.icon {
    width: 20px;
    height: 20px;
}
.name {
    display: block;
    @include font-medium();
    font-size: 12px;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 22em;
    white-space: nowrap;
}

.info {
    @include font-light();
    font-size: 12px;
}

.buttonAdd {
    width: 100%;
}
