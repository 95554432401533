@import '../../../../styles/variables';
@import '../../../../styles/fonts';
@import '../../../../styles/mixins';

@-moz-keyframes spin {
    100% {
        -moz-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.main-content {
    flex-grow: 1;
    margin-top: 1.6rem;
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $color-black;
    font-family: Ezlo Sans;
    .backup_progress {
        margin-top: 18rem;
        position: relative;

        &__icon {
            -webkit-animation: spin 2s linear infinite;
            -moz-animation: spin 2s linear infinite;
            animation: spin 2s linear infinite;
        }

        &__percentage {
            position: absolute;
            top: 41%;
            right: 22%;
            text-align: center;
            min-width: 10.2rem;
            font-size: 2rem;
            color: #3e4292;
        }
    }

    .icon {
        margin-top: 7rem;
        width: 12rem !important;
        height: 12rem !important;

        &-restore {
            margin-top: 6rem;
            width: 20rem !important;
            height: 20rem !important;
        }

        &-completed {
            margin-top: 6rem;
        }
    }

    .text {
        margin-top: 2rem;
        text-align: center;
        &-auto-width {
            max-width: 77rem;
        }
    }

    .description {
        text-align: center;
        margin-top: 0.8rem;
        font-size: 1.6rem;
        font-weight: 400;
        line-height: 2.3rem;
        &-warning {
            font-size: 2.2rem;
            line-height: 1.38;
            margin-top: 0;
            color: $danger-color;
        }
        &-restored {
            text-align: center;
            margin-top: 6rem;
            font-size: 2.2rem;
            line-height: 1.38;
        }
    }

    .title {
        margin-top: 2rem;
        font-weight: 600;
        font-size: 3.2rem;
        line-height: 3.8rem;
        text-align: center;
    }

    .animated-dots {
        text-align: start !important;
        // padding-left: 30px; // used for animated-dots
    }

    .animated-dots::after {
        overflow: hidden;
        display: inline-block;
        vertical-align: bottom;
        -webkit-animation: ellipsis steps(4, end) 900ms infinite;
        animation: ellipsis steps(4, end) 900ms infinite;
        content: '\2026'; /* ascii code for the ellipsis character */
        width: 0;
    }

    .devices {
        margin-top: 7rem;
        position: static;
        width: 38rem;
        height: 6.7rem;

        .dropdown-toggle {
            height: 6.7rem !important;
            border-radius: 0 !important;
            padding: 2.15rem 3.2rem 2.16rem 1.18rem;
        }

        .dropdown-arrow {
            width: 1.6rem;
            top: 2.85rem;
            right: 2.8rem;
            position: absolute;
        }

        .dropdown-content {
            border-radius: 0 !important;
            margin: 0 !important;
            color: $color-white;
            position: static;
            transform: none;

            .undefined-menu-item {
                padding-left: 1.2rem;
            }
        }
    }

    .main-content-footer {
        width: 40rem;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .lock-backup {
            margin-top: 5rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;

            &-text {
                display: flex;
                line-height: 1;
                align-items: flex-start;
                color: $text-color-black;

                &__icon {
                    margin-top: 0.6rem;
                    width: 2.4rem;
                    height: auto;
                    margin-right: 1.6rem;
                }

                &__title {
                    font-weight: 500;
                    font-size: 1.8rem;
                    line-height: 2.2rem;
                }

                &__description {
                    max-width: 25rem;
                    margin-top: 4px;
                    font-weight: 500;
                    font-size: 1.3rem;
                    line-height: 1.6rem;
                    color: $text-color-description;
                }
            }

            &-toggle {
                .toggle {
                    .toggle-container {
                        border-color: #3e46ae;
                        height: 2.2rem;
                        width: 4rem;

                        .toggle-handle {
                            background-color: #3e46ae;
                        }
                    }
                }

                .toggle.toggled {
                    .toggle-container {
                        border-color: #3e46ae;
                        background-color: #3e46ae;

                        .toggle-handle {
                            background-color: $color-white;
                            transform: translateX(1.8rem);
                        }
                    }
                }
            }
        }

        .primary-btn {
            margin: 5rem auto;
            width: 39.2rem;
            height: 5.6rem;
            border-radius: 0.4rem;
            color: #fff;
            @include font-medium();
            font-size: 1.6rem;
        }
        .warning {
            border-color: #fe5c15;
            background-color: #fe5c15;
        }
    }
}
