@import "../../../../../styles/variables";
@import "../../../../../styles/mixins";

.device-default {
    text-align: center;
    h2 {
        font-size: 22px;
        margin: 30px 0;
    }
    button {
        width: 200px;
        font-size: 14px;
    }
}
