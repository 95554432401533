@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';

.passwordRequirementsBox {
    @include font-medium();

    .title {
        font-size: 20px;
        color: $text-color-grey;
        padding-bottom: 12px;
    }

    .requirementItemBox {
        transition: all 0.2s ease-out;
        @include flexBox(flex-start, center);
        .requirementText {
            text-align: left;
        }
    }

    .itemIcon {
        color: $text-color-grey;
        transition: all 0.2s ease-out;
        opacity: 0;
    }

    .isRequirementFulfilled,
    .requirementFulfilled__green {
        .itemIcon {
            transition: all 0.2s ease-out;
            opacity: 1;
        }
    }

    .requirementFulfilled__green {
        :global {
            .MuiTypography-colorPrimary {
                color: $main-color-color-green;
            }
            .MuiSvgIcon-colorPrimary {
                color: $main-color-color-green;
            }
        }
    }

    :global {
        .MuiTypography-body1 {
            padding: 4px 8px;
            font-size: 14px;
            @include font-medium();
        }
    }
}
