@import '../../../../../styles/mixins.scss';

.wrapper {
    .select {
        @include selectStyle();
        :global {
            .MuiInputBase-input {
                width: 100%;
            }
            .MuiFormHelperText-root {
                font-size: 10px;
            }
        }
    }
}
