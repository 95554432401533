@import '../../../../../styles/variables';
@import '../../../../../styles/mixins';
@import '../../../../../styles/fonts';

.meshbotCaption {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 44px;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;

    .meshbotCaption__title {
        font-size: 2rem;
        text-transform: uppercase;
        @include font-semibold();
        color: $color-white;
        z-index: 1;
    }
}

.trigger_block {
    background-color: $meshbot-trigger-section;
}
.action_block {
    background-color: $meshbot-action-section;
}
