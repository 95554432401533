@import '../../../../../styles/variables';
@import '../../../../../styles/mixins';
@import '../../../../../styles/fonts';

.treeViewWithDraggingBox {
    @include flexBox(space-between);
    flex: 1 1 auto;
    margin-top: 20px;
    flex-wrap: wrap;
}

.treeTitle {
    min-width: 200px;
    @include font-medium();
    font-size: 14px;
    margin: 0 0 10px;
}
.treeViewBox {
    margin-bottom: 15px;
    @include font-regular();
    :global {
        .MuiTreeItem-content {
            cursor: grab;
        }
        .MuiTypography-body1 {
            font-family: Menlo, monospace;
            font-size: 11px;
            color: rgb(136, 19, 145);
        }
    }
    .elementType {
        color: #000000;
        text-transform: capitalize;
        white-space: nowrap;
    }
}
.treeItemLabelBox {
    @include flexDirectionAlign(row, center);
    :global {
        .MuiInputBase-input {
            text-align: center;
        }
    }
}
.treeItemInput {
    :global {
        .MuiInputBase-input {
            width: 40px;
            font-size: 12px;
            padding: 1px 0;
        }
    }
}
.fieldBox {
    margin: 0 7px;
    :global {
        .MuiInputBase-root {
            font-size: 12px;
        }
    }
}
.dropContainer {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-bottom: 15px;
}
