@import '../../../../styles/fonts';
@import '../../../../styles/variables';

.delete-group-modal {
  @include font-regular();
  width: 440px !important;

  .ant-modal-content {
    border-radius: 24px;

    .ant-modal-body {
      display: flex;
      flex-direction: column;
      padding: 24px;

      .icon {
        margin: 86px auto 0;
      }

      .title {
        text-align: center;
        margin: 24px 0 0;
        font-size: 32px;
        line-height: 38px;
      }

      .message {
        text-align: center;
        margin: 8px 0 0;
        font-size: 16px;
        line-height: 23px;
      }

      .btn {
        margin: 48px 0 0;
        @include font-normal();
        height: 56px;
        text-transform: none;
      }

      .warning {
        font-size: 16px;
        background: $text-color-warning;
        color: $color-white;
      }

      .warning:hover {
        box-shadow: 0 8px 16px rgba(144, 160, 175, 0.3);
      }

      .warning:focus {
        background-color: $color-warning-active;
      }

    }
  }
}