@import '../../../../../styles/variables';
@import '../../../../../styles/mixins';

.response-form {
    margin-top: 90px;
    margin-bottom: 20px;
    .MuiOutlinedInput-notchedOutline {
        legend {
            font-size: 10px;
        }
    }
    div.MuiOutlinedInput-multiline {
        min-height: inherit;
    }
    &__input {
        margin-right: 0;
    }
}