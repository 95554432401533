@import './variables';
@import './mixins';

.MuiInput-input {
    .trigger-block__select_placeholder & {
        color: $color-button-grey-1;
    }
}

.MuiSelect-outlined {
    &.MuiSelect-outlined {
        .trigger-block & {
            line-height: 16px;
            padding-right: 24px;
            padding-bottom: 4px;
        }
    }
}

.MuiInputLabel-outlined {
    .input-block_script &,
    .generator-form__block &,
    .device-settings__string &,
    .response-form__input & {
        transform: translate(14px, 14px) scale(1);
    }
    .script-form & {
        transform: translate(18px, 15px) scale(1);
    }
    .response-form & {
        transform: translate(14px, 20px) scale(1);
    }
}

.MuiOutlinedInput-multiline {
    .script-form & {
        min-height: calc(100vh - 290px);
        align-items: start;
    }
}

.MuiInputBase-root {
    .MuiInputBase-input {
        .generator-form__item & {
            font-size: 16px;
        }
    }
    .trigger-block & {
        line-height: 16px;
    }

    .MuiInputBase-input {
        line-height: 1.3;
    }
}
