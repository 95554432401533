@import '../../../styles/variables';
@import '../../../styles/mixins';

.notificationsWrapper {
    font-size: 16px;
    font-family: 'Ezlo Sans';
    padding: 1.5em 2.5em;
    position: relative;

    .notificationTableWrapper {
        height: 100%;

        :global {
            .align-center {
                display: flex;
                margin: 0 auto;

                svg {
                    cursor: pointer;
                    width: auto;
                    height: auto;
                }
            }
        }
    }

    .titleWrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 0 30px 0;
    }

    .title {
        text-align: left;
        color: $text-color-black;
        font-weight: bold;
        margin: 0;
        font-size: 32px;
        line-height: 39px;
        font-family: Ezlo Sans;
    }

    .colImport {
        display: inline-block;
        font-weight: 600;
        color: $text-color-black;
        cursor: pointer;

        svg {
            display: block;
            color: $text-color-blue;
            font-size: 25px;
            margin: 0 auto;
        }
    }

    .createTemplateBtn {
        padding: 5px 12px;

        :global {
            .MuiSvgIcon-root {
                height: 45px;
                font-size: 40px;
                margin-right: 5px;
            }
        }
    }

    .anyNotificationsYet {
        margin: 10px 0;
        text-align: center;
        font-weight: bold;
    }

    .MuiDataGrid-cell .MuiDataGrid-cell--textLeft .MuiDataGrid-withBorder {
        justify-content: flex-start;
        text-align: left !important;
    }

    .notificationInformationWrapper {
        p {
            align-items: center;
            display: flex;

            svg {
                fill: $text-color-blue;
                width: 30px;
                height: 30px;
                min-width: 30px;
                margin: 0 10px 0 0;
            }
        }
    }
}
