@import '../../../../../styles/mixins.scss';
@import '../../../../../styles/variables';

.deviceGroupsCreateWrapper {
    @include flexBox();
}

.header {
    @media (min-width: $break-d-burger) {
        padding: 18px 34px 5px !important;
    }
    .controllerDropdown {
        margin-left: auto;
    }
}
