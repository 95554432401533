@import "../../../../../styles/variables";

.ellipses {
  span {
    display: inline-block;
    border-radius: 50%;
    background: $color-white;
    width: 8px;
    height: 8px;
    margin-right: 8px;

    animation-name: blink;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
  }

  span:nth-child(2) {
    animation-delay: 0.2s;
  }

  span:nth-child(3) {
    animation-delay: 0.4s;
    margin-right: 0;
  }
}

@keyframes blink {
  0% {
    opacity: 0.65;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 0.25;
  }
}