@import '../../../../styles/mixins';
@import '../../../../styles/variables';

.mediaBox {
    padding: 110px 50px 0;
    height: 100vh;
    overflow-y: scroll;
    margin-top: -35px;
    @include listScrollbar();

    @media (max-width: $break-t-m) {
        padding: 110px 20px 0;
    }
    @media (max-width: $break-t-s) {
        padding-top: 130px;
    }
    @media (max-width: $break-m-s) {
        padding-top: 170px;
    }
}
