@import '../../../../../styles/variables';
@import '../../../../../styles/mixins';

.modal-plugin {
    &__group {
        @include flexBox();
        margin: 0 15px 0 0;
    }

    &__update {
        width: 500px;
        padding: 15px;

        h3 {
            font-size: 26px;
            font-weight: bold;
            margin-bottom: 30px;
            word-wrap: break-word;
        }

        &-field {
            @include flexBox(flex-start, center);
            margin-bottom: 20px;

            input,
            textarea {
                line-height: 18px;
                padding: 12px 15px;
            }

            .MuiFormControl-root {
                width: 100%;
            }

            .MuiOutlinedInput-multiline {
                padding: 0;
            }

            h4:first-letter {
                text-transform: uppercase;
            }
        }

        &-label {
            width: 180px;
            font-size: 18px;
            flex-shrink: 0;
        }

        &-buttons {
            @include flexBox(space-between);

            button {
                width: 48%;
                font-size: 14px;
                padding: 10px 15px;
            }

            &.center {
                @include flexBox(center);
                margin-top: 40px;
            }
        }
    }

    &__errors {
        span {
            word-break: break-all;
        }

        &-item {
            color: $danger-color;
        }
    }

    &__notification {
        width: 440px;
        padding: 50px 0;

        &-title {
            text-align: center;
            font-size: 24px;
            margin-bottom: 40px;
            word-wrap: break-word;

            span {
                display: block;
            }
        }

        &-buttons {
            text-align: center;

            button {
                font-size: 14px;
                padding: 10px 15px;
            }
        }
    }

    &__confirmation {
        padding: 10px;
        width: 360px;
        margin-top: 14px;

        &.publish {
            width: 500px;

            h3 {
                font-weight: bold;
                word-wrap: break-word;
            }

            p {
                margin: 35px 0 30px;
            }
        }

        &-title {
            font-size: 18px;
            text-align: center;
            margin-bottom: 15px;
            word-wrap: break-word;
        }

        &-buttons {
            @include flexBox(space-between);
            margin: 0 15px 15px 15px;

            button {
                width: 48%;
                font-size: 14px;
            }
        }
    }

    &__install {
        width: fit-content;
        height: 370px;
        border-radius: 4px;

        &-list {
            height: 312px;
            overflow-y: auto;
            padding: 13px 15px;

            &::-webkit-scrollbar-track {
                background-color: transparent;
            }

            &::-webkit-scrollbar {
                width: 6px;
                background-color: transparent;
            }

            &::-webkit-scrollbar-thumb {
                border-radius: 22px 0 0 22px;
                background-color: $color-scrollbar-plugins;
            }
        }

        &-title {
            margin: 0;
            font-size: 20px;
            font-weight: 600;
            color: $color-white;
            background-color: $text-color-blue;
            padding: 7px 50px 7px 18px;
            line-height: 22px;
            word-wrap: break-word;
            border-radius: 4px 4px 0 0;
        }

        &-controller {
            @include flexBox(space-between, center);
            padding: 10px;
            border-bottom: 1px solid $color-button-grey;
            margin-bottom: 10px;
        }

        &-online {
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background: $orange-color;

            &.isOnline {
                background: $success-color;
            }
        }

        &-serial {
            min-width: 100px;
            font-size: 16px;
            color: $color-black;
            font-weight: 500;
            padding: 0 12px;
        }

        &-buttons {
            button {
                font-size: 14px;

                & + button {
                    margin-left: 10px;
                }
            }
        }
    }

    &__dropZone {
        @include flexBox(space-between, center);
        border-top: 1px solid $color-button-grey-1;
        padding: 20px 0 40px;

        &-label {
            max-width: 390px;
            font-size: 18px;
            margin-bottom: 0;

            span {
                color: $color-button-grey-1;
                font-size: 14px;
                padding: 0 10px;
                word-break: break-all;
            }
        }
    }

    &__configure {
        width: 463px;
        height: 450px;
        border-radius: 4px;

        h2 {
            margin: 0;
            font-size: 20px;
            font-weight: 600;
            color: $color-white;
            background-color: $text-color-blue;
            padding: 7px 50px 7px 18px;
            line-height: 22px;
            word-break: break-all;
            border-radius: 4px 4px 0 0;
        }

        &-field {
            margin-bottom: 15px;

            .MuiFormControl-root {
                min-width: 100%;
            }

            & > div {
                margin-right: 0;
            }
        }

        &-label {
            font-size: 14px;
            margin-bottom: 6px;
        }

        .input-block__input {
            input {
                padding: 12px;
            }
        }

        .modal-plugin__configure-button {
            font-size: 14px;
            display: block;
            margin: 35px auto 0;
        }

        .modal-plugin__configure-list {
            height: 320px;
            overflow-y: auto;
            overflow-x: hidden;
            padding: 13px 15px;

            &::-webkit-scrollbar-track {
                background-color: transparent;
            }

            &::-webkit-scrollbar {
                width: 6px;
                background-color: transparent;
            }

            &::-webkit-scrollbar-thumb {
                border-radius: 22px 0 0 22px;
                background-color: $color-scrollbar-plugins;
            }
        }
    }

    &__devices {
        width: 463px;
        height: 450px;
        border-radius: 4px;

        table {
            table-layout: fixed;
            width: 100%;
        }

        th {
            font-size: 16px;
            text-align: left;
            padding: 5px 10px 5px 10px;
            color: $color-white;
            background-color: $text-color-blue;
        }

        td {
            font-size: 14px;
            text-align: left;
            word-break: break-all;
            padding: 6px 10px 6px 10px;
        }

        &-empty {
            text-align: center;
            font-size: 18px;
            padding: 30px 0 10px;
        }
    }

    &__scroll-body {
        height: 300px;
        overflow-x: auto;

        &::-webkit-scrollbar {
            width: 6px;
        }

        &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 6px rgba($color-black, 0.3);
        }

        &::-webkit-scrollbar-thumb {
            box-shadow: inset 0 0 6px rgba($color-black, 0.3);
        }
    }
}
