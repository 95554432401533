@import '../../../../../styles/variables';
@import '../../../../../styles/fonts';

.main-content-dialog {
    flex-grow: 1;
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: Ezlo Sans;
    color: $color-black;

    .icon-dialog {
        margin-top: 4rem;

        &-restore {
            margin-top: 6rem;
        }

        &-completed {
            margin-top: 6rem;
        }
    }

    .text {
        margin-top: 2rem;
        text-align: center;

        &-auto-width {
            max-width: 77rem;
        }
    }

    .description {
        text-align: center;
        margin-top: 1rem;
        font-weight: 400;
        font-size: 1.6rem;
        line-height: 1.38;
    }

    .title-dialog {
        font-weight: 600;
        font-size: 3.2rem;
        line-height: 1;
        text-align: center;
    }

    .animated-dots::after {
        overflow: hidden;
        display: inline-block;
        vertical-align: bottom;
        -webkit-animation: ellipsis steps(4, end) 900ms infinite;
        animation: ellipsis steps(4, end) 900ms infinite;
        content: '\2026'; /* ascii code for the ellipsis character */
        width: 0;
    }

    .main-content-footer-dialog {
        margin-top: 5rem;
        display: flex;
        justify-content: center;

        .primary-btn {
            width: 39.2rem;
            height: 5.6rem;
            border-radius: 0.4rem;
            color: #fff;
            @include font-medium();
            font-size: 1.6rem;
            background-color: $color-button-blue;
            border-color: #fe5c15;
        }

        .warning {
            width: 39.2rem;
            height: 5.6rem;
            border-radius: 0.4rem;
            @include font-medium();
            font-size: 1.6rem;
            color: #fff;
            background-color: #fe5c15;
            border-color: #fe5c15;
        }
    }
}
