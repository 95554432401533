@import '../../../styles/variables';

.ezlo-dashboard-page {
    width: 100%;
    margin-top: -30px;
    @media (max-width: $break-d-xx) {
        padding-top: 65px;
    }
    iframe {
        width: 100%;
        min-height: 100%;
    }
}

.ezlo-section {
    &.ezlo-dashboard-page {
        height: 100vh;
    }
}

.page-header {
    &.header {
        &.dashboard-header {
            @media (max-width: $break-d-xx) {
                padding: 19px 0 0 9px;
            }
        }
    }
}
