@import '../../../../../styles/variables';
@import '../../../../../styles/mixins';

.select {
    width: 300px;
}

.generatorForm {
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;

    .generatorForm__group {
        @include flexBox();
    }

    .generatorForm__block {
        @include flexBox(inherit, center);
        margin-bottom: 20px;

        :global {
            .MuiOutlinedInput-notchedOutline {
                legend {
                    font-size: 10px;
                }
            }
        }
    }

    .generatorForm__block_item {
        :global {
            .MuiInputBase-input {
                padding: 14px 18px 14px 0;
            }

            .MuiInputLabel-formControl {
                transform: translate(0, 20px) scale(1);
            }

            .MuiInputLabel-shrink {
                transform: translate(0, -6px) scale(0.75);
            }
        }
    }

    .generatorForm__body {
        margin: 50px 0;

        h3 {
            margin-bottom: 30px;
        }
    }

    .generatorForm__input {
        text-transform: capitalize;
    }

    .generatorForm__select {
        @include flex(0, 1, 300px);
    }

    .generatorForm__select,
    .generatorForm__input {
        @media (max-width: $break-d-s) {
            justify-content: center;
            align-self: stretch;
            flex: 1 1 auto;
        }
    }

    .generatorForm__label {
        font-size: 16px;
        min-width: 170px;
        margin-right: 20px;

        &:first-letter {
            text-transform: uppercase;
        }
    }

    .generatorForm__buttonCreate {
        display: block;
        margin: 0 auto;
    }

    .generatorForm__buttonCreate,
    .generatorForm__buttonAdd {
        font-size: 14px;
    }

    .generatorForm__card {
        background: $color-button-grey-4;
        border-radius: 8px;
        padding: 20px;
        margin-bottom: 40px;

        .generatorForm__label {
            width: 320px;
        }
    }

    .generatorForm__card_title {
        margin-top: 50px;
        margin-bottom: 30px;
    }

    :global {
        .MuiFormLabel-root {
            font-size: 14px;
        }

        .toggle-icon {
            width: 20px;
        }

        .MuiInput-formControl {
            margin-top: 0;
        }
    }
}
