@import '../../../../../styles/variables.scss';
@import '../../../../../styles/mixins';
@import '../../../../../styles/fonts';

.virtualDeviceWrapper {
    padding: 10px 4px;
    border-bottom: 1px solid rgba($text-color-grey, 0.15);
    &:first-child {
        border-top: 1px solid rgba($text-color-grey, 0.15);
    }
}
.virtualDeviceItem {
    display: flex;
    align-items: center;
    height: 40px;
    padding-right: 4px;
    color: $text-color-black;
    font-size: 16px;
    line-height: 20px;
    @include font-regular();
    &:hover {
        background: rgba($text-color-grey, 0.15);
        border-radius: 16px;
    }
}
.virtualDeviceActions {
    display: flex;
    margin-left: auto;
    color: $color-black;
    :global {
        button {
            svg {
                width: 24px;
                height: 24px;
            }
        }
    }
}
.virtualDeviceIconContainer {
    width: 48px;
    height: 49px;
    margin-left: 16px;
    margin-right: 2px;
    :global {
        svg {
            width: 46px;
        }
    }
}
.virtualDeviceName {
    display: inline-block;
    vertical-align: middle;
    color: black;
    font-size: 18px;
    font-weight: 400;
    line-height: normal;
    @include ellipsis();
}
