@import '../../../../../styles/fonts';
@import '../../../../../styles/mixins';
@import '../../../../../styles/variables';

.pinCodesContainer {
    padding: 79px 40px 20px 40px;
    @media (max-width: $break-t-m-expression) {
        padding: 100px 10px 10px;
    }
}
.personIcon {
    padding: 17px;
    width: 56px;
    height: 59px;
}
.personBox {
    @include flexDirectionAlign(row, center);
    @include font-medium();
    font-size: 18px;
    margin-bottom: 13px;
}
.tableContainer {
    :global {
        .MuiTableRow-root {
            height: 60px;
        }
        .MuiTableContainer-root {
            height: calc(100vh - 210px);
            @include listScrollbar();
            @media (max-width: $break-d-m) {
                height: calc(100vh - 190px);
            }
        }
        .MuiTableCell-root {
            font-size: 14px;
            padding: 6px 15px;
            @include font-medium();
        }

        .MuiTableBody-root:first-child {
            border-top: 1px solid $table-border-color;
        }
    }
}
.iconCell {
    padding: 0 !important;
}
.headerIconCell {
    width: 50px;
}
.buttonBox {
    @media (max-width: $break-d-xx) {
        display: flex;
        flex: 1 1 auto;
        justify-content: right;
        padding: 0 30px 0 10px;
    }
    @media (max-width: $break-t-m-expression) {
        margin: 5px 0;
        padding: 0 25px;
        justify-content: center;
    }
}
