@import '../../../../../styles/variables';

.switchToolbarContainer {
    :global {
        .MuiTypography-body1 {
            font-size: 14px;
            font-family: $ezloSans;
            font-weight: 400;
            margin-left: 2px;
        }

        .MuiFormControlLabel-root {
            margin: 0;
        }

        .MuiButton-label {
            flex-wrap: nowrap;
        }
    }
}
