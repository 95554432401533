@import '../../../../../styles/variables';
@import '../../../../../styles/mixins';

.device-settings__string {
    width: 300px;

    .MuiOutlinedInput-notchedOutline {
        legend {
            font-size: 10px;
        }
    }

    .device-alarm__block {
        max-width: 100%;
    }

    &-input {
        margin-bottom: 30px;
        margin-right: 0;

        input {
            padding: 14px 18px;
        }
    }

    &-buttons {
        text-align: center;
        padding-bottom: 20px;
        padding-top: 20px;

        button {
            max-width: 300px;
            width: 100%;
            background: $toggle-color;
            font-size: 14px;
            border-radius: 40px;
            padding: 10px 25px;

            &:hover {
                background: $additional-text-color;
            }
        }
    }
}
