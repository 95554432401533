@import '../../../../styles/variables';
@import '../../../../styles/fonts';
@import '../../../../styles/mixins';

.selectField {
    width: 100%;

    :global {
        button.MuiButtonBase-root {
            width: auto;
            height: auto;
        }

        .MuiSelect-icon {
            width: 23px;
        }

        .MuiFormLabel-root {
            font-size: 16px;
            @include font-medium();
        }

        .MuiChip-root {
            font-size: $field-font-size;
            @include font-medium();
        }

        .MuiAutocomplete-inputRoot,
        .MuiFormControl-root {
            background-color: $color-white;

            &:hover {
                background-color: $color-white;
            }
        }
    }
}

.controllerIcon {
    margin-right: 10px;
}

.menuItem {
    display: flex;
    flex: 1 1 auto;
    justify-content: space-between;
}

.popupIcon {
    width: 23px;
}

.checkbox {
    svg {
        width: 24px;
        height: 24px;
    }
}

.controllerBox {
    @include flexDirectionAlign(row, center);
}

.optionWrapper {
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
}

.roomNameWrapper {
    font-size: 14px;
    margin: 0;
}

.currentRoomName {
    display: block;
    color: #a2a0a0;
    font-size: 12px;
}
