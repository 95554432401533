@import '../../../../../styles/variables.scss';
@import '../../../../../styles/fonts.scss';

.expressionItem {
    background-color: $color-tooltip-background;
    border: 1px solid $text-color-grey;
    border-radius: 12px;
    padding: 12px 13px 10px 13px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 1em;
    @media (max-width: $break-d-s) {
        padding-bottom: 20px;
    }
    :global {
        input {
            padding: 7px 12px;
        }
        .MuiSelect-outlined.MuiSelect-outlined {
            padding: 7px 30px 5px 12px;
        }
        .MuiInputBase-root.Mui-disabled {
            color: $color-black;
        }
    }
}
.expressionItemData {
    display: flex;
    align-items: flex-start;
    flex-grow: 1;
    margin-right: 30px;
    @media (max-width: $break-d-s) {
        flex-direction: column;
        align-items: start;
    }
    @media (max-width: $break-m-m) {
        max-width: 165px;
    }
}
.expressionItemEqualSymbol {
    position: relative;
    border-radius: 20%;
    background-color: $color-black;
    font-weight: 600;
    margin: 7px 14px;
    color: $color-white;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    padding: 10px;
    @media (max-width: $break-d-s) {
        align-self: flex-start;
        margin: 0 0 13px;
        font-size: 26px;
        width: 30px;
        height: 28px;
    }
    @media (max-width: $break-t-s) {
        align-self: center;
        margin-right: 0;
    }
}
.expressionItemInfo {
    width: 100%;
    max-width: 700px;
}
.expressionItemTitle,
.expressionItemBody {
    width: 100%;
    padding: 8px 12px;
    border-radius: 4px;
    border: 1px solid $secondary-text-color;
    background-color: $color-white;
    color: $color-black;
}
.expressionItemTitle {
    max-width: 200px;
    word-break: break-all;
    @media (max-width: $break-d-s) {
        max-width: 500px;
        margin-bottom: 10px;
    }
}
.expressionItemBody {
    max-width: 700px;
    word-break: break-word;
}
.expressionItemText {
    font-size: 14px;
    margin-bottom: 0;
    word-break: break-all;
}
.valueColor,
.errorColor {
    @include font-regular();
}
.valueColor {
    color: $color-blue-1;
}
.errorColor {
    color: $color-warning-active;
}
.expressionItemActions {
    display: flex;
    align-items: flex-start;
}
.variableItemInfo {
    display: flex;
    align-items: center;
    max-width: 1000px;
    width: 100%;
    min-height: 32px;
    @media (max-width: $break-d-s) {
        width: 100%;
        max-width: 500px;
        margin: 10px 20px 10px 0;
    }
}
