.dialogWrap {
    .closeIconWrap {
        padding: 10px;
    }
    .infoIconWrap {
        text-align: center;
        svg {
            width: 100%;
        }
    }
    :global {
        .MuiDialog-paperWidthSm {
            max-width: 440px;
        }
        .MuiPaper-rounded {
            border-radius: 24px;
        }
        .MuiDialogTitle-root {
            padding: 5px 20px;
        }
        .MuiSvgIcon-root {
            font-size: 32px;
            fill: #1c1e21;
        }
        .MuiTypography-h6 {
            font-size: 32px;
            font-weight: 600;
            line-height: 36px;
            color: #1c1e21;
            font-family: 'Ezlo Sans';
            text-align: center;
        }
        .MuiDialogContent-root {
            padding: 8px 20px;
        }
        .MuiDialogContentText-root {
            margin-bottom: 0;
        }
        .MuiTypography-body1 {
            font-size: 16px;
            font-weight: 400;
            font-family: 'Ezlo Sans';
            color: #1c1e21;
            text-align: center;
        }
        .MuiDialogActions-root {
            padding: 60px 20px 25px;
            justify-content: space-between;
        }
        .MuiButton-root {
            width: 190px;
        }
        .MuiDialogActions-root .MuiButton-label {
            font-size: 13px;
        }
    }
}
