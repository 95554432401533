@import '../../../../styles/variables.scss';
@import '../../../../styles/mixins.scss';

.modal {
    @include flexBox(center, center);

    .modalContainer {
        @include modalContainer();
        padding: 25px 24px 36px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        box-shadow: 0 8px 24px rgba(144, 160, 175, 0.3);
        border-radius: 24px;
        min-height: 486px;

        .closeBtn {
            display: flex;
            justify-content: flex-start;
            margin: 0;

            svg {
                cursor: pointer;
                width: auto;
                height: auto;
            }
        }

        svg {
            width: 100%;
        }

        h4 {
            font-size: 32px;
            font-weight: 600;
            line-height: 38px;
            margin: 0 0 8px 0;
        }

        .tryAgain {
            font-size: 16px;
            font-weight: 400;
            line-height: 23.04px;
            display: block;
        }
        .message {
            font-family: 'Ezlo Sans';
            font-size: 24px;
            font-weight: 600;
            line-height: 29px;
            letter-spacing: 0em;
            text-align: center;
        }

        .btn {
            display: block;
            margin: 32px auto 0;
            background-color: $text-color-warning;
            color: $color-white;
            height: 40px;
            width: 100%;
        }

        .waitText {
            font-size: 16px;
            line-height: 23.04px;
            font-weight: 400;
            color: $text-color-basic-black;
            margin: 8px 0 0 0;
        }

        .spinnerWrapper {
            animation-name: spin;
            animation-duration: 2000ms;
            animation-iteration-count: infinite;
            animation-timing-function: linear;
            align-self: center;

            svg {
                width: auto;
            }
        }

        @-ms-keyframes spin {
            from {
                -ms-transform: rotate(0deg);
            }
            to {
                -ms-transform: rotate(360deg);
            }
        }
        @-moz-keyframes spin {
            from {
                -moz-transform: rotate(0deg);
            }
            to {
                -moz-transform: rotate(360deg);
            }
        }
        @-webkit-keyframes spin {
            from {
                -webkit-transform: rotate(0deg);
            }
            to {
                -webkit-transform: rotate(360deg);
            }
        }
        @keyframes spin {
            from {
                transform: rotate(0deg);
            }
            to {
                transform: rotate(360deg);
            }
        }

        &.modalContainerFailed {
            justify-content: space-around;
        }
    }
}
