@import '../../styles/variables';
@import '../../styles/mixins';

.week_wrapper {
    @include flexBox();
    @include itemsGap;
}

.week_item {
    &:first-child {
        margin-left: 0;
    }
    :global {
        .MuiIconButton-colorSecondary {
            display: none;
        }
        .MuiTypography-body1 {
            @include flexBox(center, center);
            @include itemsFlow(50px);
        }
        .MuiFormControlLabel-root {
            margin: 0;
        }
    }
}

.active {
    :global {
        .MuiTypography-body1 {
            @include itemsActiveFlow;
        }
    }
}
