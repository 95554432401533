@import '../../../../../styles/variables.scss';

.buttonsContainer {
    position: fixed;
    right: 50px;
    bottom: 30px;
    z-index: 100;
    display: flex;
}
.integratedDevicesButtonWrapper {
    position: relative;
    margin-left: 20px;
    display: inline-block;
}
