@import '../../../styles/variables';
@import '../../../styles/mixins';

.headerDevices {
    justify-content: left;
    .title {
        margin-bottom: 0;
        font-size: 2.4rem;
        font-weight: normal;
        color: #1c1e21;
    }
}

.devices {
    position: relative;
    padding: 40px;
    .devicesList {
        @include flexBox();
        @include flexFlow(wrap);
    }
    .devicesGroupList {
        width: 100%;
    }
}
.headerDevices {
    @media (max-width: $break-d-xx) {
        padding: 19px 10px 5px !important;
    }
}
