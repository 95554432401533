@import '../../../../../styles/variables';
@import '../../../../../styles/fonts';
@import '../../../../../styles/mixins';

.cell {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    position: relative;
    text-overflow: ellipsis;

    &:hover {
        cursor: pointer;
        text-decoration: underline;
    }

    :local {
        .icon {
            width: 20px;
            min-width: 20px;
            margin-right: 8px;
        }

        .name {
            font-size: 16px;
            text-overflow: ellipsis;
        }
    }
}

.disabled {
    pointer-events: none;
    color: #999;

    path {
        fill: #999;
    }
}
