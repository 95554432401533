@import '../../../styles/variables';
@import '../../../styles/mixins';

.lua-scripts {
    padding: 40px 60px 40px 40px;
    position: relative;
    height: calc(100vh - 30px);
    @media (max-width: $break-t-m) {
        padding: 70px 10px 0;
    }
    .notifications-component {
        right: 0;
    }
    &__list {
        h2 {
            font-size: 26px;
            font-weight: bold;
        }
    }
    .script-list {
        .MuiDataGrid-cell,
        .MuiDataGrid-columnHeader {
            &:focus,
            &:focus-within {
                outline: none;
            }
        }
    }
}
