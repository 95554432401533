@import "../../../../styles/variables";

.controllers-select {
  margin-top: 50px;
  padding: 0 10px;
  .MuiFormControl-root {
    width: 100%;      
  }
  .MuiInputBase-input {
    padding: 0 0 0 7px;
  }
}

.select-item-content {
    display: flex; 
    align-items: center;
    .controller-serial {
      color: $text-color-grey;
      font-size: 18px;
      line-height: 1.2;
      font-weight: 400;
  }
  .controller-serial.online {
    color: $text-color-black;
  }
  .controller_icon.icon-container {
    &:before {
      position: absolute;
      top: 10px;
      right: 10px;
      z-index: 1;
      content: '';
      border-radius: 50%;
      border: 2px solid white;
      box-sizing: content-box;
      width: 8px;
      height: 8px;
      background-color: $text-color-warning;
    }
    svg {
      position: relative;
      fill: $text-color-grey;
      height: 24px;
      width: 24px;
    }
  }
  .controller_icon.icon-container.online {
    &:before {
      background-color: $color-online;
    }
    svg {
      fill: $text-color-black;
    }
  }
}
