@import '../../../styles/variables';
@import '../../../styles/fonts';

.environment {
    display: flex;
    align-items: center;
    margin-top: 10px;

    &__title {
        font-size: 16px;
        margin: 0;
        padding-right: 10px;
    }
}

.signupLabel {
    font-size: 16px;
    width: 100%;
    text-align: center;
    display: inline-block;
    margin-top: 48px;

    @media (max-width: $break-m-m) {
        margin-top: 20px;
    }
}

:global(.MuiButtonBase-root).signupBtn {
    @include font-normal();
    margin-top: 16px;
    height: 56px;
    font-size: 16px;
    text-transform: none;
    border: 1px solid rgba($text-color-grey, 0.25);
    padding: 0;

    span {
        display: flex;
        height: 100%;

        a {
            color: $text-color-blue;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            width: 100%;
        }
    }

    &:hover {
        background-color: transparent;
        box-shadow: 0 4px 8px rgba($text-color-grey, 0.3);
    }
}
