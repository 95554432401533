@import '../../../../../styles/mixins.scss';

.section {
    @include flexDirectionAlign(column, center);
    margin-bottom: 20px;
}

.sectionWithMargin {
    margin: 0 0 24px;
}
