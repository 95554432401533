@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/fonts';

.virtualDevicesContainer {
    height: inherit;
    padding: 50px 40px 24px;
    @media (max-width: $break-m-l) {
        padding: 90px 10px 10px;
    }
    @media (max-width: 340px) {
        padding-top: 140px;
    }
}
.virtualDevicesForm {
    @include flexFlow(wrap, column);
}
.submitButton {
    @include flexBox(flex-end, center);
    margin-top: 15px;
    button {
        min-width: 200px;
    }
    @media (max-width: $break-m-l) {
        @include flexBox(center, center);
    }
}
.virtualDevice,
.fieldsBox {
    @include flexDirectionAlign(row, center);
    @include flexFlow(wrap, row);
    @media (max-width: $break-m-l) {
        @include flexBox(center, center);
    }
}
.addButton {
    @media (max-width: $break-m-l) {
        @include flexBox(center, center);
    }
}
.fieldBox {
    display: flex;
    margin: 15px;
    &:first-child {
        margin-left: 0;
    }

    :global {
        .MuiFormHelperText-root.Mui-error {
            position: absolute;
            top: 18px;
            font-size: 10px;
            width: inherit;
            @include font-normal();
        }

        .MuiFormLabel-root {
            font-size: 14px;
            @include font-normal();
        }
    }
}
.autocomplete {
    min-width: 250px;
}
.numberField {
    margin-bottom: -5px;
}
.deleteButton {
    display: flex;
    flex-direction: column;
}
.headerTitle {
    display: flex;
    flex: 1 1 auto;
    @media (max-width: $break-d-xx) {
        justify-content: center;
    }
    @media (max-width: $break-m-l) {
        justify-content: flex-start;
    }
    @media (max-width: 350px) {
        justify-content: center;
    }
}
.switchControllersBox {
    margin-left: 20px;
    @media (max-width: $break-m-l) {
        display: flex;
        flex: 1 1 auto;
        justify-content: center;
    }
}

.installPluginBox {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    flex: 1 1 auto;
    height: 70%;
    .description {
        font-size: 25px;
        @include font-normal();
    }
    button {
        margin: 20px;
        min-width: 150px;
    }
}

.linearProgressBox {
    width: 100%;
    position: absolute;
    top: 0;
    right: 0;
}

.linearProgress {
    z-index: 1200;
}
