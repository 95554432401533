@import '../../../../../styles/variables';

.iconTooltipBox {
    .tooltipBox {
        background-color: $color-white;
        border: 1px solid $border-grey;
        color: $color-black;
        &::before {
            display: none;
        }
        &::after {
            display: none;
        }
    }
}
