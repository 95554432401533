@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/fonts';

// todo: apply refactoring and remove EzloMeshbots page styles
.mesh-bot {
    padding: 24px 40px;

    &__and {
        position: relative;
        font-size: 12px;
        line-height: 1.3;
        color: $color-white;
        text-transform: uppercase;
        background: $color-button-blue;
        border-radius: 4px;
        padding: 6px 14px;
        margin: 0;

        &:after {
            content: '';
            display: none;
            position: absolute;
            bottom: -12px;
            left: 7px;
            height: 12px;
            border-color: $color-button-grey-2;
            border-style: solid;
            border-width: 0 0 0 2px;
            box-sizing: border-box;
        }

        &.show {
            &:after {
                display: block;
            }
        }
    }

    &__container {
        position: relative;
        margin-top: 40px;
    }

    &__list-triggers {
        .mesh-bot__group {
            .mesh-bot__group {
                padding-right: 5px;
            }
        }

        &.activeConnection {
            & > .connections {
                &:after,
                &:before {
                    display: block;
                }
            }
        }

        .trigger {
            cursor: grabbing; // todo: fix by "grab"
            &_ondrag {
                & > div {
                    box-shadow: 8px 8px 20px rgba($color-black, 0.15);
                }
            }
        }
    }

    &__list-empty {
        padding-top: 10px;
    }

    &__header {
        @include flexBox(space-between, center);

        .input-block {
            max-width: 400px;
        }

        &-serial {
            font-size: 20px;
            color: $color-button-grey-1;
            margin: 0;

            span {
                color: $text-color-black;
            }
        }
    }

    &__list {
        margin-top: 10px;
        max-height: 85vh;

        .MuiTableCell-head {
            font-size: 13px;
            font-weight: 500;
            text-transform: uppercase;
        }

        .MuiTableCell-body {
            font-size: 12px;
        }

        .MuiTablePagination-caption,
        .MuiTablePagination-select {
            font-size: 11px;
        }

        .MuiTablePagination-select {
            @include flexBox(center, center);
        }

        .MuiTableRow-root {
            &.disabled {
                background-color: rgba(0, 0, 0, 0.04);
                pointer-events: none;

                .MuiIconButton-label {
                    color: $color-button-grey-2;
                }
            }
        }
    }

    &__sections-wrap-header {
        padding: 10px;
        background-color: $color-blue-2;
        border-top: 3px solid $color-blue-1;
        border-bottom: 0 solid $color-blue-1;
        height: 50px;
        z-index: -1;
        position: relative;

        &::after,
        &::before {
            content: ' ';
            position: absolute;
            height: 10px;
            width: 300px;
            border-top-left-radius: 40px 40px;
            border-top-right-radius: 0;
            border: 3px solid $color-blue-1;
            border-right: 0px solid $main-bg-color;
            bottom: 19px;
            border-bottom: 0;
        }

        &::after {
            left: -2px;
            transform: rotate(-8.1deg);
            box-shadow: 10px -30px 0px 20px $main-bg-color;
        }

        &::before {
            right: -2px;
            transform: rotate(8.2deg) scale(-1, 1);
            box-shadow: -23px -26px 0px 20px $main-bg-color;
        }
    }

    &__input-block-wrap {
        display: flex;
        align-items: flex-end;
        justify-content: center;
    }

    &-local__img-wrap {
        width: 50px;
        height: 65px;
        transform: rotateZ(-10deg);

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    &__input-wrap {
        position: relative;
        display: flex;
        justify-content: center;
        padding: 5px 20px;
        border-radius: 30px 30px 0 0;
        background-color: $color-blue-1;

        .tab-label-decor_left,
        .tab-label-decor_right {
            &::before {
                bottom: 0;
                left: 0;
                box-shadow: -35px 25px 0 0 $color-blue-1;
            }
        }
    }

    &-local__trapezoid-triggers,
    &-local__trapezoid-actions {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 250px;
        height: 44px;
        padding: 3px 6px;
        border-radius: 30px 30px 0 0;
        color: $color-white;
        font-size: 24px;
        position: relative;
        text-transform: uppercase;

        .tab-label-decor_left,
        .tab-label-decor_right {
            &::before {
                bottom: 0;
                left: 0;
                box-shadow: -35px 25px 0 0 #528baf;
            }
        }
    }

    &-local__trapezoid-triggers {
        .tab-label-decor_left,
        .tab-label-decor_right {
            &::before {
                bottom: 0;
                left: 0;
                box-shadow: -35px 25px 0 0 #c36138;
            }
        }
    }

    &-local__trapezoid-triggers {
        background: transparent linear-gradient(180deg, #f5a17c 0%, #ff996c 21%, #d6815c 52%, #c36138 100%) 0% 0%
            no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
    }

    &-local__trapezoid-actions {
        background: transparent linear-gradient(180deg, #90d4ff 0%, #99d3f8 25%, #72a3c1 50%, #528baf 100%) 0% 0%
            no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;

        &::after,
        &::before {
            background: none;
        }
    }

    &__tab .Mui-selected {
        .MuiTab-wrapper {
            opacity: 1;
            color: $meshbot-action-section;
        }

        &:before {
            z-index: 2;
            border-bottom: 2px solid transparent;
        }
    }

    &__tab .MuiTabs-indicator {
        display: none;
    }

    &__tab .MuiTabs-flexContainer {
        justify-content: space-between;
    }

    &__table {
        &-td {
            @include flexBox(inherit, center);
            margin-bottom: 0;

            span {
                text-transform: capitalize;
                margin-left: 10px;
            }

            svg {
                width: 28px;
            }
        }

        &-buttons {
            .MuiSvgIcon-root {
                width: 20px;
                height: 20px;
            }

            button {
                margin: 0 4px;
            }
        }
    }

    &__group.connections {
        border-left: 4px solid $left-border-inValid;
    }

    &__group.is-valid.connections {
        border-left: 4px solid $left-border-valid;
    }

    &__group.editing.connections {
        border-left: 4px solid $left-border-saved;
    }

    &__group {
        background-color: #e1e1e1;
        border-radius: 5px;
        padding: 8px 15px 16px 20px;
        margin-bottom: 16px;

        .mesh-bot__group-deleteBtn {
            padding: 0 !important;
            background-color: transparent !important;

            svg {
                width: 25px;
                height: 25px;
                fill: $meshbot-delete-icon;
            }
        }

        &-header {
            @include flexBox(space-between, flex-end);
            margin-bottom: 10px;
        }

        &-operators {
            @include flexBox();

            &_wrap {
                @include flexBox();
                position: relative;

                &:after {
                    content: '';
                    display: none;
                    position: absolute;
                    bottom: -12px;
                    left: 7px;
                    height: 12px;
                    border-color: $color-button-grey-2;
                    border-style: solid;
                    border-width: 0 0 0 2px;
                    box-sizing: border-box;
                }

                &.show {
                    &:after {
                        display: block;
                    }
                }

                .checkbox-button__label {
                    background-color: #fff;
                    border-right: none;

                    &.active {
                        background-color: #e64d4e;
                    }
                }
            }

            .radio-button {
                &:first-child {
                    .radio-button__label {
                        border-radius: 2px 0 0 2px;

                        .activeGroup & {
                            border-radius: 0;
                        }
                    }

                    & .activeGroup {
                        border-radius: 0;
                    }
                }

                &:last-child {
                    .radio-button__label {
                        border-radius: 0 4px 4px 0;
                    }
                }
            }
        }

        &-block {
            @include flexBox(inherit, flex-end);

            .group-name {
                margin-left: 15px;

                .MuiInputBase-root .MuiInputBase-input {
                    padding: 10px;
                    font-size: 16px;
                    font-family: 'Roboto', sans-serif;
                }
            }
        }

        &-buttons {
            @include flexBox(inherit, center);
            margin-left: 10px;
            gap: 15px;

            button {
                height: 40px;
                text-transform: capitalize;
            }

            .MuiButton-outlined {
                margin-right: 10px;
                padding: 4px 10px;
            }

            .MuiIconButton-colorSecondary {
                color: $color-button-grey-3;
            }

            .MuiSvgIcon-root {
                font-size: 24px;
            }

            .MuiIconButton-root {
                padding: 10px;
            }
        }

        &-collapse {
            button {
                padding: 0 !important;
                background-color: transparent;

                svg {
                    fill: $text-color-black;
                    transform: rotate(0);
                    transition: transform 0.3s ease;
                }
            }
        }

        .mesh-bot__group-operators_wrap {
            .checkbox-button__label {
                padding: 6px 10px;
            }
        }

        .collapse-close {
            > .mesh-bot__list-triggers {
                opacity: 0;
                max-height: 0;
                transition: all 0.2s ease;
            }

            .mesh-bot__group-operators_wrap {
                .checkbox-button__label {
                    padding: 6px 10px;
                }

                &.show {
                    &::after {
                        display: none;
                    }
                }
            }

            .mesh-bot__group-collapse {
                button {
                    svg {
                        transform: rotate(-180deg);
                    }
                }
            }
        }

        .collapse-open {
            > .mesh-bot__list-triggers {
                opacity: 1;
                max-height: 1500vh;
                transition: all 0.17s ease;
            }
        }
    }

    .meshbot-cell-icon {
        display: flex;
        justify-content: left;
        align-items: center;
        margin-left: 10px;
        width: 100%;

        &-info {
            color: #3f51b5;
        }

        svg {
            align-self: center;
            width: 20px;
        }

        span {
            align-self: center;
            margin-left: 10px;
        }
    }
}

.MuiAutocomplete-paper {
    .MuiAutocomplete-groupLabel {
        font-size: 14px;
        font-weight: bold;
        color: #444;
        line-height: 25px;
    }

    .MuiAutocomplete-option {
        font-size: 12px;
        color: #6c6969;
    }
}

.trigger-block__footer.date {
    .trigger-block__select,
    .cloud-once-subnode__date {
        display: flex;
        justify-content: flex-end;
    }

    .cloud-once-subnode__date {
        margin-top: 20px;
    }

    .input-block {
        display: flex;
        align-items: flex-end;
    }

    .month {
        margin-top: 10px;
    }
}

.meshbot-name {
    margin: 0;

    .MuiInputBase-root .MuiInputBase-input {
        width: 250px;
        height: 34px;
        padding: 3px 6px;
        font-weight: 600;
        border-radius: 15px;
        border: 2px solid $border-inValid;
        background: transparent linear-gradient(180deg, #ededed 0%, #fff8f8 37%, #d0cece 60%, #d1cdcd 100%) 0% 0%
            no-repeat padding-box;
        text-align: center;
        box-sizing: border-box;
    }

    .MuiOutlinedInput-notchedOutline {
        border: none;
    }

    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-color: transparent;
    }

    &.is-valid .MuiInputBase-root .MuiInputBase-input {
        border: 2px solid $border-valid;
    }
}

.trigger-block.is-valid {
    border-left: 4px solid $left-border-valid;
}

.trigger-block.editing {
    border-left: 4px solid $left-border-saved;
}

.trigger-block.disabled-validation {
    border-left: 4px solid transparent;
}

.trigger-block {
    .trigger-block-select__cameras {
        display: flex;
        padding-left: 10px;
        border-left: 1px solid rgba(168, 178, 208, 0.4);

        .description {
            padding: 20px 15px 0 0;
            font-size: 16px;
            font-weight: 600;
        }
    }

    .mesh-bot__builder & {
        // todo: fix style duplication
        &__action_select {
            width: auto;
            min-width: 140px;
            margin-right: 30px;
        }

        .input-number {
            margin-bottom: 10px;
            margin-right: 25px;
        }

        &__select {
            margin-top: 0px;
            margin-right: 25px;
            min-width: 120px;
            margin-bottom: 10px;
            width: auto;

            .MuiInputBase-root {
                padding-right: 22px;
            }

            &.flex-start {
                align-self: flex-start;
            }

            // width: auto;

            &.custom_day-size {
                width: 100px;
            }

            &.custom_month-size {
                width: 120px;
            }

            &-label {
                font-size: 14px;
            }

            &_big {
                max-width: 250px;
            }

            &_margin {
                margin-top: 0px;
            }

            .MuiInputBase-input {
                width: 100%;
                padding: 4px 0 !important;

                &:focus {
                    background-color: transparent;
                }
            }

            .MuiOutlinedInput-adornedEnd {
                padding: 0;
            }

            .MuiInputBase-root {
                &.MuiAutocomplete-inputRoot {
                    .MuiAutocomplete-input:first-child {
                        padding: 14px 8px 11px;
                    }
                }
            }

            .MuiAutocomplete-root {
                label {
                    padding: 0;
                }
            }

            legend {
                font-size: 1em;
            }

            .MuiOutlinedInput-notchedOutline {
                border-color: #e0e0e0;
            }
        }

        &__meshbot-select {
            input {
                width: 100%;
            }
        }

        // todo: fix style duplication
        &__select-time {
            //width: auto;
            margin-top: 10px;

            &.custom_day-size {
                width: 116px;
            }

            &.custom_month-size {
                width: 130px;
            }

            &.custom_meridiem {
                width: 60px;
            }

            &-label {
                font-size: 14px;
            }
        }
    }

    &__buttons {
        display: flex;

        svg {
            width: 25px;
            height: 25px;
            fill: $meshbot-delete-icon;
        }
    }

    &__title {
        font-size: 16px;
        margin: 0 20px 0 0;
        text-transform: capitalize;
    }

    &__date-label {
        margin-bottom: 0;

        span {
            padding-left: 10px;
        }
    }

    &__save {
        margin-top: 20px;
    }

    &__daily {
        @include flexBox(inherit, center);
    }

    &__body {
        @include flexBox(flex-start, flex-start);
        min-height: 50px;
        max-width: calc(100% - 115px);
        padding: 0;
        @include flexFlow(wrap);

        &.between {
            @include flexBox(flex-start, flex-start);
        }

        &.notFlex {
            display: block;
            width: 100%;
        }

        &.flexEnd {
            .trigger-block__select {
                margin-top: -5px;
            }
        }

        &-field {
            @include flexBox(inherit, center);
            margin-top: 12px;
            padding-left: 0px;
        }

        .actions & {
            .MuiOutlinedInput-notchedOutline {
                legend {
                    font-size: 10px;
                }
            }
        }

        .MuiFormHelperText-root {
            font-size: 10px;
        }

        .PrivateNotchedOutline-legendLabelled-10 {
            font-size: 1.05em;
        }

        &.wrapFlex {
            flex-wrap: wrap;
        }
    }

    &__field {
        @include flexBox(inherit, flex-end);
        flex-wrap: wrap;

        .trigger-block__body-field & {
            margin-top: 0;
        }

        .between & {
            display: flex;
            align-items: flex-start;
        }

        .next-line {
            width: 100%;
        }

        .custom__text {
            min-width: 80px;
            margin-right: 10px;
        }

        &-group {
            @include flexBox();
            align-items: center;
            margin-top: 20px;

            &.offset {
                margin-top: 0;
                margin-bottom: 10px;

                span {
                    margin-right: 15px;
                }
            }

            .input-block {
                flex-shrink: 0;
            }
        }

        &-body {
            max-width: 600px;
            width: 100%;
            padding: 0 15px;

            .trigger-block__slider {
                width: 400px;
            }
        }

        .trigger-block__select-label {
            position: absolute;
            transform: translate(0, 1.5px) scale(0.75);
        }
    }

    &__select {
        &.operator {
            width: 80px;
        }

        .small & {
            width: 80px;
        }
    }

    &__input {
        .actions & {
            input {
                padding: 12px;
            }
        }

        &.long {
            max-width: 450px;
            width: 100%;
        }

        &.script {
            margin-top: 10px;
        }
    }

    .trigger-block__security {
        margin-top: 10px;
    }

    &__footer {
        &-title {
            font-size: 14px;
            margin-bottom: 10px;
        }

        &-group {
            @include flexBox();
        }

        .body-script {
            @include flexBox(inherit, flex-end);
        }

        .textarea-script {
            width: 602px;
        }

        .button-script {
            font-size: 12px;
            padding: 10px 20px;
        }

        &-section {
            margin-bottom: 15px;

            .MuiInputLabel-outlined {
                transform: translate(14px, 14px) scale(1);
            }
        }

        .notFlex & {
            border-top: 1px solid $divider-color;
            width: 100%;
            margin-top: 20px;

            .MuiSvgIcon-root {
                font-size: 22px;
            }
        }

        &.body-request {
            padding-left: 14px;
            padding-bottom: 0;
        }

        .toggle-icon {
            width: 20px;
        }
    }

    &__headers {
        &-item {
            @include flexBox(inherit, center);
            margin-bottom: 15px;

            .MuiInputBase-root {
                &.Mui-disabled {
                    background: rgba($color-button-grey, 0.4);
                }
            }

            .MuiFormLabel-root {
                &.Mui-disabled {
                    color: $color-button-grey-1;
                }
            }
        }
    }

    &__week,
    &__days {
        @include flexBox(inherit, flex-start);
    }

    &__custom {
        &-section {
            @include flexBox(flex-start, center);

            & + .trigger-block__custom-section {
                margin-top: 10px;
            }

            .custom__text {
                min-width: 87px;
                align-self: center;
                padding: 10px 20px 0 0;
            }
        }
    }

    .checkbox-button {
        margin-top: 8px;
        margin-right: 25px;
    }

    .MuiIconButton-colorSecondary {
        color: $color-button-grey-3;
    }

    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
        display: none;
    }

    .MuiFormLabel-filled {
        font-size: 14px;
    }

    .MuiTypography-body1 {
        font-size: 16px;
    }

    .MuiAccordionDetails-root {
        &.trigger-block__action-detail {
            display: block;
        }
    }

    .MuiSlider-marked {
        margin-bottom: 5px;
    }

    .MuiSlider-root {
        padding: 12px 0;
    }

    .MuiTypography-root {
        text-transform: capitalize;
    }

    .MuiSlider-colorPrimary {
        .MuiSlider-markLabel {
            font-size: 12px;
        }

        .MuiSlider-valueLabel {
            font-size: 12px;
        }

        .MuiSlider-mark {
            display: none;
        }

        .MuiSlider-rail,
        .MuiSlider-track {
            height: 4px;
            border-radius: 3px;
        }
    }

    .notification-wrapper {
        &.notification-wrapper-dashboard {
            label {
                background-color: $color-white;
                margin-top: 0;
            }
        }

        .MuiSelect-outlined.MuiSelect-outlined {
            padding-top: 12px;
        }

        .MuiFormControl-root {
            margin-right: 25px;

            .MuiInputBase-root {
                margin-top: 0;
            }
        }
    }

    &__container {
        @include flexBox(space-between, flex-start);
        @include flexFlow(wrap);
        width: 100%;

        .function-block__settings {
            padding: 5px 12px;
        }
    }
}

.tab-label-decor {
    width: 35px;
    height: 30px;
    overflow: hidden;
    position: absolute;
    bottom: 0;

    &::before {
        content: '';
        width: 200%;
        height: 200%;
        position: absolute;
        border-radius: 50%;
    }

    &_left {
        right: -32px;
    }

    &_right {
        left: -32px;
        transform: scale(-1, 1);
    }
}

// .trigger-current-items {
//     white-space: nowrap;
//     color: $main-text-color;
// }

// TODO: do not overwrite top lvl styles in specific /../../style.scss file because it effects all styles and it's hard to find the actual place of overwriting
// TODO: ONLY if necessary move top lvl styles overwriting to general /src/styles/index.scss
.MuiPickersClockNumber-clockNumber {
    &.MuiTypography-body2 {
        font-size: 14px;
    }

    &.MuiTypography-body1 {
        font-size: 14px;
    }
}

// TODO: do not overwrite top lvl styles in specific /../../style.scss file because it effects all styles and it's hard to find the actual place of overwriting
// TODO: ONLY if necessary move top lvl styles overwriting to general /src/styles/index.scss
.MuiDialogActions-root {
    .MuiButton-label {
        font-size: 14px;
    }
}

.trigger-block__action_coming {
    min-height: 40px;
    min-width: 140px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 0 5px;
    font-family: 'Ezlo Sans';
    font-size: 16px;
}

.builder-footer {
    display: flex;
    flex-direction: column;
}

div[role='tooltip'] {
    z-index: 9999;
}

// TODO: do not rewrite top lvl styles
.MuiPaper-root {
    .MuiBox-root {
        position: relative;
        background-color: $color-tooltip-background;
        max-width: 300px;
        color: $color-black;
        padding: 15px;
        font-size: 14px;
        border: 1px solid $color-black;
        border-radius: 3px;

        h4 {
            font-weight: bold;
        }

        a {
            color: $color-blue-3;
            text-decoration: underline;
        }

        button {
            position: absolute;
            top: 0;
            right: 0;
            padding: 8px;

            svg {
                color: $color-black;
                font-size: 14px;
                font-weight: bold;
            }
        }

        p {
            &:last-child {
                margin: 0;
            }
        }
    }
}

// TODO: refactoring needed
.mesh-bot__tab {
    .MuiButtonBase-root {
        &:nth-child(3) {
            top: 0;
            right: calc(100% - 20px);
            border: none;
            background-color: transparent;
            z-index: 2;

            svg {
                font-size: 16px;
                color: $text-color-dark-grey;
            }
        }
    }
}

// TODO: refactoring needed
.mesh-bot__tab {
    .MuiButtonBase-root {
        &:nth-child(4) {
            top: 0;
            right: 0;
            border: none;
            background-color: transparent;
            z-index: 2;

            svg {
                font-size: 16px;
                color: $text-color-dark-grey;
            }
        }
    }
}

.cloud-header,
.meshbot-edit-header,
.plugins-header,
.backups-header {
    @media (max-width: $break-d-xx) {
        padding: 19px 10px 5px !important;
    }

    &.header_empty {
        @media (min-width: $break-d-xx) {
            padding: 0;
        }
    }
}
