@import '../../../../../styles/variables';
@import '../../../../../styles/mixins';

.boxOfSaveOutput {
    width: 100%;
    min-height: 60px;
    padding-left: 14px;
    margin-bottom: 25px;
    :global {
        .MuiFormControl-root {
            margin-top: 0px;
        }
    }
}

.checkBox {
    :global {
        .MuiSvgIcon-root {
            font-size: 22px;
        }
    }
}

.autocompleteSelect {
    @include selectStyle(210px);
    :global {
        label {
            padding-top: 0;
            font-size: 14px;
        }
        .MuiFormControl-root {
            min-width: 210px;
        }
        .MuiAutocomplete-endAdornment {
            top: calc(50% - 10px);
        }
    }
}

.checkboxBlock {
    position: relative;
    display: inline-block;
    :global {
        .MuiFormControlLabel-root {
            margin-right: 0;
        }
        .MuiFormControlLabel-label {
            white-space: nowrap;
        }
    }
}

.infoButtonWrapper {
    position: absolute;
    top: 10px;
    right: -15px;
}

.selectBlock {
    width: 100%;
}

.errorText {
    color: $color-inValid;
    font-size: 12px;
    position: absolute;
    line-height: 16px;
    top: 38px;
}
.iconBox {
    width: 20px;
    cursor: pointer;
}
.toolBar {
    position: fixed;
    right: 0;
    left: 0;
    background-color: $color-white;
    z-index: 10;
    box-shadow: 0 1px 7px $border-gray-color;
}

.toolBarContent {
    padding: 0 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 70px;
}

.title {
    font-size: 24px;
}

.paper {
    font-size: 13px;
    font-family: $ezloSans;
}
.paperContent {
    :global {
        .MuiAutocomplete-listbox {
            max-height: 250px;
            @include listScrollbar();
        }
    }
}
.createButton {
    border-top: 1px solid $light-gray;
    padding: 10px 15px;
    cursor: pointer;
}
:global {
    .MuiDialog-paperFullScreen {
        @include listScrollbar();
    }
}
