@import '../../styles/variables';

.dynamic-field-block {
  font-size: 14px;
  display: flex;
  align-items: center;
  margin: 15px;
  &_input{
    flex: 1 1 auto;
  }
  &_label{
    color: $base-text-color;
    margin-right: 20px;
    width: 90px;
    flex-wrap: wrap;
    span {
      color: $danger-color-input-focus;
    }
  }
}

.dynamic-field-wrapper {
  .dynamic-field__description{
    font-size: 12px;
    color: rgba(0, 0, 0, 0.85);
    margin-left: 126px;
    margin-bottom: -8px;
    font-weight: 600;
  }
}