@import '../../../../../styles/variables';
@import '../../../../../styles/fonts';
@import '../../../../../styles/mixins';

.infoPopperBox {
    position: absolute;
    top: 3px;
    right: -50px;
    svg {
        width: 26px;
    }
}

.popperContainer {
    transform: none !important;
    :global {
        .MuiPaper-root .MuiBox-root {
            max-width: 100%;
            height: 100%;
            border: 0px;
            background: $paper-bg-color;
            padding: 15px 50px;
        }
    }
    .popperTitle {
        font-size: 26px;
        padding-bottom: 20px;
        border-bottom: $border-style;
        @include font-semibold();
    }
    h3 {
        @include font-semibold();
    }
    p {
        @include font-normal();
    }
    ul {
        max-width: 1400px;
        margin: 0 auto;
    }
    li {
        position: relative;
        margin-bottom: 20px;
        margin-left: 50px;
        &::before {
            content: '';
            position: absolute;
            top: 4px;
            left: -40px;
            width: 20px;
            height: 20px;
            border: 3px solid $color-grey;
            border-radius: 50%;
        }
    }
    .selectedTypeItem {
        position: relative;
        &::before {
            content: '';
            position: absolute;
            top: 4px;
            left: -40px;
            width: 20px;
            height: 20px;
            border: 6px solid $color-blue-3;
            border-radius: 50%;
        }
    }
}

.paperBox {
    overflow-y: scroll;
    @include listScrollbar();
}

.closeBtn {
    &:global(.MuiButtonBase-root.MuiIconButton-root) {
        top: 15px;
        right: 50px;

        svg {
            font-size: 24px;
        }
    }
}

.tableContainer {
    margin-top: 10px;
    @include listScrollbar();
    :global {
        thead {
            th {
                font-size: 17px;
                @include font-normal();

                &:global(.MuiTableCell-root) {
                    padding: 10px;
                }

                &:not(:last-child) {
                    border-right: $border-style;
                }

                &:first-child {
                    width: 32%;
                }
            }
        }
        tbody {
            tr {
                td {
                    &:not(:first-child) {
                        text-align: center;
                    }
                    &:first-child {
                        font-size: 15px;
                        @include font-normal();
                    }
                }
            }
            td {
                svg {
                    font-size: 36px;
                }
                &:not(:last-child) {
                    border-right: $border-style;
                }
            }
        }
    }
}

.selectedTypeName {
    &:global(.MuiTableCell-root) {
        color: $color-blue-3;
    }
}

// Do not rename values below. It case of refactoring check InfoPopper styles!
.selectedType {
    &:global(.Notification-Only) {
        @include highlightAccountTypeColumn(2);
    }
    &:global(.Basic) {
        @include highlightAccountTypeColumn(3);
    }
    &:global(.Advanced) {
        @include highlightAccountTypeColumn(4);
    }
    &:global(.Account) {
        @include highlightAccountTypeColumn(5);
    }
}
