.icon-container {
    cursor: pointer;
    padding: 0;

    svg {
        height: 20px;
        width: 13px;
        transform: rotate(180deg);
        margin-right: 12px;
    }
}

.infoContent {
    font-size: 18px;
    margin: 15px 0;
}
