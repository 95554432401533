@import '../../../styles/variables';
@import '../../../styles/mixins';

// todo: apply refactoring and remove EzloMeshbot page styles
.ezlo-meshbots {
    &__and {
        position: relative;
        font-size: 12px;
        line-height: 1.3;
        color: $color-white;
        text-transform: uppercase;
        background: $color-button-blue;
        border-radius: 4px;
        padding: 6px 14px;
        margin: 0;

        &:after {
            content: '';
            display: none;
            position: absolute;
            bottom: -12px;
            left: 7px;
            height: 12px;
            border-color: $color-button-grey-2;
            border-style: solid;
            border-width: 0 0 0 2px;
            box-sizing: border-box;
        }

        &.show {
            &:after {
                display: block;
            }
        }
    }

    &-local__container {
        position: relative;
        margin-top: 40px;
    }

    &__list-triggers {
        padding: 0 20px;

        &.activeConnection {
            & > .connections {
                &:after,
                &:before {
                    display: block;
                }
            }
        }
    }

    &__content {
        font-family: 'Ezlo Sans';
        padding: 40px 25px;

        .connections {
            position: relative;

            &:after,
            &:before {
                display: none;
                content: '';
                position: absolute;
                left: -14px;
                width: 14px;
                height: calc(50% + 12px);
                border-color: $color-button-grey-2;
                border-style: solid;
                box-sizing: border-box;
            }

            &:before {
                top: -10px;
                border-width: 0 0 2px 2px;
            }

            &:after {
                top: 50%;
                border-width: 0 0 0 2px;
            }

            &:last-child {
                &:after {
                    display: none;
                }

                &:before {
                    border-radius: 0 0 0 4px;
                }
            }
        }
    }

    &__header {
        @include flexBox(space-between, center);

        .input-block {
            max-width: 400px;
        }

        &-serial {
            font-size: 20px;
            color: $color-button-grey-1;
            margin: 0;

            span {
                color: $text-color-black;
            }
        }
    }

    &__builder {
        width: 100%;
        background: rgba($color-card, 0.1);
        border-radius: 5px;
        padding: 15px 10px;

        .MuiButton-label {
            line-height: 1.2;
        }
    }

    &-local__sections-wrap-header {
        padding: 10px;
        background-color: #eff2f9;
        border-top: 3px solid #474787;
        border-bottom: 0 solid #474787;
        height: 50px;
        z-index: -1;
        position: relative;

        &::after,
        &::before {
            content: ' ';
            position: absolute;
            height: 10px;
            width: 300px;
            border-top-left-radius: 40px 40px;
            border-top-right-radius: 0;
            border: 3px solid #474787;
            border-right: 0px solid $main-bg-color;
            bottom: 19px;
            border-bottom: 0;
        }

        &::after {
            left: -2px;
            transform: rotate(-8.1deg);
            box-shadow: 10px -30px 0px 20px $main-bg-color;
        }

        &::before {
            right: -2px;
            transform: rotate(8.2deg) scale(-1, 1);
            box-shadow: -23px -26px 0px 20px $main-bg-color;
        }
    }

    &-local__sections-wrap {
        padding: 10px;
        border: 3px solid #474787;
        background-color: #eff2f9;
        border-top: 0 solid #474787;
    }

    &__builder.local__triggers {
        background: #ffe3e3;
        border: 3px solid #ee5a24;
    }

    &__builder.local__actions {
        padding-top: 0;
        background-color: #e3fff8;
        border: 3px solid #079992;
    }

    &__builder.local__actions.false {
        background-color: rgba(144, 160, 175, 0.2);
    }

    &-local__input-block-wrap {
        display: flex;
        align-items: flex-end;
        justify-content: center;
    }

    &-local__img-wrap {
        width: 50px;
        height: 65px;
        transform: rotateZ(-10deg);

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    &-local__input-wrap {
        display: flex;
        justify-content: center;
        width: 190px;
        padding: 0 3px;
        border-radius: 10px 10px 0 0;
        background-color: #474787;
        border-top: 3px solid #474787;
        border-left: 3px solid #474787;
        border-right: 3px solid #474787;
    }

    &-local__trapezoid-triggers,
    &-local__trapezoid-actions {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 190px;
        height: 29px;
        padding: 3px 6px;
        border-radius: 20px 20px 0 0;
        color: #fff;
        font-size: 22px;
        position: relative;

        .left-shape,
        .right-shape {
            width: 20px;
            height: 20px;
            overflow: hidden;
            position: absolute;
            bottom: 0;
            right: -18px;
        }

        .right-shape {
            left: -18px;
            transform: scale(-1, 1);
        }

        .left-shape::before,
        .right-shape::before {
            content: '';
            width: 200%;
            height: 200%;
            position: absolute;
            border-radius: 50%;
        }

        .left-shape::before,
        .right-shape::before {
            bottom: 0;
            left: 0;
            box-shadow: -20px 20px 0 0 #079992;
        }
    }

    &-local__trapezoid-triggers {
        .left-shape::before,
        .right-shape::before {
            bottom: 0;
            left: 0;
            box-shadow: -20px 20px 0 0 #ee5a24;
        }
    }

    &-local__trapezoid-triggers {
        background-color: #ee5a24;
    }

    &-local__trapezoid-actions {
        background-color: #079992;

        &::after,
        &::before {
            background: none;
        }
    }

    &-local__tab .MuiTab-root {
        width: 50%;
        padding: 0;
        font-size: 14px;
        font-weight: 600;
        max-width: none;
        min-height: 10px;

        &:hover {
            background-color: rgba(144, 160, 175, 0.3);
        }
    }

    &-local__tab .MuiButtonBase-root {
        height: 30px;

        &:nth-child(2) {
            background-color: rgba(144, 160, 175, 0.61);
        }
    }

    &-local__tab .MuiTabs-indicator {
        display: none;
    }

    &-local__tab .MuiTabs-flexContainer {
        justify-content: space-between;
        margin-bottom: 15px;
    }

    &__section {
        margin-top: 50px;
    }

    &__section.local__triggers {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 0;
    }

    &__section.local__actions {
        display: flex;
        flex-direction: column;
        margin-top: 10px;
        align-items: center;
    }

    &__buttons.local__buttons-wrap {
        margin-top: 80px;
    }

    &__table {
        &-td {
            @include flexBox(inherit, center);
            margin-bottom: 0;

            span {
                text-transform: capitalize;
                margin-left: 10px;
            }

            svg {
                width: 28px;
            }
        }

        &-buttons {
            .MuiSvgIcon-root {
                width: 20px;
                height: 20px;
            }

            button {
                margin: 0 4px;
            }
        }
    }

    &__buttons {
        margin-top: 20px;

        button {
            font-size: 14px;
            width: 240px;
        }
    }

    &__group {
        background-color: rgba($color-card, 0.1);
        border-radius: 5px;
        border: 1px solid rgba($color-button-grey-1, 0.8);
        padding: 6px;
        margin-bottom: 10px;

        &-header {
            @include flexBox(space-between, flex-end);
            margin-bottom: 10px;
        }

        &-operators {
            @include flexBox();

            &_wrap {
                @include flexBox();
                position: relative;

                &:after {
                    content: '';
                    display: none;
                    position: absolute;
                    bottom: -12px;
                    left: 7px;
                    height: 12px;
                    border-color: $color-button-grey-2;
                    border-style: solid;
                    border-width: 0 0 0 2px;
                    box-sizing: border-box;
                }

                &.show {
                    &:after {
                        display: block;
                    }
                }
            }

            .radio-button {
                &:first-child {
                    .radio-button__label {
                        border-radius: 4px 0 0 4px;

                        .activeGroup & {
                            border-radius: 0;
                        }
                    }

                    & .activeGroup {
                        border-radius: 0;
                    }
                }

                &:last-child {
                    .radio-button__label {
                        border-radius: 0 4px 4px 0;
                    }
                }
            }
        }

        &-block {
            @include flexBox(inherit, flex-end);

            .group-name {
                background: $color-white;
                margin-left: 30px;
            }
        }

        &-buttons {
            @include flexBox(inherit, center);

            button {
                font-size: 10px;
            }

            .MuiButton-outlined {
                margin: 0 6px;
                padding: 4px 10px;
            }

            .MuiSvgIcon-root {
                font-size: 16px;
            }
        }
    }

    .meshbot-cell-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        position: relative;

        &-info {
            color: $color-button-blue;
        }

        svg {
            align-self: center;
            width: 20px;
            min-width: 20px;
        }
    }
}

.trigger-block {
    @include flexBox(space-between, flex-start);
    position: relative;
    background: $color-white;
    border-radius: 5px;
    border: 1px solid transparent;
    padding: 0 6px 3px;
    margin-bottom: 10px;
    min-height: 48px;
    border-left: 4px solid $left-border-inValid;

    & + .trigger-block {
        margin-top: 3px;
    }

    .mesh-bot__builder & {
        &__select {
            width: 170px;
            margin-top: 10px;

            &-label {
                font-size: 14px;
            }

            &_big {
                width: auto;
            }
        }
    }

    &__title {
        font-size: 16px;
        margin: 0 20px 0 0;
        text-transform: capitalize;
    }

    &__date-label {
        margin-bottom: 0;

        span {
            padding-left: 10px;
        }
    }

    &__save {
        margin-top: 20px;
    }

    &__daily {
        @include flexBox(inherit, center);
    }

    &__body {
        @include flexBox(flex-start, flex-start);
        flex-wrap: wrap;
        min-height: 48px;
        padding: 0 0 3px;

        &.notFlex {
            display: block;
            width: 100%;
        }

        &-field {
            @include flexBox(inherit, center);
            padding-left: 20px;
        }
    }

    &__wrapper {
        display: flex;
        align-items: center;
        margin-top: 13px;
    }

    &__field {
        @include flexBox(inherit, center);

        &-group {
            @include flexBox();
            margin-top: 5px;

            &.offset {
                margin-top: 8px;

                span {
                    margin-right: 15px;
                }
            }

            .input-block {
                flex-shrink: 0;
            }
        }

        &-body {
            max-width: 600px;
            width: 100%;
            padding: 0 15px;

            .trigger-block__slider {
                width: 400px;
            }
        }

        .trigger-block__select-label {
            position: absolute;
            transform: translate(0, 1.5px) scale(0.75);
        }
    }

    &__select {
        &.operator {
            width: 80px;
        }

        .small & {
            width: 80px;
        }
    }

    &__footer {
        .notFlex & {
            border-top: 1px solid $divider-color;
            width: 100%;
            padding: 15px 0;
            margin-top: 20px;
        }
    }

    &__week,
    &__days {
        @include flexBox(inherit, flex-start);
    }

    .checkbox-button {
        margin-top: 10px;
        margin-right: 30px;
    }

    .MuiFormLabel-filled {
        font-size: 14px;
    }

    .MuiTypography-body1 {
        font-size: 16px;
    }

    .MuiAccordionDetails-root {
        &.trigger-block__action-detail {
            display: block;
        }
    }

    .MuiSlider-marked {
        margin-bottom: 5px;
    }

    .MuiSlider-root {
        padding: 12px 0;
    }

    .MuiTypography-root {
        text-transform: capitalize;
    }

    .MuiSlider-colorPrimary {
        .MuiSlider-markLabel {
            font-size: 12px;
        }

        .MuiSlider-valueLabel {
            font-size: 12px;
        }

        .MuiSlider-mark {
            display: none;
        }

        .MuiSlider-rail,
        .MuiSlider-track {
            height: 4px;
            border-radius: 3px;
        }
    }
}
.action-block {
    .buttons {
        margin-bottom: 20px;
    }
}
.modal-container {
    background: #f7f7f7;
    width: 100%;
    padding: 20px;
    border-radius: 25px;
    margin: auto;
    margin-left: 22px;
    margin-bottom: 20px;
    flex-direction: column;
    cursor: default;
    .input-container {
        margin: 20px 0;
    }
}
.inputs_fields {
    background-color: #ffffff;
}
.action-container {
    width: 100%;
}
.customs_checkbox-root .MuiSvgIcon-root {
    width: 20px;
    height: 20px;
}

// TODO: do not overwrite top lvl styles in specific /../../style.scss file because it effects all styles and it's hard to find the actual place of overwriting
// TODO: ONLY if necessary move top lvl styles overwriting to general /src/styles/index.scss
.MuiPickersClockNumber-clockNumber {
    &.MuiTypography-body2 {
        font-size: 14px;
    }

    &.MuiTypography-body1 {
        font-size: 14px;
    }
}

// TODO: do not overwrite top lvl styles in specific /../../style.scss file because it effects all styles and it's hard to find the actual place of overwriting
// TODO: ONLY if necessary move top lvl styles overwriting to general /src/styles/index.scss
.MuiDialogActions-root {
    .MuiButton-label {
        font-size: 14px;
    }
}
