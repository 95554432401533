@import '../../../../../styles/variables.scss';
@import '../../../../../styles/mixins.scss';

.scalableTypes {
    background: $color-white;
    margin-right: 30px !important;
    border-radius: 0.25em;
    min-width: 170px !important;
    height: fit-content;
    @media (max-width: $break-d-xxx) {
        margin-bottom: 10px !important;
        margin-right: 19px !important;
    }

    :global {
        .MuiTextField-root {
            border: 1px solid transparent;
            border-radius: 0.25em;
            min-width: 80px;
        }
        .MuiOutlinedInput-root {
            &:focus-visible {
                outline: none;
            }
        }
        .MuiFormLabel-root {
            font-size: 12px;
        }
        fieldset {
            font-size: 18px;
        }
        input {
            padding: 3px 0 5px !important;
        }
        .MuiInputLabel-formControl {
            left: -2px;
        }
        .MuiInputLabel-shrink {
            transform: translate(14px, -6px) scale(0.95) !important;
        }
        .MuiAutocomplete-inputRoot {
            padding: 4px 0px 2px 10px !important;
        }
    }
}
.scalableTypesAutocomplete {
    :global {
        .MuiInputLabel-outlined {
            transform: translate(14px, -7px) scale(1) !important;
        }
        .MuiInputLabel-shrink {
            transform: translate(14px, -6px) scale(0.95) !important;
        }
    }
}
.equalSymbol {
    border-radius: 50%;
    background-color: $color-black;
    font-weight: 800;
    margin: 0.3em 0.5em;
    color: $color-white;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
}
.scalableTypeValueInput {
    background-color: $color-white;
    margin-right: 30px !important;
    height: fit-content;
    @media (max-width: $break-d-l) {
        margin-bottom: 10px !important;
    }
    @media (max-width: $break-d-s) {
        margin-right: 0;
    }
    :global {
        .MuiFormLabel-root {
            font-size: 12px;
        }
        .MuiInputLabel-shrink {
            transform: translate(14px, -5px) scale(0.95) !important;
        }
        fieldset {
            font-size: 18px;
        }
    }
}
.scalableWrapper {
    display: flex;
    @media (max-width: $break-d-xxx) {
        flex-wrap: wrap;
    }
    @media (max-width: $break-d-s) {
        flex-direction: column;
        width: 100%;
        max-width: 500px;
    }
}

:global {
    .MuiAutocomplete-popper {
        ul {
            @include listScrollbar();
        }
    }
}
