@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.createTicketPageWrapper {
    padding: 30px;

    h3 {
    }

    .createTicketForm {
        max-width: 540px;

        .createTicketItem {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 10px;
            margin: 0;
        }

        .createTicketLabel {
            display: inline-block;
            margin: 0 15px 10px 0;
            min-width: 120px;
            font-size: 14px;
        }

        .createTicketInput {
            border-left: none;
            border-right: none;
            border-top: none;
            padding: 0 10px;
            width: 100%;
        }

        .createTicketTextarea {
            padding: 10px;
            border: $border-style;
            width: 100%;
        }

        .buttonCreateWrapper {
            padding: 0 10px;

            .buttonCreate {
                color: $color-white;
                background-color: $button-color;
                border: none;
                font-size: 16px;
                padding: 10px;
                height: 40px;
                width: 100%;
                display: block;
                cursor: pointer;
            }
        }

        .deviceHubSettingsMenuItem {
            @include font-regular();
            font-size: 16px;
        }
    }
}
