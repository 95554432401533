@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';

.grid-table-wrapper {
    position: relative;
    height: 100%;
    width: auto;

    .MuiDataGrid-root {
        width: auto;
        font-family: 'Ezlo Sans';

        &.grid-table {
            border: none;
        }

        .MuiDataGrid-viewport {
            overflow: visible;
        }

        .MuiDataGrid-window {
            @include tableScrollbar();
        }

        .MuiDataGrid-cell--textLeft {
            &:first-child {
                overflow: hidden;
            }

            position: relative;
            overflow: visible;
            text-align: left;

            .script-list &,
            .plugins-list & {
                text-align: left;
            }
        }

        .MuiDataGrid-toolbarContainer {
            height: 39px;
            padding-left: 17px;
        }

        .MuiDataGrid-columnHeader {
            font-size: 16px;
        }

        .MuiDataGrid-cell {
            font-size: 16px;
        }

        .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiDataGrid-iconSeparator {
            display: none;
        }

        .MuiDataGrid-overlay {
            font-family: 'Ezlo Sans';
            font-size: 24px;
        }
    }

    .MuiDataGrid-toolbarContainer.grid-toolbar {
        button {
            font-family: 'Ezlo Sans';
            font-size: 14px;
        }
    }

    .MuiDataGrid-footerContainer {
        .MuiTablePagination-toolbar {
            .MuiTypography-root {
                font-family: 'Ezlo Sans';
                font-size: 14px;
            }

            .MuiTablePagination-selectRoot {
                font-family: 'Ezlo Sans';
                font-size: 14px;

                .MuiSelect-select {
                    font-family: 'Ezlo Sans';
                    font-size: 14px;
                }
            }
        }

        .MuiTablePagination-actions {
            .MuiButtonBase-root {
                padding: 8px;
            }

            .MuiIconButton-label {
                svg {
                    font-size: 24px;
                }
            }
        }
    }
}

// TODO: do not overwrite top lvl styles in specific /../../style.scss file because it effects all styles and it's hard to find the actual place of overwriting
// TODO: ONLY if necessary move top lvl styles overwriting to general /src/styles/index.scss
.MuiGridPanelWrapper-root {
    .MuiFormLabel-root {
        font-family: 'Ezlo Sans';
        font-size: 14px;

        .MuiInput-formControl {
            font-family: 'Ezlo Sans';
            font-size: 14px;
        }

        .MuiInputBase-input {
            font-family: 'Ezlo Sans';
            font-size: 14px;
        }
    }
}

// TODO: do not overwrite top lvl styles in specific /../../style.scss file because it effects all styles and it's hard to find the actual place of overwriting
// TODO: ONLY if necessary move top lvl styles overwriting to general /src/styles/index.scss
.MuiGridPanelContent-root {
    .MuiGridFilterForm-root {
        svg {
            font-size: 18px;
        }

        .MuiSelect-select {
            font-family: 'Ezlo Sans';
            font-size: 14px;
            padding: 4px;
        }

        .MuiFormControl-root {
            .MuiInputBase-root {
                font-family: 'Ezlo Sans';
                font-size: 14px;

                .MuiInputBase-input {
                    padding: 4px;
                }
            }
        }
    }

    span {
        font-family: 'Ezlo Sans';
        font-size: 14px;
    }
}

// TODO: do not overwrite top lvl styles in specific /../../style.scss file because it effects all styles and it's hard to find the actual place of overwriting
// TODO: ONLY if necessary move top lvl styles overwriting to general /src/styles/index.scss
.MuiGridPanelFooter-root {
    .MuiButton-label {
        font-family: 'Ezlo Sans';
        font-size: 14px;
    }

    button {
        display: none;
    }
}

// TODO: do not overwrite top lvl styles in specific /../../style.scss file because it effects all styles and it's hard to find the actual place of overwriting
// TODO: ONLY if necessary move top lvl styles overwriting to general /src/styles/index.scss
.MuiList-root {
    .MuiButtonBase-root {
        font-family: 'Ezlo Sans';
        font-size: 14px;
    }
}
