@import '../../../../../styles/variables.scss';

.dataTypes {
    background: $color-white;
    margin-right: 30px !important;
    border-radius: 0.25em;
    min-width: 150px !important;
    @media (max-width: $break-d-s) {
        margin-bottom: 10px !important;
    }

    :global {
        .MuiSelect-outlined {
            border: 1px solid transparent;
            border-radius: 0.25em;
            padding: 0.7em 0.75em 0.5em 0.75em;
            min-width: 80px;
        }
        .MuiOutlinedInput-root {
            &:focus-visible {
                outline: none;
            }
        }
        .MuiFormLabel-root {
            font-size: 12px;
        }
        .MuiInputLabel-formControl {
            left: -2px;
        }
        .MuiInputLabel-shrink {
            transform: translate(14px, -5px) scale(0.95) !important;
        }
        fieldset {
            font-size: 18px;
        }
    }
}
