@import '../../../../../styles/fonts';
@import '../../../../../styles/mixins';
@import '../../../../../styles/variables';

.pinCodeForm {
    padding: 79px 40px 20px 40px;
    @media (max-width: $break-m-m) {
        padding: 79px 25px 10px;
    }
    :global {
        .MuiFormHelperText-root {
            font-size: 11px;
            @include font-regular();
        }
    }
}

.formContainer {
    max-width: 470px;
    & > div {
        padding-bottom: 20px;
    }
    & > div:last-child {
        padding-bottom: 0;
    }
}

.fieldInfo {
    font-size: 14px;
    @include font-medium();
    color: $text-color-grey;
    display: flex;
    padding-top: 16px;
}

.subTitle {
    font-size: 20px;
    @include font-medium();
    color: $text-color-black;
    & > div {
        padding: 15px 0 0;
        display: flex;
        justify-content: space-between;
    }
}
.selectAllButton {
    font-size: 14px;
    color: $text-color-blue;
    display: flex;
    align-items: center;
    text-transform: capitalize;
    cursor: pointer;
}

.item {
    @include flexBox(space-between, center);
    :global {
        .MuiSwitch-root {
            opacity: 0.7;
        }
    }
}
.iconSquareOfSquares {
    width: 24px;
    height: 24px;
    margin-right: 16px;
}
.label {
    font-size: 18px;
    @include font-medium();
    color: $text-color-black;
    @include flexDirectionAlign(row, center);
    .box {
        @include flexDirectionAlign(column, flex-start);
    }
    span {
        font-size: 13px;
        color: $text-color-dark-grey;
        line-height: 18px;
        text-transform: capitalize;
    }
}

.locksDoorIcon {
    fill: $text-color-black;
    margin-left: -16px;
}

.actionBox {
    @include flexDirectionAlign(row, center);
    svg {
        width: 24px;
        height: 24px;
    }
}

.passwordField {
    svg {
        margin-bottom: 5px;
    }
}
