@import '../../styles/mixins.scss';

.input {
    @include selectStyle();
    min-width: 230px;
}

.field {
    @include flexBox(inherit, center);
    padding-left: 0px;
}

.sliders_box {
    width: 300px;
    margin: 10px;
}

.group {
    @include flexBox(inherit, center);
    margin-top: 20px;
}
