@import '../../../../../styles/variables';
@import '../../../../../styles/mixins';

.modal {
    @include flexBox(center, center);

    .modalContainer {
        @include modalContainer();
        font-size: 20px;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        box-shadow: 0 8px 24px rgba(144, 160, 175, 0.3);
        padding-bottom: 20px;
        border-radius: 24px;
        height: auto;

        .closeBtn {
            display: flex;
            justify-content: flex-start;
            margin: 0;

            svg {
                cursor: pointer;
                width: auto;
                height: auto;
            }
        }

        .imgWrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 200px;

            svg {
                width: 100%;
            }
        }

        .content {
            margin: 8px 0 20px;
            font-size: 16px;

            span {
                cursor: pointer;
                color: $color-select-button-blue;
            }
        }

        button {
            display: block;
            width: 100%;
        }

        .createMore {
            cursor: pointer;
            font-size: 16px;
            font-weight: 400;
            line-height: 23.04px;
            color: $color-select-button-blue;
            width: 100%;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 16px 0 0 0;
        }

        h3 {
            font-size: 32px;
            font-weight: 600;
            line-height: 38.4px;
            margin: 0;
        }
    }
}
