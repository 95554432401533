@import '../../../../../../../styles/variables';
@import '../../../../../../../styles/mixins';

.requestFields__select {
    display: inline-flex;
    flex-direction: column;
    min-width: 250px;
}
.requestFields {
    .requestFields__label {
        font-size: 14px;
        color: $color-black;
    }
    .requestFields__value {
        font-size: 14px;
        line-height: 19px;
        border-bottom: 2px solid rgba(144, 160, 175, 0.5);
        padding: 9px 0 8px 0;
        margin-bottom: 0;
    }
    .requestFields__headersItem {
        @include flexBox();
        margin-bottom: 15px;
    }
    //.requestFields__actionField {
    //    margin-right: 20px;
    //}
    .requestFields__headersItem_input {
        width: 100%;
    }
    .requestFields__buttonDelete {
        svg {
            width: 18px;
            height: 18px;
        }
    }
    .requestFields__buttonAdd {
        font-size: 11px;
    }
    :global {
        .MuiInputBase-root {
            input {
                height: inherit;
                line-height: 18px;
                padding: 10px 0;
            }
        }
        .MuiInputBase-inputMultiline {
            padding: inherit;
        }
        .MuiInputBase-root {
            &.Mui-disabled {
                background: rgba($color-button-grey, 0.4);
            }
        }
        .MuiFormLabel-root {
            &.Mui-disabled {
                color: $color-button-grey-1;
            }
        }
        .MuiInputLabel-outlined {
            transform: translate(14px, 14px) scale(1);
        }
        .MuiInputLabel-outlined.MuiInputLabel-shrink {
            transform: translate(14px, -6px) scale(0.75);
        }
        .MuiOutlinedInput-notchedOutline {
            legend {
                font-size: 10px;
            }
        }
    }
}
