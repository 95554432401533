@import '../../../../../styles/variables.scss';
.wrapper {
    .info_button {
        position: absolute;
        top: -15px;
        right: -7px;
        padding: 6px;
        background-color: transparent;
        z-index: 1;

        :global {
            .MuiSvgIcon-root {
                font-size: 11px;
                z-index: 1;
                color: $info-btn-color;
            }
        }
    }
    .hide {
        opacity: 0;
        transition: opacity 2s linear;
    }

    .buttonTabTrue {
        width: 24px;
        top: 0;
        left: 0;
    }

    .buttonTabFalse {
        width: 24px;
        top: 0;
        right: 0;
    }
}

.box {
    white-space: pre-line;
}
