@import '../../../../../styles/variables';
@import '../../../../../styles/mixins';

.templateCapability {
    @include flexBox(space-between, flex-start);
    background: $color-ocean-4;
    border-radius: 15px;
    padding: 20px;
    margin-bottom: 25px;
    .templateCapability__group {
        @include flexBox();
    }
    .templateCapability__select {
        @include flex(0, 1, 220px);
    }
    .templateCapability__label {
        font-size: 14px;
        color: $color-black;
    }
    .templateCapability__actions {
        @include flex(1, 1, 100%);
        margin: 0 40px;
    }
    .templateCapability__buttonDelete {
        svg {
            width: 18px;
            height: 18px;
        }
    }
    .templateCapability__actions_item {
        display: grid;
        grid-template-areas:
            'name data contentType method'
            'name headers headers .'
            'name uri uri .';
        grid-template-columns: 140px 1fr 240px 160px;
        border-bottom: 1px solid $color-black;
        grid-column-gap: 30px;
        grid-row-gap: 30px;
        padding-bottom: 25px;
        margin-bottom: 25px;
    }
    .templateCapability__actions_name {
        grid-area: name;
    }
    .templateCapability__actions_method {
        grid-area: method;
    }
    .templateCapability__actions_uri {
        grid-area: uri;
    }
    .templateCapability__actions_headers {
        grid-area: headers;
    }
    .templateCapability__actions_data {
        grid-area: data;
    }
    .templateCapability__actions_contentType {
        grid-area: contentType;
    }
    :global {
        .MuiSelect-root.MuiSelect-select {
            min-height: 18px;
            font-size: 14px;
            line-height: 18px;
            padding: 10px 22px 10px 0;
        }
        .MuiFormHelperText-root {
            display: none;
        }
        .MuiInputBase-root {
            margin-top: 0;
        }
        .MuiInputLabel-formControl {
            transform: translate(0, 14px) scale(1);
        }
        .MuiInputLabel-shrink {
            transform: translate(0, -6px) scale(0.75);
        }
        .MuiFormControl-root {
            .MuiFormLabel-root.Mui-focused {
                font-size: 14px;
            }
        }
    }
}
