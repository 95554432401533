@import './src/styles/variables';

.expression-add-form {
    &__actions {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: 10px;
    }
    &__cancel,
    &__create {
        width: 150px;
        padding: 0.5em;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 0.25em;
        &:hover {
            cursor: pointer;
        }
    }
    &__cancel {
        color: $color-button-blue;
        background-color: $color-white;
        border: 1px solid $color-button-blue;
        margin-right: 1em;
    }
    &__create {
        width: 150px;
        color: $color-white;
        background-color: $color-button-blue;
        border: none;
        &:disabled {
            opacity: 0.5;
        }
        &:hover {
            background-color: $color-button-blue-hover;
        }
    }
}

.expression-item {
    &__device-reference-wrapper {
        display: flex;
        align-items: flex-end;
    }

    &__code-block {
        width: 100%;
        max-width: 600px;
        textarea {
            min-height: 45px;
            max-height: 900px;
        }
    }

    &__select-control {
        .MuiInputLabel-formControl {
            font-size: 16px;
            color: $color-black;
            padding-left: 10px;
            z-index: 10;
        }
        .MuiSelect-select {
            padding-left: 10px;
        }
    }

    &__select {
        background-color: $color-white;
        margin-right: 10px;
        border-radius: 0.25em;
        border: 1px solid $secondary-text-color;
        min-width: 150px;
        &.MuiAutocomplete-root {
            margin-top: 16px;
            .MuiInputLabel-formControl {
                &.MuiInputLabel-shrink {
                    transform: translate(0, -16px) scale(0.75);
                }
                padding-top: 0;
                transform: translate(0, 7px) scale(1);
            }
        }
        &.Mui-disabled {
            opacity: 0.5;
        }
        .MuiFormControl-root {
            .MuiInputBase-root.MuiAutocomplete-inputRoot {
                margin-top: 0;
                .MuiAutocomplete-input:first-child {
                    width: auto;
                    font-size: 16px;
                    padding: 5px 10px;
                    margin-top: 0;
                }
            }
        }
    }

    &__add-reference {
        border: none;
        background-color: $color-button-blue;
        color: $color-white;
        border-radius: 0.25em;
        font-size: 14px;
        padding: 5px 10px;
        &:hover {
            cursor: pointer;
            background-color: $color-button-blue-hover;
        }
        &:disabled {
            opacity: 0.5;
        }
    }
}
