@import "../../../../../styles/variables";
@import "../../../../../styles/mixins";

.modal-confirmation {
    width: 360px;
    margin-top: 14px;
    &__title {
        font-size: 18px;
        text-align: center;
    }
    &__buttons {
        @include flexBox(space-between);
        button {
            width: 48%;
            font-size: 14px;
        }
    }
}
