@import 'src/styles/variables';

.fullScreenDialogWrapper {
    min-width: 400px;

    h2 {
        background: $color-primary-theme;
        color: $color-white;
        padding: 10px 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 0;

        span {
            display: flex;
            align-items: center;
            margin: 0 10px 0 0;

            svg {
                width: 20px;
                height: 20px;
                cursor: pointer;
            }
        }

        span:first-child {
            cursor: pointer;
            position: absolute;
            left: 15px;
            top: 12px;
        }
    }

    :global {
        label + .MuiInput-formControl {
            margin-top: 0;
        }
    }

    .modalPluginConfigureList {
        padding: 0 20px 20px 20px;
        overflow-y: auto;
    }

    .MuiDialogContent-dividers + div {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 15px 0 15px 0;
    }

    .MuiDialogContent-dividers {
        padding: 15px;
        overflow-x: hidden;
    }
}

.fullScreenIntegrationWrapper {
    min-width: 423px;
    border-radius: 20px !important;
    padding: 8px 8px 40px 8px;

    h2 {
        margin-bottom: 20px;
    }

    .closeIconWrapper {
        display: flex;
        align-items: center;
        padding: 10px;

        svg {
            cursor: pointer;
        }
    }

    :global {
        .MuiDialogContent-dividers {
            border-top: none;
            border-bottom: none;
            padding: 12px 40px 0;
        }

        .MuiButton-root {
            width: 100%;
        }
    }
}
