@import '../../../styles/variables.scss';
@import '../../../styles/fonts.scss';

.virtualContainerWrapper {
    display: flex;
    flex-direction: column;
    position: relative;
    height: calc(100vh - 30px);
    padding: 50px 43px;
    @include font-regular();
    @media (max-width: $break-t-m) {
        padding-top: 80px;
    }
}
.virtualContainerTitle {
    margin-right: 20px;
    font-size: 24px;
    line-height: 29px;
    @include font-semibold();
}
.virtualContainerHeader {
    justify-content: space-between;
}
:global {
    .MuiButtonBase-root {
        &.MuiButton-outlinedPrimary {
            border: $border-style;
            color: $color-white;
            background-color: $color-primary-button;
            padding: 7px 12px;
            .MuiSvgIcon-root {
                margin-right: 7px;
                font-size: 40px;
            }
        }
    }
}
