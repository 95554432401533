@import '../../../../../styles/fonts.scss';
@import '../../../../../styles/variables.scss';

.notificationCapabilityDropdownWrapper {
    margin: 0 0 10px;
    display: flex;
    align-items: center;
}

.notificationCapabilityDropdownSelect {
    display: inline-block;
    min-width: 280px;
    width: 50%;
}

:global {
    .MuiPickersBasePicker-pickerView {
        .MuiPickersCalendarHeader-switchHeader p {
            font-size: 14px;
        }

        .MuiPickersCalendarHeader-daysHeader span {
            font-size: 12px;
        }

        .MuiPickersCalendar-transitionContainer .MuiPickersCalendar-week p {
            font-size: 14px;
        }
    }

    .MuiMenu-paper {
        &:global(.MuiPopover-paper) {
            max-height: 400px;
        }
    }
}
