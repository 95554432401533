@import '../../../../styles/variables';
@import '../../../../styles/fonts';
@import '../../../../styles/mixins';

.groups-section {
    padding-top: 40px;
    @media (max-width: $break-m-s) {
        padding-top: 65px;
    }

    .MuiAccordion-root:before {
        background: none;
    }

    .MuiAccordion-root.Mui-expanded {
        margin: 24px 0;
    }

    .MuiAccordion-root.Mui-expanded:first-child {
        margin: 24px 0;
    }

    .accordion {
        padding: 0;
        box-shadow: none;
        border: 0;
        margin-top: 24px;

        .expand-icon {
            transform: rotate(180deg);
        }

        .MuiAccordionSummary-root {
            padding: 0 24px;
            height: 56px !important;
            min-height: 56px !important;

            .MuiAccordionSummary-content {
                margin: 0 !important;
            }
        }

        &__header {
            font-size: 24px;
            line-height: 1.2;
            @include font-medium();
            flex-grow: 1;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .header-actions {
                display: flex;
                align-items: center;
                .disabled {
                    pointer-events: none;
                    svg {
                        fill: rgba($text-color-grey, 0.3);
                    }
                }
            }
        }

        &__details {
            padding: 0;
        }
    }
}
