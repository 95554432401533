@import '../../../../../../styles/variables.scss';

.wrapper {
    display: flex;
    align-items: center;
    gap: 5px;
    flex-wrap: wrap;
    width: $deleteBtnWidth;
    padding-left: 5px;
    box-sizing: border-box;

    button {
        svg {
            width: 25px;
            height: 25px;
            fill: $meshbot-delete-icon;
        }
    }
}

.wrapper.actionSectionButtonsWrapper {
    width: 50%;
    padding-left: 0;
    justify-content: end;
}

.activeButton {
    :global {
        .MuiButton-contained {
            background-color: $trigger_exception_button;
            color: $color-white;
            &:hover {
                background-color: darken($trigger_exception_button, 10%);
            }
        }
    }
}
