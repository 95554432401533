@import 'src/styles/variables';
@import 'src/styles/mixins';

.duplicateNotificationWrapper {
    width: 100%;
    text-align: center;

    .title {
        font-size: 20px;
        display: inline-block;
        margin: 0 0 30px 0;
    }

    .buttonContainer {
        max-width: 200px;
        margin: 0 auto;
        font-size: 16px;
        display: flex;

        button {
            cursor: pointer;
            padding: 5px 10px;
            color: $color-blue-3;
            border: 1px solid $color-blue-3;
            margin: 0 10px;
            background-color: $color-white;
            font-size: 16px;

            &.duplicate {
                background-color: $color-blue-3;
                color: $color-white;
            }
        }
    }
}
