@import '../../../../../styles/fonts';

.phoneInput {
    :global {
        .PhoneInputCountryIcon {
            width: 40px;
            height: auto;
            margin-right: 5px;
        }
        .PhoneInputCountrySelect {
            font-size: 16px;
            @include font-medium();
            border: none;
        }
    }
}
