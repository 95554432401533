@import '../../../../styles/variables.scss';

.ezloPolicies {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: 'Ezlo Sans';

    *::-webkit-scrollbar-corner {
        background: rgba(0, 0, 0, 0);
    }

    * {
        scrollbar-width: thin;
        scrollbar-color: none #f6f6f6;
    }

    *::-webkit-scrollbar {
        width: 5px;
    }

    *::-webkit-scrollbar-track {
        background: rgba(0, 0, 0, 0);
    }

    *::-webkit-scrollbar-thumb {
        background-color: #c5c5c5;
        border-radius: 20px;
        width: 3px;
        border: 3px solid #c5c5c5;
    }
}

.main {
    width: 70vw;
}

.checkboxControl {
    transform: scale(1.5);
    margin-right: 8px;
}

.label {
    font-size: 15px;
    color: black;
    font-weight: normal;
    font-family: 'Ezlo Sans';
}
.description {
    color: gray;
    font-size: 14px;
}

.consentTitle {
    font-size: 20px;
    font-weight: bold;
}

.logo {
    position: absolute;
    top: 31px;
    left: 32px;
    display: flex;
    width: 180px;
}

.title {
    font-size: 30px;
    color: black;
    font-weight: bold;
    font-family: 'Ezlo Sans';
    margin-bottom: 30px;
    text-align: left;
}

.text {
    font-size: 15px;
    color: black;
    line-height: 20px;
    max-height: 35vh;
    overflow-y: scroll;
    padding: 7px;
    border-radius: 5px;
}
.innermain {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
}
.formControl {
    font-size: 20px;
}

.formControlLabelRoot {
    align-items: flex-start !important;
}

.formControlLabel {
    padding-top: 4.5px;
}

.button_wrapper {
    margin-top: 18px;
    display: flex;
    justify-content: center;

    .button {
        font-size: 16px;
        width: 250px;
        height: 56px;
    }
    :global {
        .MuiButton-contained {
            &.Mui-disabled {
                background-color: rgba(0, 0, 0, 0.12);
            }
        }
    }
}
.MuiFormControlLabel-root {
    display: inline-block;
}
.footer {
    margin-top: 15px;
}
