@import 'src/styles/variables';

.menu-ui {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    &__section-preview {
        border: 1px solid rgb(236, 236, 236);
        padding: 20px;
        width: 300px;
    }

    &__list {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    &__items {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-content: center;
        align-items: center;
    }

    &__subitems {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-content: center;
        align-items: center;
        margin-left: 10px;
    }

    &__nestedsubitems {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-content: center;
        align-items: center;
        margin-left: 25px;
    }

    &__item {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-content: center;
        align-items: center;
    }

    &__icons {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }
    &__textfield {
        margin-right: 14px;
    }

    &__subItems {
        display: flex;
    }

    &__dropdown {
        margin-bottom: 4px;
        margin-top: 5px;
    }

    &__list-items {
        font-size: 1.6rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        align-content: center;
        height: 50px;

        .meshbot-title {
            color: black;
            align-items: center;
            display: flex;
            flex-wrap: wrap;
        }

        .meshbot-title > div {
            margin-right: 10px;
        }

        .icon {
            margin: 0 12px;
            min-width: 30px;
        }
    }

    &__lists {
        display: flex;
        flex-direction: column;
    }

    &__list-item {
        margin-left: 15px;
        display: flex;
        flex-direction: column;
    }

    &__list-subitems {
        margin-left: 70px;
        display: flex;
        flex-direction: column;
    }

    &__list-nested-subitems {
        margin-left: 20px;
        display: flex;
        flex-direction: column;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        padding-left: 22px;
        align-items: center;
    }

    &__logo-container {
        width: 180px;
        height: 100px;
    }

    &__logo {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    &__version-input {
        width: 110px;
    }

    &__checkbox {
        * {
            font-size: 12px;
        }

        svg {
            fill: $text-color-blue !important;
            width: 20px;
            height: 20px;
        }
    }

    .logo {
        padding-left: $navigation-logo-margin;
        margin-bottom: 24px;
        display: flex;

        &__left-icon {
            width: $navigation-logo-width;
            height: $navigation-logo-height;
            margin-left: 35px;
        }

        &__right-icon {
            margin-left: 57px;
            width: $navigation-logo-width;
            height: $navigation-logo-height;
        }
    }

    svg {
        fill: $text-color-dark-grey;
    }
}

.show-label {
    font-size: 14px;
    font-family: 'Ezlo Sans';
    color: #7f7f7f;
}

.right-menu-logo {
    width: $navigation-logo-width;
    height: $navigation-logo-height;
}

.section-top {
    color: $color-primary-theme;
    font-weight: 900;
    font-size: 22px;
    text-align: center;
}

.section-edit {
    margin-top: 48px;
    margin-right: 240px;
    border: 1px solid rgb(236, 236, 236);
    padding: 20px;
    width: 600px;
}
