@import '../../../../../styles/variables.scss';
@import '../../../../../styles/mixins.scss';

.templateTypeItemWrapper {
    display: flex;
    flex-direction: column;
    border: $border-style;
    border-radius: 4px;
    padding: 20px;

    &:not(:last-child) {
        margin-right: 17px;
    }

    .templateTypeItemIcon {
        svg {
            display: block;
            margin: 0 auto 20px;
            width: 40px;
            height: 40px;
            fill: $primary-text-color;
        }
    }

    .templateTypeItemTitle {
        @include font-semibold();

        color: $text-color-black;
    }

    .templateTypeItemCreateBtn {
        &:global(.MuiButton-root) {
            position: relative;
        }
    }
}
