@import 'src/styles/variables';

.selectDeviceCapability {
    display: inline-block;
    width: 225px;
    .label {
        background-color: $color-white;
        padding-right: 3px;
    }

    .select {
        width: auto;
        margin-top: 8px;
        margin-right: 25px;
        min-width: 200px;
    }

    .selectInput {
        position: relative;
        > div {
            padding: 12px 14px 5px;
        }

        > input {
            padding: 12px 14px 5px;
        }
    }
}

.infoText {
    text-align: center;
    color: $text-color-dark-grey;
}
