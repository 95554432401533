.container {
    margin-bottom: 0;
    .marker {
        color: red;
    }
    .text {
        margin-right: 10px;
    }
    .iconButton {
        width: 40px;
        height: 40px;
        margin-right: 10px;
    }
}
