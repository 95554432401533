@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.wifiGenerator {
    padding: 40px;
    position: relative;
    height: calc(100vh - 30px);

    .linearProgressBoxPlugins {
        position: absolute;
        top: -30px;
        left: 0;
        right: 0;
        z-index: 9999;
    }

    .btnBack {
        cursor: pointer;
    }

    @media (max-width: $break-d-xx) {
        padding: 60px 40px;
    }

    .wifiGenerator__spinner {
        @include flexBox(center, center);
        width: 100%;
        min-height: 300px;
    }

    .wifiGenerator__spinner_installed {
        @include flexBox(center, center);
        min-height: calc(100vh - 80px);
    }

    .wifiGenerator__spinner_component {
        width: 64px;
        height: 64px;
    }

    .wifiGenerator__notActive {
        @include flexBox(center, center);
        height: calc(100vh - 195px);
        min-height: 300px;
        padding: 30px 30px 60px;

        h3 {
            width: 650px;
            font-size: 24px;
            line-height: 1.5;
        }

        button {
            font-size: 14px;
            margin-top: 20px;
        }
    }

    .wifiGenerator__notActive_body {
        text-align: center;
    }

    :global {
        .plugins-list {
            .MuiDataGrid-cell,
            .MuiDataGrid-columnHeader {
                &:focus,
                &:focus-within {
                    outline: none;
                }
            }
        }
    }
}
