@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';

.settingsIntegrations {
    display: flex;
    flex-direction: column;
    position: relative;
    @include font-regular();
}

.headerContainer {
    z-index: 1;
    position: absolute;
    top: 16px;
    height: $header-height;
    display: flex;
    align-items: flex-end;
    width: calc(100% - 40px);
    padding: 0 40px;
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-top: 19px;
}

.headerTitle {
    margin: 0 0 7px 0;
    font-size: $h1-font-size;
    line-height: 39px;
    @include font-regular();
    @media (max-width: $break-t-l) {
        display: flex;
        justify-content: start;
        flex: 1 1 auto;
    }
    @media (max-width: $break-m-s) {
        justify-content: center;
        margin-left: -20px;
        font-size: 23px;
    }
}

.newEnrollmentBtnBox {
    display: flex;
    justify-content: end;
    flex: 1 1 auto;
    @media (max-width: $break-d-xx) {
        margin: 0 31px 0 20px;
    }
    @media (max-width: $break-t-l) {
        margin: 0 0 0 31px;
        justify-content: start;
    }
    @media (max-width: $break-m-s) {
        margin: 0;
        justify-content: center;
    }
}

.newEnrollmentBtn {
    height: 5.6rem;
    @media (max-width: $break-t-m) {
        height: 4.6rem;
    }
    svg {
        height: 24px;
        width: 24px;
        margin-right: 10px;
        @media (max-width: $break-t-m) {
            height: 20px;
        }
    }
    @media (max-width: $break-m-s) {
        margin-bottom: 10px;
    }
}

.progressBar {
    position: absolute;
    top: -30px;
    height: 4px;
    width: 100%;
    z-index: 2;
}

.sectionContent {
    padding: $header-height 40px 24px;
    position: relative;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 30px);
    overflow: auto;
    @include inputScrollbar;
    @media (max-width: $break-t-l) {
        padding: 100px 40px 24px;
    }
    @media (max-width: $break-m-s) {
        padding: 100px 20px 20px;
    }
}

.iconArrowBack {
    cursor: pointer;
    padding: 0;

    svg {
        height: 20px;
        width: 13px;
        transform: rotate(180deg);
        margin-right: 12px;
    }

    &:hover {
        background-color: $color-white;
        box-shadow: 0 8px 16px rgba($text-color-grey, 0.3);
    }
}

.searchTwoToneIcon {
    width: 24px !important;
    height: 24px !important;
    color: $text-color-dark-grey;
}

.closeIcon {
    width: 16px;
    height: 16px;
}

.inputBlock {
    max-width: 500px;
    @media (max-width: $break-t-l) {
        max-width: 100%;
    }

    :global {
        .MuiInputBase-root {
            height: 56px;
            font-weight: bold;
        }
    }
}
