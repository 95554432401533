@import '../../../../../styles/variables';
@import '../../../../../styles/mixins';

.scriptHeader {
    @include flexBox(space-between);
    padding-top: 50px;

    &_group {
        @include flexBox();
    }

    &__buttons {
        .MuiButton-root {
            margin-right: 2px;
            padding: 8px 30px;
            font-size: 14px;

            &.active {
                background: $color-button-blue-hover;
            }
        }
    }

    .title {
        font-size: 26px;
        font-weight: bold;
    }

    .titleEdit {
        flex: 0.7;
        font-size: 26px;
        font-weight: bold;
    }

    .MuiButton-root {
        font-size: 14px;
    }
}
