@import '../../../../../styles/variables.scss';
@import '../../../../../styles/mixins.scss';
@import '../../../../../styles/fonts.scss';

.codeArea {
    &:global(.MuiFormControl-root) {
        min-width: 250px;
        margin-right: 15px;
        margin-bottom: 5px;
    }

    :global {
        textarea {
            @include inputScrollbar();
            @include font-normal();

            color: $text-color-black;
            font-size: 14px;
            padding: 11px 5px;
            border-radius: 4px;
            border-color: $border-color-meshbot;

            &:focus-visible {
                outline-color: $primary-text-color;
            }
        }
    }

    &:global(.invalidValue) {
        :global {
            textarea {
                border-color: $left-border-inValid;
                &:focus-visible {
                    outline-color: $left-border-inValid;
                }
            }
        }
    }
}

.errorText {
    color: $left-border-inValid;
}
