@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.input_wrapper {
    @include listScrollbar();
    overflow-y: auto;
    padding: 0 20px;
    @media screen and (max-height: 1200px) {
        max-height: 300px;
    }
}

.large_wrapper {
    min-width: 477px;
}

.input_block {
    position: relative;
    background-color: $color-white;

    :global {
        .MuiInputBase-root {
            position: sticky;
            background-color: #fff;
            top: 0;
            z-index: 9999;

            input {
                padding: 10px 35px 10px 0;

                &::placeholder {
                    color: $text-color-dark-grey;
                }
            }
        }
        .MuiPaper-elevation1 {
            box-shadow: none;
        }
    }
}

.input_container {
    width: 100%;
}

.icon {
    position: sticky;
    bottom: calc(100% - 35px);
    left: 0;
    width: 30px;
    float: right;
    z-index: 9999;
    svg {
        width: 20px;
        height: 20px;
    }
}
