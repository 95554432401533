@import '../../../../styles/variables.scss';

.policiesLinks {
    flex-shrink: 0;
    padding-left: 40px;
    font-size: 16px;
}

.policiesLinks a {
    margin: 5px;
}

.policiesLinks a:hover {
    text-decoration: none;
}

.link {
    border-bottom: 2px solid $text-color-blue;
    padding-bottom: 5px;
}
