@import "../../styles/fonts";

.confirm-custom-dialog__wrapper{
  .confirm-custom-dialog{
    &__title {
      font-size: 24px;
      margin-bottom: 8px;
      @include font-bold();
    }
    &__text{
      font-size: 18px;
      margin-bottom: 8px;
      font-family: 'Ezlo Sans';
    }
    &__actions-box{
      display: flex;
      flex-direction: row;
      padding: 12px 24px 24px;
    }
    &__button{
      font-size: 16px;
      width: 100%;
      height: 48px;
      font-family: 'Ezlo Sans';
      flex-grow: 1;
      text-transform: none;
    }
  }
}