@import "../../../../../styles/variables";

.virtual-items-error-plug {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: 'Ezlo Sans';
    height: 100vh;
    font-size: 16px;

    .error-icon {
        padding-bottom: 20px;

        .MuiSvgIcon-root {
            font-size: 75px;
            color: $text-color-warning;
        }
    }

    .reload-icon {
        cursor: pointer;
        padding-top: 20px;

        .MuiSvgIcon-root {
            font-size: 35px;
            color: $text-color-blue;
        }
    }
}
