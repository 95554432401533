@import '../../../../../styles/variables';

.linearProgressBox {
    width: 100%;
    position: absolute;
    top: 0;
    right: 0;
}

.linearProgressBoxPlugins {
    width: 100%;
    position: absolute;
    top: -30px;
    right: 0;
}

.linearProgress {
    z-index: 1200;
}

.title {
    font-size: 2.4rem;
    font-weight: normal;
    color: $text-color-black;
}

.arrowBackIcon {
    margin-right: 10px;
    cursor: pointer;
}

.titleBox {
    display: flex;
    align-items: center;
    flex: 1 1 auto;
    min-height: 5.6rem;

    :global {
        .MuiSvgIcon-root {
            fill: $text-color-black;
        }
    }
}
