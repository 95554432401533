@import 'src/styles/mixins';
@import 'src/styles/variables';

.integrationInfoWrapper {
    padding: 0 30px 30px 30px;
    @media screen and (max-width: 1200px) {
        padding: 60px 30px 30px 30px;
    }

    .goBack {
        padding: 0;
        color: $color-black;
        display: flex;
        align-items: center;

        svg {
            cursor: pointer;
        }

        .integrationName {
            padding: 0 0 0 28px;
            display: inline-block;
            font-size: 24px;
            font-weight: 600;
            line-height: 28.8px;
        }
    }

    button {
        cursor: pointer;
    }

    .iconWrapper {
        display: flex;
        align-items: center;
        margin: 40px 0 5px 0;

        img {
            width: 50px;
            height: 50px;
            margin: 0 10px 0 0;
        }

        .integrationName {
            font-size: 18px;
        }
    }

    .integrationDescription {
        color: $text-color-grey-basic;
        font-size: 16px;
        display: flex;
        margin: 30px 0 0 0;

        svg path {
            fill: $text-color-description;
        }

        span {
            font-size: 16px;
            font-weight: 400;
            line-height: 23.04px;
            display: block;
            margin: 0 0 0 22px;
        }
    }

    .titleIntegrations {
        font-size: 18px;
        line-height: 22px;
        font-weight: 500;
        color: $text-color-basic-black;
        margin: 80px 0 40px;
    }

    .enrollmentListItem {
        border: $border-style;
        border-radius: 16px;
        width: 292px;
        min-height: 94px;
        padding: 8px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin: 0 15px 15px 0;

        .name {
            font-size: 16px;
            color: $text-color-basic-black;
            line-height: 20px;
            font-weight: 400;
        }

        .info {
            font-size: 13px;
            color: $text-color-grey-basic;
            font-weight: 500;
            line-height: 15.6px;
        }
    }
}

.integrationEnrollmentsListWrapper {
    margin: 20px 50px 40px;
    display: flex;
    flex-wrap: wrap;

    li {
        margin: 0 30px 30px 0;
    }

    .infoWrapper {
        display: flex;
        align-items: flex-start;
        border: $border-style-customization-header;
        border-radius: 16px;
        width: 380px;
        height: 104px;
        padding: 12px 0 12px 12px;

        img,
        svg {
            width: 40px;
            height: 40px;
            display: block;
            margin: 0 10px 0 0;
        }

        .col {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;

            .settingsTextButtonBox p {
                margin: 15px 0 0 0;
                font-size: 13px;
                font-weight: 500;
                cursor: pointer;
                color: $color-blue-4;
            }
        }

        .row {
            display: flex;
            flex-direction: column;

            span {
                display: block;
                @include ellipsis();
                width: 226px;
            }
        }

        .name {
            font-size: 16px;
            color: $text-color-black;
        }

        .info {
            font-size: 16px;
            color: $text-color-grey;
        }

        .settingsTextButtonBox p {
            margin: 15px 0 0 0;
            font-size: 13px;
            font-weight: 500;
            cursor: pointer;
            color: $color-blue-4;
        }
    }
}
