@import '../../../../../styles/variables';
@import '../../../../../styles/fonts';
@import '../../../../../styles/mixins';

.headerTitleBox {
    min-height: 5.6rem;
    @include flexBox(inherit, center);
}

.accountContainer {
    display: flex;
    flex-direction: column;
    min-height: 92.5vh;

    .accountContent {
        flex: 1;
        padding: 40px;
    }
    .policiesLinks {
        margin-bottom: 10px;
    }
}
