@import "../../../../styles/variables";
@import "../../../../styles/fonts";
@import "../../../../styles/mixins";

.update-integration-dialog {

  .dialog__title {
    padding: 0;

    .close {
      margin: 8px 0 0 8px;

      svg {
        height: 20px!important;
        width: 23px!important;
      }
    }

    .title {
      border-bottom: 1px solid lightgrey;
      @include font-medium();
      height: 56px;
      display: flex;
      margin: 0;
      padding: 0 24px;
      align-items: center;
      font-size: 24px;
      line-height: 29px;
    }
  }

  .dialog__content {
    padding-top: 32px;
    width: 400px;
    @include listScrollbar();
    .MuiListItem-root {
      height: 56px;

      .MuiListItemIcon-root {
        height: 56px;
        width: 56px;
        span {
          width: 100%;
          height: 100%;
          svg {
            width: 24px;
            height: 24px;
          }
        }
      }

      .MuiTypography-root {
        @include font-medium();
        font-size: 18px;
      }
    }

    .text-wrapper {
      width: 100%;
      //padding: 0 24px;

      .text-field {
        width: 100%;
        height: 72px;
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 16px;
        @include font-regular();

        label {
          font-size: 13px;
          line-height: 16px;
        }

        input {
          @include font-regular();
          padding-top: 4px;
          padding-bottom: 12px;
          font-size: 18px;
          line-height: 22px;
        }

        .MuiFormLabel-root {
          @include font-regular();
          color: $text-color-dark-grey;
          font-size: 18px;
          line-height: 22px;
          transform: translate(0, 20px) scale(1)
        }

        .MuiInputLabel-shrink {
          transform: translate(0, 0) scale(0.75) !important;
        }

        .MuiInput-underline:before {
          border-bottom: 2px solid rgba($text-color-grey, 0.5);
        }

        .MuiInput-underline:after {
          border-bottom: 2px solid $text-color-black;
        }

        .Mui-error:after {
          border-bottom-color: $text-color-warning;
        }

        .MuiFormHelperText-root {
          @include font-regular();
          margin-top: 1px;
          font-size: 13px;
          line-height: 16px;
        }

        .MuiInputAdornment-root {
          padding-bottom: 16px;
          margin-right: -15px;
        }

        .MuiIconButton-root {
          svg {
            height: 24px;
            width: 24px;
          }
        }

        .search-icon {
          height: 56px;
          width: 56px;
        }
      }

      .disabled {
        input {
          background: rgba(0,0,0,0.05);
        }
      }
    }

    .autocomplete {

    }

  }

  //.icon-container {
    svg.info {
      fill: $text-color-warning;
      height: 26px;
      width: 26px;
      margin-right: 15px;
    }
  //}
}