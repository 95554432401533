.uploadImagesDialog {
    :global {
        .MuiTypography-root {
            font-size: 20px;
        }
        .MuiDialogActions-root {
            padding: 16px 24px;
        }
        .MuiDialogActions-root .MuiButton-label {
            font-size: 16px;
        }
    }
}
.infoBox {
    display: flex;
    align-items: center;
    font-size: 16px;
    padding: 0 0 30px;
    svg {
        width: 20px;
        height: auto;
        margin-right: 7px;
        fill: #575d70;
    }
}

.saveButtonBox {
    display: flex;
    justify-content: right;
    button {
        max-width: 120px;
    }
}
