@import '../../../../../styles/variables';
@import '../../../../../styles/mixins';

.builder_header {
    @include flexBox(flex-end, center);
    margin: 5px 0 10px;
}

.active {
    @include flexBox(space-between, flex-end);
}

.buttons {
    button {
        font-size: 12px;
    }
    :global {
        .Mui-disabled {
            background: $color-button-grey;
        }
    }
}
