.checkbox {
    position: relative;
    margin: 15px 20px 0 0;
    :global {
        .MuiFormControlLabel-root {
            margin-right: 5px;
        }
        .MuiTypography-body1 {
            font-size: 14px;
            font-family: 'Ezlo Sans';
            font-weight: 500;
        }
    }
}
