@import '../../../../../styles/variables';
@import '../../../../../styles/mixins';
@import '../../../../../styles/fonts';

.wrapper {
    h2 {
        margin-bottom: 12px;
        text-align: center;
        font-size: 20px;
        @include font-medium();
        color: $color-black;
    }
    h3 {
        width: 100%;
        margin-bottom: 15px;
        padding: 0 5px;
        text-align: center;
        font-size: 16px;
        line-height: 1.2;
        @include font-medium();
        color: $color-black;
    }
}

.body {
    @include flexBox(space-between);
    text-align: center;
    gap: 20px;
    button {
        align-self: center;
        height: 40px;
        font-size: 14px;
        text-transform: capitalize;
    }
}

.section {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    padding: 14px 8px 11px;
    border: 1px solid $border-color-meshbot;
    border-radius: 4px;
}

.group {
    @include flexBox(center, center);
    button {
        margin-right: 7px;
    }
}

.icon {
    width: 30px;
    height: 30px;
    margin: auto;
    margin-bottom: 10px;
    svg {
        width: 31px;
        height: 26px;
    }
}

.modal {
    width: 626px !important;

    :global {
        .ant-modal-content {
            border-radius: 4px;
        }
        .ant-modal-body {
            padding: 12px 16px 17px;
        }
        .ant-modal-close {
            svg {
                color: $text-color-black;
                font-size: 12px;
            }
        }
    }
}
