@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';

$font-size: 16px;
$line-height: 20px;
$lines-to-show: 2;

.deviceCard {
    &:hover {
        box-shadow: 0px 1px 4px 2px rgba(203, 203, 203, 0.5);
    }

    @include flexFlow(inherit, column);
    @include flexBox(space-between);
    @include font-regular();
    position: relative;
    width: 320px;
    height: 110px;
    padding: 6px 6px 6px 16px;
    margin: 0 8px 8px 0;
    color: #000000;
    background-color: #fff;
    border-radius: 12px;
    border: 1px solid #e4e4e4;
    box-shadow: 0px 1px 4px 2px rgba(203, 203, 203, 0.25);

    .content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .header {
            @include flexBox(space-between, center);

            .iconDeviceBlock {
                @include flexBox(inherit, center);

                &:hover {
                    cursor: pointer;
                }

                .deviceIcon {
                    width: 18px;
                    height: 18px;
                    fill: $text-color-blue;
                }
            }

            .iconDeviceBlock.inaccessible {
                pointer-events: none;
                cursor: default;

                .deviceIcon {
                    fill: rgba(88, 88, 88, 0.8);
                }
            }

            .roomControlUnit {
                display: flex;

                :global {
                    .MuiIconButton-sizeSmall {
                        padding: 0;
                    }
                }

                .blockStatusWrapper {
                    display: flex;
                    margin-right: 3px;
                    align-items: center;

                    .deviceRoomName {
                        padding: 4px 10px;
                        font-size: 12px;
                        font-weight: 400;
                        border: 1px solid #bcbcbf;
                        border-radius: 39px;
                        background-color: #fff;
                    }

                    .unreachable {
                        background-color: #e7e7e7;
                        color: rgba(88, 88, 88, 0.6);
                    }

                    .menuButtonWrapper {
                        position: relative;

                        :global {
                            .MuiButton-root {
                                padding: 1px 15px 1px 10px;
                                font-size: 12px;
                                font-weight: 400;
                                border: 1px solid #bcbcbf;
                                border-radius: 39px;
                                text-transform: none;
                                background-color: #fff;
                                color: #000000;
                            }

                            .Mui-disabled {
                                background-color: #e7e7e7;
                                color: rgba(88, 88, 88, 0.6);
                            }
                        }

                        .arrow {
                            position: absolute;
                            top: 6px;
                            right: 2px;
                        }
                    }
                }
            }
        }

        .nameDevice {
            min-height: 20px;
            display: block;
            display: -webkit-box;
            margin: 12px 0 0;
            font-size: 16px;
            font-weight: 500;
            line-height: 18px;
            -webkit-line-clamp: $lines-to-show;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            word-break: break-word;

            :global {
                .MuiInput-underline:before {
                    content: none;
                }
            }

            span {
                display: block;
                box-sizing: border-box;
                border-bottom: 2px solid transparent;
                line-height: 1.1;
            }

            &:hover {
                span {
                    border-bottom-color: $text-color-blue;
                }
            }

            :global {
                .MuiFormControl-root {
                    display: block;
                    height: 50px;
                    font-size: $font-size;
                    margin-top: 4px;
                }
            }
        }
    }

    .settingsWrapper {
        .settings {
            font-weight: 400;
            font-size: 14px;
            color: $text-color-blue;

            &:hover {
                text-decoration: underline;
            }
        }

        .disabled {
            color: rgba(0, 0, 0, 0.3);
            text-decoration: none;
            pointer-events: none;
            cursor: default;
        }
    }
}

.inactive {
    background: #e7e7e7;
    box-shadow: 0 1px 4px 2px rgba(203, 203, 203, 0.25);
    color: #585858;
    fill: rgba(88, 88, 88, 0.8);
}

.dividingLine {
    margin: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.popper {
    z-index: 1200;
}

.deviceSettingsPoper {
    left: 40px !important;
    top: 5px !important;
}

.integrationPopper {
    left: -70px !important;

    :global {
        .MuiPaper-root {
            border-radius: 12px;

            li {
                padding: 5px 10px 5px 10px;
            }
        }
    }
}

.integrationManagePopper {
    top: -85px !important;
    left: -10px !important;
    width: 166px;

    :global {
        .MuiPaper-root {
            border-radius: 12px;
            box-shadow: 0 8px 24px 0 rgba(144, 160, 175, 0.3);

            li {
                padding: 5px 10px 5px 10px;
                color: $text-color-warning;

                svg {
                    margin: 0 8px 0 0;
                }
            }
        }
    }
}

.paper {
    :global {
        .MuiList-root {
            max-height: 450px;
            overflow-y: auto;
            padding-left: 4px;
            padding-right: 4px;
            @include listScrollbar();

            .MuiButtonBase-root {
                font-size: 14px;
            }

            li {
                border-radius: 12px;
                display: flex;
                align-items: center;

                &:first-letter {
                    text-transform: uppercase;
                }
            }

            li:hover {
                background-color: rgba(62, 70, 174, 0.05);
                color: $color-select-button-blue;
            }
        }
    }
}

.noRooms {
    padding: 0 12px;
    font-size: 14px;
}

.advancedBtnWrapper {
    position: absolute;
    right: 56px;

    .advancedBtn {
        display: flex;
        align-items: center;
        width: 140px;
        padding: 0 22px 0;
        font-size: 16px;
        background-color: transparent;
        border: 1px solid transparent;
        border-radius: 4px;
        color: $color-white;
        cursor: pointer;

        &:hover {
            background-color: rgba(62, 70, 174, 0.05);
        }

        svg {
            height: 24px;
            width: 24px;
            margin: 0 4px 0 0;

            path {
                fill: $color-white;
            }
        }
    }
}

.integrationSettingsWrapper {
    :global {
        .MuiIconButton-root {
            padding: 0;
            margin: 0 0 0 0;
            transform: rotate(90deg);
            width: auto;
            height: auto;

            svg {
                fill: $color-black;
                width: 24px;
                height: 24px;
            }

            &:hover {
                background-color: transparent;
            }
        }
    }

    button {
        width: 100%;
        padding: 0;
        margin: 0 0 0 0;
        transform: rotate(90deg);
    }
}

.settingsStylesIntegration {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-weight: 500;
    color: $color-white;
    font-size: 13px;

    &:hover {
        color: $color-white;
    }

    svg {
        margin: 0 5px 0 0;
        fill: $color-white;
    }
}

.blockStatusWrapperIntegration {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    button {
        padding: 0;
        font-size: 13px;
    }

    svg {
        fill: $color-white;
    }
}

.menuButtonWrapperIntegration {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 13px;
    font-weight: 500;

    svg {
        margin: 0 0 0 5px;
    }

    span {
        color: $color-white;
    }
}
