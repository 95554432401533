@import '../../../../styles/variables.scss';

.actions {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.cancel,
.create {
    width: auto;
    height: 40px;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    font-size: 14px !important;
    &:hover {
        cursor: pointer;
    }
}
.cancel {
    color: $text-color-black !important;
    background-color: $color-white !important;
    border: $border-style !important;
    margin-right: 10px !important;
}
.create {
    background-color: $color-primary-button !important;
    border: none;
    color: $color-white !important;
    &:disabled {
        opacity: 0.5;
    }
    &:hover {
        background-color: $color-primary-button;
    }
}
.data {
    display: flex;
    align-items: flex-start;
    flex-grow: 1;
    @media (max-width: $break-d-m) {
        flex-direction: column;
    }
}
.variablesData {
    display: flex;
    align-items: flex-start;
    flex-grow: 1;
    margin-right: 20px;
    @media (max-width: $break-d-s) {
        flex-direction: column;
    }
}
.title,
.body {
    border-radius: 0.25em;
    border: 1px solid $secondary-text-color;
    padding: 0.5em 0.75em 0.5em 0.75em;
    background-color: $color-white;
    color: $color-black;
}
.title {
    width: 100%;
    max-width: 200px;

    @media (max-width: $break-d-m) {
        width: 100%;
        max-width: 315px;
    }

    :global {
        .MuiFormHelperText-root {
            font-size: 10px;
            font-family: 'Ezlo Sans';
        }
    }
}
.body,
.info {
    width: 100%;
    max-width: 421px;
    @media (max-width: $break-d-m) {
        width: 100%;
        max-width: 630px;
    }
}
.body {
    max-width: 700px;
}
.deviceReferenceWrapper {
    display: flex;
    align-items: flex-end;
    @media (max-width: $break-t-m-expression) {
        flex-wrap: wrap;
    }
    > div {
        margin-right: 20px !important;
    }
}
.addReference {
    min-width: 150px !important;
    border: none;
    background-color: $color-button-blue;
    color: $color-white;
    border-radius: 4px;
    font-size: 14px;
    padding: 4px 14px !important;
    margin-left: 10px;
    @media (max-width: $break-t-m-expression) {
        margin-left: 0;
        margin-top: 10px;
    }
    @media (max-width: $break-m-l) {
        margin-bottom: 10px !important;
    }
    &:hover {
        cursor: pointer;
        background-color: $color-button-blue-hover;
    }
    &:disabled {
        opacity: 0.5;
    }
}
.equalSymbol {
    position: relative;
    border-radius: 20%;
    background-color: $color-black;
    font-weight: 600;
    margin: 7px 14px;
    color: $color-white;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    padding: 5px;
    line-height: 1.1;
    @media (max-width: $break-d-m) {
        align-self: center;
        margin: 10px 0;
        font-size: 26px;
        width: 30px;
        height: 28px;
    }
}
.variablesEqualSymbol {
    border-radius: 20%;
    background-color: $color-black;
    font-weight: 600;
    margin: 7px 14px;
    color: $color-white;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    padding: 4px;
    @media (max-width: $break-d-s) {
        align-self: flex-start;
        margin: 0 40px 20px 0;
        font-size: 26px;
        width: 30px;
        height: 28px;
    }
    @media (max-width: $break-t-s) {
        align-self: center;
        margin-right: 0;
    }
}
.chipsWrapper {
    margin: 10px 0;
    .chip {
        background-color: $color-chip-button-blue;
        color: $color-white;
        &:hover {
            background-color: $color-button-blue-hover;
        }
        :global {
            svg {
                fill: $color-white;
            }
        }
    }
}
.expressionControls {
    display: flex;
    align-items: flex-end;
    margin-left: 30px;
}
.expressionItem {
    background-color: $color-tooltip-background;
    border: 1px solid $text-color-grey;
    border-radius: 12px;
    padding: 12px 13px 10px 13px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 1em;
    :global {
        input {
            padding: 8px 12px;
        }
        .MuiSelect-outlined.MuiSelect-outlined {
            padding: 7px 32px 5px 12px;
        }
    }
}
.codeBlock {
    width: 100%;
    max-width: 700px;
    :global {
        textarea {
            min-height: 45px;
            max-height: 900px;
        }
    }
}
.selectControl {
    min-width: 190px !important;
    background-color: $color-white;
    :global {
        svg {
            font-size: 26px;
            path {
                fill: $text-color-black;
            }
        }
        .MuiFormLabel-root {
            z-index: 10;
        }
    }
}

.select {
    background-color: $color-white;
    min-width: 150px;
}

.expressionItemFlexWrap {
    flex-wrap: wrap;
    flex-direction: column;
}

.expressionActionsMargin {
    margin-top: 20px;
}
