@import 'src/styles/variables';

.inputNumber {
    margin-top: 8px;
}

.inputString {
    margin: 0;
    position: relative;

    label {
        position: absolute;
        font-size: 11px;
        background-color: $color-white;
        color: rgba($color-black, 0.54);
        left: 14px;
        top: 2px;
    }

    input {
        padding: 9px 14px 4px;
        box-shadow: none;
        margin-top: 9px;
        font-size: 14px;
        border-color: rgba($border-gray-color, 0.54);
        color: $color-black;
        &:focus,
        &:hover {
            border-color: $color-black;
        }
    }
}
