.meshbotRunWrapper {
    display: flex;
    justify-content: center;
    width: 100%;

    svg {
        font-size: 22px;
    }

    .disabled {
        svg {
            color: #bdbdbd;
        }
    }
}
