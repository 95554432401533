@import '../../../../../styles/variables.scss';
@import '../../../../../styles/mixins.scss';

.wrapper {
    font-family: 'Ezlo Sans';
    padding: 10px 43px;
    height: calc(100vh - 30px);
    @include inputScrollbar;

    @media (max-width: $break-d-xx) {
        padding: 30px 43px;
    }
    :global {
        .notifications-component {
            right: 0;
        }
        .connections {
            position: relative;
            &:after,
            &:before {
                display: none;
                content: '';
                position: absolute;
                left: -14px;
                width: 14px;
                height: calc(50% + 12px);
                border-color: #333;
                border-style: solid;
                box-sizing: border-box;
            }
            &:before {
                top: -10px;
                left: -17px;
                border-width: 0 0 2px 2px;
            }
            &:after {
                top: 50%;
                border-width: 0 0 0 2px;
                left: -17px;
            }
            &:last-child {
                &:after {
                    display: none;
                }
                &:before {
                    border-radius: 0 0 0 4px;
                    left: -17px;
                }
            }
        }
    }
}

@media screen and (max-width: 940px) {
    .wrapper {
        position: relative;
        margin-top: 21px;
        padding: 40px 6px;
    }
}
