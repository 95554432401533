.container {
    position: relative;
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-top: 10px;
    :global {
        .MuiAlert-root {
            font-size: 14px;
            line-height: 1.2;
            font-weight: 600;
            font-family: 'Ezlo Sans';
        }
    }
    .switchingBlock {
        display: flex;
        align-items: center;
        margin: 0 20px 10px 0;
        p {
            font-size: 14px;
            margin: 0 0 0 5px;
        }
    }
    .timeSettingBlock {
        margin-top: 10px;
        display: flex;
        align-items: flex-start;
        .timeSetterWrapper {
            display: flex;
            flex-direction: column;
            .timeSetter {
                display: flex;
                margin-right: 10px;
                .iconButton {
                    margin-left: 10px;
                }
            }
        }
        :global {
            .MuiButton-root {
                font-size: 14px;
                line-height: 1.2;
            }
            .MuiButton-outlined {
                padding: 5px;
            }
        }
    }
}
