@import '../../../../../styles/mixins';
@import '../../../../../styles/variables.scss';

.modalWrapper {
    position: relative;
    background-color: $color-white;
    font-family: 'Ezlo Sans';
    border-radius: 4px;
    max-width: 700px;
    padding: 15px;
    text-align: center;
}

.information {
    font-size: 16px;
    padding: 15px 0;
}

.dialogButton {
    margin: 20px 0 0 0;
    min-width: 150px;
}
