@import 'src/styles/variables';

.customization-tab-area {
    display: flex;
    flex-direction: column;
    .customization-tabs {
        float: left;
        font-size: 18px;
        text-align: center;
        padding: 0.2em 0.5em;
        min-height: 40px;
        min-width: 100px;
        border: none;
        border-radius: 0.25em;
        margin-left: 15px;
        color: $color-primary-button;
        border: 1px solid $color-primary-button;
        background-color: white;
        margin-top: 1em;
        &:hover {
            cursor: pointer;
            color: $color-white;
            background-color: $color-primary-button;
        }
        &:focus {
            color: $color-white;
            background-color: $color-primary-button;
        }
        &.active {
            background-color: $color-primary-button;
            color: $color-white;
        }
    }
}
.customization-tab-content {
    padding: 20px;
    height: 75vh;
    .tab-content-save-button {
        position: fixed;
        bottom: 50px;
        right: 10px;
        z-index: 999;
        &__next {
            font-size: 18px;
            text-align: center;
            padding: 0.2em 2em;
            color: $color-white;
            background-color: $color-primary-button;
            border: none;
            border-radius: 0.25em;
            margin-top: 2em;
            margin-right: 10px;
            &:hover {
                background-color: $color-primary-button;
                color: white;
            }
        }
        &__save {
            font-size: 18px;
            text-align: center;
            padding: 0.2em 1em;
            border: none;
            border-radius: 0.25em;
            background-color: $color-primary-button;
            color: $color-white;
            margin-top: 2em;
            &:hover {
                cursor: pointer;
                color: white;
                background-color: $color-primary-button;
            }
        }
        &__reset {
            font-size: 16px;
            text-align: center;
            border: none;
            border-radius: 0.25em;
            background-color: $color-white;
            text-transform: none;
            color: $color-primary-button;
            margin: 2.2em 1em 0em;
            margin-right: 10px;
            &:hover {
                cursor: pointer;
                background-color: rgba($color: $color-white, $alpha: 1);
            }
        }
        .MuiButton-contained {
            &.Mui-disabled {
                background-color: $color-button-grey;
            }
        }
    }
}
