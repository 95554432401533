@import '../../../../../styles/variables.scss';

.executionPolicySelect {
    background-color: $color-white;
    border-radius: 4px;
    padding: 0 8px;
    max-width: 256px;
}
.eachActionSelect {
    max-width: 285px;
}

.meshbotExecutionPolicyBox {
    position: absolute;
    right: 10px;
    margin-bottom: -8px;
    &.eachAction {
        position: static;
    }
}
.infoIconButton {
    :global {
        .MuiSvgIcon-root {
            width: 1rem;
            height: 1rem;
            fill: $color-grey;
        }
    }
}
