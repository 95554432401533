@import 'src/styles/variables';

.warning {
    color: #697580;
    display: flex;
    align-items: center;
    margin: 30px 0 0 0;
    max-width: 940px;

    svg {
        min-width: 30px;
    }
}
