@import "../../../../../../styles/variables";

.dialog-layer {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 10000;
}

.dialog-container-backups {
  position: fixed;
  left: 50%;
  top: 65%;
  transform: translate(-50%, -65%);
  width: 44rem;
  z-index: 10100;

  &.transition-enter {
    transform: translate(-50%, -100%);
    opacity: 0.01;
  }

  &.transition-enter-active {
    transform: translate(-50%, -65%);
    opacity: 1;
    transition: transform 300ms, opacity 200ms;
  }

  &.transition-leave {
    opacity: 1;
    transform: translate(-50%, -65%);
  }

  &.transition-leave-active {
    transform: translate(-50%, -100%);
    opacity: 0.01;
    transition: transform 200ms, opacity 100ms;
  }

  .dialog-close {
    cursor: pointer;
    position: absolute;
    top: 2.6rem;
    left: 2.5rem;
  }

  .dialog-header {
    font-size: 1.8rem;
    text-align: center;
    color: #000;
    background: #fff;
    height: 4rem;
    line-height: 4rem;
    border-radius: 24px 24px 0 0;
    margin-top: -1px;
  }

  .dialog-body {
    font-size: 16px;
    padding: 24px;
    background-color: #fff;
    border-radius: 24px;

    .dialog-label,
    .dialog-checkbox {
      display: inline-block;
      vertical-align: middle;
    }

    .dialog-label {
      font-weight: bold;
    }

    .dialog-checkbox {
      float: right;
    }
  }

  .dialog-footer {
    padding: 0 3rem 50px 3rem;
    text-align: center;

    .btn-base {
      text-transform: uppercase;
      padding: 1.3rem 3rem;
      margin-left: 1rem;

      &:first-child {
        margin-left: 0;
      }

      &.btn-rounded {
        min-width: 18rem;
      }
    }
  }
}

.dialog-middle {
  min-width: 450px;
}

.dialog-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(28, 30, 33, 0.75);
  top: 0;
  left: 0;
  z-index: 10000;

  &.transition-enter {
    opacity: 0.01;
  }

  &.transition-enter-active {
    opacity: 1;
    transition: opacity 300ms;
  }

  &.transition-leave {
    opacity: 1;
  }

  &.transition-leave-active {
    opacity: 0.01;
    transition: opacity 200ms;
  }
}
