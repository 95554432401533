@import '../../../../../styles/variables';
@import '../../../../../styles/mixins';

.modal {
    @include flexBox(center, center);

    .modal__button-close {
        position: absolute;
        top: 10px;
        right: 10px;
        padding: 0;

        &:hover {
            background-color: transparent;
        }

        svg {
            fill: $color-white;
            width: 10px;
            height: 10px;
        }
    }

    &__paper {
        position: relative;
        background-color: $color-white;
        box-shadow: 0 3px 5px -1px rgba($color-black, 0.4), 0px 5px 8px 0px rgba($color-black, 0.2),
            0px 1px 14px 0px rgba($color-black, 0.1);
        padding: 24px 32px;
    }
}
