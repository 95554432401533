.columns {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 1080px;
}

.column1,
.column2 {
    width: calc(50% - 16px);
    padding: 80px;
    box-sizing: border-box;
    @media (max-width: 720px) {
        width: 100%;
    }
}

.title {
    padding-top: 30px;
}
.name {
    text-align: center;
}

.styledList {
    margin-bottom: 20px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
}

.title {
    margin-bottom: 10px;
    text-align: center;
}

.menuItem {
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ddd;
    box-shadow: 0 0 5px #ddd;
    background-color: white;
}

.dragHandleIcon {
    float: right;
    margin-right: 10px;
}
