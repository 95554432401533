@import '../../../../../styles/fonts';

.textField {
    :global {
        .MuiFormLabel-root {
            font-size: 16px;
            @include font-medium();
            text-transform: capitalize;
        }
    }
}
.textFieldBox {
    position: relative;
}
