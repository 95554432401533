@import '../../../../styles/variables';
@import '../../../../styles/fonts';
@import '../../../../styles/mixins';

.active-group {
    padding-top: 60px;

    &__title {
        font-size: 20px;
        line-height: 24px;
        padding: 13px 24px 19px;
        margin: 0;
    }

    &__select {
        padding-left: 0;

        .MuiSelect-root {
            width: 300px;
            font-size: 16px;
            line-height: 56px;
            padding: 0 0 0 24px;
        }

        .MuiSelect-select:focus {
            background: none;
        }

        .MuiSvgIcon-root {
            height: 32px;
            width: 32px;
            color: $text-color-black;
            top: calc(50% - 16px);
        }
    }
}
