@font-face {
	font-family: 'Ezlo Sans';
	font-style: normal;
	font-weight: 100;
	src: url('EzloSans-Thin.woff2') format('woff2'), 
		url('EzloSans-Thin.woff') format('woff');
}

@font-face {
	font-family: 'Ezlo Sans';
	font-style: normal;
	font-weight: 300;
	src: url('EzloSans-Light.woff2') format('woff2'), 
		url('EzloSans-Light.woff') format('woff');
}

@font-face {
	font-family: 'Ezlo Sans';
	font-style: normal;
	font-weight: 400;
	src: url('EzloSans-Normal.woff2') format('woff2'), 
		url('EzloSans-Normal.woff') format('woff');
}

@font-face {
	font-family: 'Ezlo Sans';
	font-style: normal;
	font-weight: 400;
	src: url('EzloSans-Regular.woff2') format('woff2'), 
		url('EzloSans-Regular.woff') format('woff');
}

@font-face {
	font-family: 'Ezlo Sans';
	font-style: normal;
	font-weight: 500;
	src: url('EzloSans-Medium.woff2') format('woff2'), 
		url('EzloSans-Medium.woff') format('woff');
}

@font-face {
	font-family: 'Ezlo Sans';
	font-style: normal;
	font-weight: 600;
	src: url('EzloSans-SemiBold.woff2') format('woff2'), 
		url('EzloSans-SemiBold.woff') format('woff');
}

@font-face {
	font-family: 'Ezlo Sans';
	font-style: normal;
	font-weight: 700;
	src: url('EzloSans-Bold.woff2') format('woff2'), 
		url('EzloSans-Bold.woff') format('woff');
}

@font-face {
	font-family: 'Ezlo Sans';
	font-style: normal;
	font-weight: 900;
	src: url('EzloSans-Black.woff2') format('woff2'), 
		url('EzloSans-Black.woff') format('woff');
}