@import '../../../../../../../../../styles/variables.scss';
@import '../../../../../../../../../styles/mixins.scss';
@import '../../../../../../../../../styles/fonts.scss';

.container {
    :global {
        .MuiAccordionSummary-root {
            padding: 0;

            &.Mui-expanded {
                min-height: 48px;
            }
        }

        .MuiAccordionDetails-root {
            padding: 0;
        }

        .MuiAccordion-root.Mui-expanded {
            margin: 0px 0;
        }
    }
}

.accordion_container {
    margin-top: 20px;
}

.accordion_title {
    @include font-medium();
    font-size: 16px;
    color: $color-black;
}

.accordion__summary {
    padding: 0;

    :global {
        .MuiAccordionSummary-content.Mui-expanded {
            margin: 8px 0 8px;
        }

        .MuiIconButton-root {
            padding: 0;
            margin-right: 0;
        }
    }
}

.accordion__details {
    padding: 0;
}

.list_container {
    width: 100%;
    grid-gap: 10px;
    gap: 10px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.card {
    position: relative;
    @include flexBox(inherit, center);
    padding: 10px;
    background: $color-white;
    border: 1px solid rgba($text-color-grey, 0.25);
    box-shadow: 0px 0px 6px 1px rgba($color-black, 0.05);
    border-radius: 12px;
    transition: box-shadow 0.3s linear;
    cursor: pointer;

    &:hover {
        box-shadow: 0px 0px 6px 1px rgba($color-black, 0.25);
    }

    svg {
        display: inline-block;
        margin: 0 5px 0 0;
    }
}

.title {
    margin-bottom: 0;
    @include font-medium();
    font-size: 14px;
    color: $color-black;
}

.img {
    width: 25px;
    height: 25px;
    margin-right: 10px;
}

.expanded_icon {
    margin-right: 0;
    width: 15px;
    height: 9px;
}

.info_button {
    position: absolute;
    top: 12px;
    right: 6px;
    z-index: 1000;
    font-size: 12px;
}
