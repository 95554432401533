@import '../../../../../styles/variables.scss';

.variableTitle {
    background-color: $color-white;
    height: fit-content;
    @media (max-width: $break-d-s) {
        width: 100%;
        max-width: 500px;
        margin: 10px 20px 10px 0 !important;
    }
    &:global(.MuiFormControl-root) {
        @media (min-width: $break-m-m) {
            min-width: 160px;
        }
    }
    :global {
        .MuiFormLabel-root {
            font-size: 12px;
        }
        .MuiInputLabel-formControl {
            left: -2px;
        }
        .MuiFormHelperText-root {
            font-size: 10px;
            font-family: 'Ezlo Sans';
        }
        .MuiInputLabel-shrink {
            transform: translate(14px, -5px) scale(0.95) !important;
        }
        fieldset {
            font-size: 18px;
        }
    }
}
