@import 'src/styles/variables';

.associations-wrapper {
    font-size: 16px;
    font-family: 'Ezlo Sans';
    padding: 1.5em 2.5em;
    position: relative;

    @media (max-width: 750px) {
        padding: 1.5em 1em;
    }

    &_support-notification {
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 30px 0;
    }

    .associations-buttons {
        display: flex;
        justify-content: flex-end;
        margin-left: 5px;
    }
    .associations-add-button,
    .associations-save-button {
        font-size: 16px;
        padding: 0.5em 1em;
        margin-left: 10px;
        color: $color-white;
        background-color: $color-button-blue;
        border: none;
        border-radius: 4px;
        margin-top: 30px;
        width: 212px;

        &:hover {
            cursor: pointer;
            background-color: $color-button-blue-hover;
        }

        &.disabled {
            background-color: $sidebar-active-item-bg-color;
            color: $text-color-grey-basic;
            cursor: auto;
        }
    }
    .associations-cancel-button {
        font-size: 16px;
        padding: 0.5em 1em;
        margin-left: 10px;
        color: rgba(0, 0, 0, 0.87);
        background-color: #e0e0e0;
        border: none;
        border-radius: 4px;
        margin-top: 30px;
        width: 212px;
        height: 40px;

        &:hover {
            cursor: pointer;
            background-color: $color-button-grey-hover;
        }

        &.disabled {
            background-color: $sidebar-active-item-bg-color;
            color: $text-color-grey-basic;
            cursor: auto;
        }
    }
    .associations-item-inner-form {
        margin: 50px 0 0 0;
        .MuiAutocomplete-inputRoot {
            margin-bottom: 20px;
        }
        .MuiSelect-icon {
            border-left: 1px solid rgba(0, 0, 0, 0.15);
        }
        .MuiSelect-iconOpen {
            border-right: 1px solid rgba(0, 0, 0, 0.15);
            border-left: none;
        }
    }
    .associations-item {
        border: $border-style;
        padding: 15px;
        margin: 50px 0 0 0;
        max-width: 940px;
        border: 2px solid #3e46ae;
        border-radius: 16px;
        @media (max-width: 750px) {
            width: 100%;
        }
        .MuiAutocomplete-inputRoot {
            margin-bottom: 20px;
        }

        &__data {
            color: $text-color-grey-basic;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 10px;

            .associations-item__data-associate-with {
                margin: 0 15px;
                padding: 0;
                font-size: 10px;
                color: #90a0af;
                text-align: center;
            }

            &-associate-with {
                display: inline-block;
                padding: 0 15px;
            }

            &__select,
            .MuiFormControl-root.trigger-block__select {
                min-width: 100px;
                margin: 20px 0 0;
                margin-top: 5px;
                padding: 5px;
                .MuiAutocomplete-inputRoot {
                    padding-right: 20px;
                }

                .MuiIconButton-root {
                    border-radius: 0;
                }

                .MuiInputLabel-formControl {
                    color: $text-color-grey-basic-dark;
                    font-size: 16px;
                    padding-top: 0;
                    top: 0;
                }

                .MuiInputLabel-formControl + .MuiInputBase-formControl {
                    margin-top: 5px;
                }

                .MuiInputBase-root .MuiAutocomplete-input {
                    padding: 0 0;
                }

                .MuiSelect-outlined.MuiSelect-outlined {
                    padding: 5px;
                    color: $text-color-basic-black;

                    &:focus {
                        background-color: transparent;
                    }

                    &.Mui-disabled {
                        color: $text-color-grey-basic-dark;
                    }
                }

                label {
                    font-weight: 500;
                    font-size: 10px;
                    line-height: 12px;
                    position: absolute;
                    justify-content: center;
                    text-align: center;
                    top: -15px;

                    color: rgba(28, 30, 33, 0.5);
                }
                &__target,
                .MuiFormControl-root.trigger-block__target {
                    min-width: 200px;
                    margin: 20px 0 0;
                    width: fit-content;
                    margin-top: 5px;
                    padding: 5px;
                    .MuiAutocomplete-inputRoot {
                        padding-right: 20px;
                    }

                    .MuiIconButton-root {
                        border-radius: 0;
                    }

                    .MuiInputLabel-formControl {
                        color: $text-color-grey-basic-dark;
                        font-size: 16px;
                        padding-top: 0;
                        top: 0;
                    }

                    .MuiInputLabel-formControl + .MuiInputBase-formControl {
                        margin-top: 5px;
                    }

                    .MuiInputBase-root .MuiAutocomplete-input {
                        padding: 0 0;
                    }

                    .MuiSelect-outlined.MuiSelect-outlined {
                        padding: 5px;
                        color: $text-color-basic-black;

                        &:focus {
                            background-color: transparent;
                        }

                        &.Mui-disabled {
                            color: $text-color-grey-basic-dark;
                        }
                    }

                    label {
                        font-weight: 500;
                        font-size: 10px;
                        line-height: 12px;
                        position: absolute;
                        justify-content: center;
                        text-align: center;
                        top: -15px;

                        color: rgba(28, 30, 33, 0.5);
                    }
                }
            }

            .delete-btn {
                display: flex;
            }
        }
        .MuiSelect-icon {
            border-left: 1px solid rgba(0, 0, 0, 0.15);
        }
        .MuiSelect-iconOpen {
            border-right: 1px solid rgba(0, 0, 0, 0.15);
            border-left: none;
        }
    }

    .associations-item-list {
        color: $text-color-grey-basic;
        display: flex;
        flex-direction: column;
        padding: 20px 0;
        margin: 0;

        .associations-item__data {
            margin: 0;
            align-items: center;

            &-device {
                border: $border-style;
                border-radius: $border-radius;
                color: $text-color-grey-basic-dark;
                width: 292px;
                padding: 15px;
                margin: 10px 0;

                &_row {
                    display: flex;
                    justify-content: space-between;
                    margin: 0;

                    &__controller-info {
                        display: flex;
                        align-items: center;
                        position: relative;

                        &_block {
                            position: absolute;
                            left: 80px;
                            padding: 15px 10px;
                            min-width: 210px;
                            box-shadow: 0 8px 24px rgba(144, 160, 175, 0.3);
                            border-radius: 12px;
                            display: none;
                            font-size: 13px;
                            background-color: $color-white;
                            color: $text-color-basic-black;

                            p {
                                margin: 0;
                            }
                        }

                        &_information {
                            display: inline-flex;
                            background-color: #3f51b5;
                            padding: 5px 10px;
                            border-radius: 50%;
                            color: #fff;
                            font-size: 16px;
                            cursor: pointer;
                            height: 24px;
                            align-items: center;
                        }

                        &_number {
                            font-size: 13px;
                            margin: 0 15px 0 0;
                            display: flex;
                            align-items: center;

                            .status {
                                display: inline-flex;
                                width: 7px;
                                height: 7px;
                                border-radius: 50%;
                                background-color: $color-online;
                                margin: 0 7px 0 0;

                                &.offline {
                                    background-color: $text-color-warning;
                                }
                            }
                        }

                        &:hover {
                            .associations-item__data-device_row__controller-info_block {
                                display: block;
                                z-index: 1;
                            }
                        }
                    }
                }

                &_col {
                    display: flex;
                    flex-direction: column;
                    line-height: 1.3;
                    margin: 0;

                    &__name {
                        color: $text-color-basic-black;
                    }

                    &__assigned-devices {
                        font-size: 13px;
                    }
                }
            }

            &-device-wrapper {
                display: flex;
                align-items: center;

                .associate-with {
                    margin: 0 15px;
                }
            }
        }
    }

    .warning {
        color: $orange-color;
        display: flex;
        align-items: center;
        margin: 30px 0 13px 0;
    }

    .association-notifications {
        margin: 30px 0 0 10px;

        .warning {
            margin: 0;
        }

        &_maximum-count {
            text-decoration: underline;
        }

        &_commands-wrapper {
            &_title {
                margin: 0 0 5px 0;
                color: #697580;
                font-weight: 500;
                font-size: 13px;
                line-height: 16px;
            }

            &_commands {
                font-size: 10px;
                color: #697580;
                font-weight: 500;
                line-height: 12px;
            }
        }
    }
}
