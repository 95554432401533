@import '../../../../../styles/variables';
@import '../../../../../styles/fonts';

.labelsContainer {
    display: flex;
    margin-right: 3px;
    align-items: center;
}
.allLabels + span {
    min-width: min-content;
}

.label {
    padding: 0 4px;
    border-radius: 4px;
    font-size: 12px;
    @include font-normal();
    margin-right: 2px;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 120px;
    line-height: 20px;
    white-space: nowrap;
}

.ellipses {
    font-size: 14px;
    color: $text-color-grey;
    margin-right: 2px;
}
