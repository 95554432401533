@import '../../../../../styles/variables';
@import '../../../../../styles/fonts';

.device-reset-button__box {
    cursor: pointer;
}

.device-hub-settings {
    &.device-hub-settings__wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .MuiFormLabel-root {
            text-transform: capitalize;
            font-size: 13px;
            line-height: 15.6px;
            @include font-regular();
        }
        .switch-bool {
            &__wrap {
                .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
                    background-color: #3e46ae;
                }
                .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
                    opacity: 1;
                }
                .MuiSwitch-colorSecondary.Mui-checked {
                    color: #ffffff;
                }
            }
        }
    }
}
.MuiList-root {
    .device-hub-settings__menu-item.MuiListItem-root {
        @include font-regular();
        font-size: 16px;
    }
}

.device-hub-settings__title {
    @include font-regular();
    text-transform: capitalize;
    font-size: 18px;
    margin-right: 10px;
    margin-bottom: 0;
}

.device-reset-button__box {
    margin-top: 20px;

    .device-alarm__block {
        max-width: 100%;
    }

    .device-alarm__title {
        font-size: 1.6rem;
    }
}
