@import '../../../../styles/mixins';

.container {
    margin-top: 0;
    :global {
        .MuiButton-outlined {
            border: 1px solid rgba(0, 0, 0, 0.6);
        }
    }
}

.dropzone {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-color: #eeeeee;
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    font-size: 14px;
    transition: border 0.24s ease-in-out;
}

.thumbsContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 16px;
}

.thumb {
    position: relative;
    display: inline-flex;
    border-radius: 2px;
    border: 1px solid #eaeaea;
    margin-bottom: 8px;
    margin-right: 8px;
    width: auto;
    height: 100px;
    padding: 4px;
    box-sizing: border-box;
}

.thumbInner {
    display: flex;
    min-width: 0;
    overflow: hidden;
}

.img {
    display: block;
    width: auto;
    height: 100%;
}

.closeIconBox {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 4px;
    right: 4px;
    background: #ffffff;
    opacity: 0.5;
    svg {
        width: 20px;
        height: 20px;
        opacity: 1;
        z-index: 20;
        cursor: pointer;
    }
}

.notValidFilesBox {
    color: red;
    @include font-medium();
    font-size: 13px;
    li {
        list-style: disc;
        margin-left: 20px;
        line-height: 20px;
    }
}
