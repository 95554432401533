@import '../../../../styles/mixins';
@import '../../../../styles/variables';

.galleryBox {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 30px;
    margin: 0 -10px;
    @media (max-width: $break-t-m) {
        justify-content: center;
    }
}

.emptyMediaStore {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 80%;
    font-size: 32px;
    @include font-medium();
}
