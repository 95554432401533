.select__cameras {
    display: flex;
    padding-left: 10px;
}

.description {
    padding: 20px 15px 0 0;
    font-size: 16px;
    font-weight: 600;
}
