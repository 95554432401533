@import '../../../../../styles/variables.scss';

.variablesTokenTypeWrapper {
    display: flex;
    flex-direction: column;
}
.variablesTokenTypeInput {
    background-color: $color-white;
    &:not(&:last-of-type) {
        margin-right: 20px !important;
    }
    :global {
        .MuiFormLabel-root {
            font-size: 12px;
        }
        .MuiInputLabel-formControl {
            left: -2px;
        }
        .MuiInputLabel-shrink {
            transform: translate(14px, -5px) scale(0.95) !important;
        }
        fieldset {
            font-size: 18px;
        }
    }
}
.variablesTokenFields {
    display: flex;
    align-items: flex-start;
    margin-bottom: 10px;
}
.variablesTokenTypeColon {
    align-self: start;
    margin-right: 20px;
    font-size: 22px;
    font-weight: 600;
}
.variableTokenTypeControlIcon {
    width: 30px;
    height: 30px;
    align-self: center;
    :global {
        svg {
            font-size: 26px;
        }
    }
}
.variablesTokenTypeCheckbox {
    :global {
        .MuiSvgIcon-root {
            font-size: 20px;
        }
    }
}
.variableTokenTypeFieldsControls {
    display: flex;
    flex-direction: column;
    max-height: 50px;
}
.alignButton {
    transform: translateX(-100%);
}
.alignRemoveButton {
    left: -30px;
}

.meshbotVariableTokenInput {
    :global {
        .MuiFormLabel-root {
            font-size: 10px;
        }
        .MuiInputLabel-shrink {
            transform: translate(3px, -2px) scale(0.95);
        }
        .MuiInputBase-input {
            padding: 5px;
        }
    }
}

.meshbotVariableTokenColon {
    margin: 5px 10px;
    font-size: 22px;
}

.meshbotAddIcon {
    &:global(.MuiButtonBase-root) {
        margin-left: 30px;
    }
}
