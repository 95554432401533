@import '../../styles/variables';
@import '../../styles/mixins';

.weeksMonth {
    @include flexBox();
    margin-right: 20px;
    @include itemsGap;
}

.weeksMonth_item {
    &:last-child {
        :global {
            .MuiTypography-body1 {
                width: 67px;
            }
        }
    }
    :global {
        .MuiIconButton-colorSecondary {
            display: none;
        }
        .MuiTypography-body1 {
            @include flexBox(center, center);
            @include itemsFlow();
        }
        .MuiFormControlLabel-root {
            margin: 0;
        }
    }
}

.active {
    :global {
        .MuiTypography-body1 {
            @include itemsActiveFlow;
        }
    }
}
