.dynamic-fields-section {
  &__description {
    font-size: 16px;
  }

  &__description.MuiGrid-item{
    margin-bottom: 15px;
  }

  &.MuiGrid-spacing-xs-3 {
    margin: 25px 0;
  }
}