@import "../../styles/variables";
@import "../../styles/mixins";

.switch-bool {
    &__wrap {
        @include flexBox(inherit, center);
        span {
            font-size: 12px;
            font-weight: bold;
            text-transform: uppercase;
        }
    }
}
