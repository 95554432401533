@import '../../../../../styles/variables';
@import '../../../../../styles/mixins';
@import '../../../../../styles/fonts';

.uploadImgFieldContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;

    &:last-child {
        flex: 1 1 auto;
        @media (max-width: $break-d-s) {
            align-items: stretch;
        }
    }

    @media (max-width: $break-d-s) {
        justify-content: flex-start;
    }
}
.imagesFieldsBox {
    display: flex;
    align-items: flex-end;
    flex: 1 1 100%;

    button {
        margin-right: 10px;
        min-width: 140px;
        font-size: 14px;
    }
    .thumbInner {
        width: min-content;
        margin-top: 10px;
        img {
            max-width: 80px;
            background: rgba(230, 231, 232, 0.43);
            border: 1px solid #cccccc;
        }
        @media (max-width: $break-d-s) {
            width: 100%;
        }
    }
    .previewBox {
        display: flex;
        flex-direction: column;
    }
    @media (max-width: $break-d-s) {
        flex-direction: column;
        align-items: stretch;
    }
}
.imgBlock {
    display: flex;
    align-items: flex-end;
    flex-grow: 1;
    flex-shrink: 1;
    @media (max-width: $break-d-s) {
        align-items: flex-start;
    }
}
.uploadImageBox {
    display: flex;
    align-items: flex-end;
}
.conditionText {
    display: flex;
    align-items: center;
    height: 40px;
    padding: 10px 15px;
    text-transform: uppercase;
    @include font-semibold();
    @media (max-width: $break-d-s) {
        justify-content: center;
        align-self: stretch;
    }
}
.helperText {
    padding: 7px 0;
}
.errorText {
    margin-top: 7px;
    color: red;
}
.imageFieldLabel {
    margin: 10px 10px 10px 0;
}
.infoTextBox {
    position: absolute;
    top: 100%;
    font-size: 11px;
    @media (max-width: $break-d-s) {
        position: static;
    }
}
