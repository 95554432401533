@import "../../../../../styles/variables";

.device-alarm__text {
    font-size: 1.5rem;
    color: #393939;
    margin-bottom: 20px;
}

.device-range-slider__box {
    position: relative;
    max-width: 300px;
    margin-bottom: 20px;
    padding-bottom: 10px;
}

.device-range__button-save {
    width: 100%;
    max-width: 350px;
}

.device-rgb-slider__box {
    display: flex;
    align-items: center;
    max-width: 300px;

    h3 {
        min-width: 50px;
        margin-right: 15px;
        font-size: 1.6rem;
        line-height: normal;
        color: #3f3f3f;
        font-family: sf_ui__textregular, Helvetica, Arial, sans-serif !important;
    }

    input[type=range]  {
        width: 100%;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        outline: none;
        overflow: hidden;
        height: 16px;
    }

    input[type=range]::-webkit-slider-runnable-track {
        height: 8px;
        border-radius: 4px;
        background: rgb(34,23,212);
        background: linear-gradient(90deg, rgba(34,23,212,1) 0%, rgba(15,121,9,1) 25%, rgba(228,11,44,1) 50%, rgba(205,19,217,1) 75%, rgba(223,228,12,1) 100%);
    }

    input[type=range]::-webkit-slider-thumb {
        background: #0000ff;
        cursor: pointer;
        width: 15px;
        height: 15px;
        border-radius: 100%;
        -webkit-appearance: none;
        margin-top: -4px;
        box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
    }

    input[type=range]::-moz-range-track {
        height: 8px;
        background: rgb(34,23,212);
        border-radius: 4px;
        background: linear-gradient(90deg, rgba(34,23,212,1) 0%, rgba(15,121,9,1) 25%, rgba(228,11,44,1) 50%, rgba(205,19,217,1) 75%, rgba(223,228,12,1) 100%);
    }

    input[type=range]::-moz-range-thumb {
        background: #0000ff;
        cursor: pointer;
        height: 12px;
        border-radius: 0;
        box-shadow: -200px 0 0 200px $rgb-slider-gradient;
        padding: 0;
        margin: 0;
    }
}

.device-range-footer {
    margin-top: 30px;
}

.device-rgb-preview__box {
    max-width: 300px;
    height: 40px;
    border: 1px solid #6c6c6c;
    border-radius: 3px;
    margin-bottom: 20px;
}

.device-range-slider {
    width: 100%;
}

.device-range__button {
    background-color: #e3e3e3;
    border: 0;
    margin-right: 20px;
}

.device-range-footer {
    margin-bottom: 10px;
}

.device-range-restore {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
}

.device-range-start {
    position: absolute;
    left: 0;
    bottom: 0;
}

.device-range-finish {
    position: absolute;
    right: 0;
    bottom: 0;
}

.device-alarm__item {
    margin: 20px 0;
}

.device-alarm__key {
    color: #575D70;
    font-size: 17px;
}

.device-alarm__value {
    color: $additional-text-color;
    font-size: 18px;
    font-weight: 600;
}

