.tooltipBody {
    position: absolute;
    width: 300px;
    bottom: 105px;
    right: 10px;
    background: #fff;
    border-radius: 4px;
    padding: 20px 20px 12px 12px;
    font-size: 14px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
    .iconHide {
        position: absolute;
        top: 4px;
        right: 4px;
    }
    .label {
        margin-right: 5px;
        font-weight: 500;
    }
    .value {
        font-weight: 300;
    }
}
