@import 'src/styles/variables';
@import 'src/styles/mixins';

.selectDropdownWrapper {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    right: 0;

    button {
        background-color: transparent;
        border: none;
        font-size: 16px;
        display: flex;
        align-items: center;
        cursor: pointer;
        color: $color-primary-button;
        font-weight: 600;
        padding: 5px;
        line-height: 12px;

        svg {
            font-size: 20px;
            display: inline-block;
            margin: 0 0 0 15px;
        }
    }
}
