@import '../../../styles/variables';
@import '../../../styles/mixins';

.supportPageWrapper {
    padding: 30px;

    .supportListWrapper {
        .supportListItemWrapper {
            display: flex;
            align-items: center;
            border-bottom: $border-style;
            padding: 10px 0;

            .supportListItem {
            }

            .supportListItemIcon {
                width: 40px;
                height: 40px;
                margin: 0 15px 0 0;
            }

            .supportListItemTitle {
                display: block;
                font-size: 18px;
                margin: 0 0 5px 0;
            }

            .supportListItemText {
                font-size: 16px;
            }

            .createTicketButton {
                cursor: pointer;
                color: $ezlo-dark-blue;
            }
        }
    }
}
