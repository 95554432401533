@import '../../../../../styles/variables';
@import '../../../../../styles/fonts';
@import '../../../../../styles/mixins';

.changePasswordContainer {
    .warningText {
        font-size: 14px;
        @include font-medium();
        color: $text-color-grey;
        display: flex;
        padding-top: 0;
    }
}
