@import '../../../../../styles/variables';
@import '../../../../../styles/mixins';

.modal {
    @include flexBox(center, center);

    .modal__button-close {
        position: absolute;
        top: 10px;
        right: 10px;
    }

    &__paper {
        position: relative;
        background-color: $color-white;
        box-shadow: none;
        padding: 0;
        font-family: 'Ezlo Sans';
        border-radius: 4px;
    }
}
