@import '../../../styles/variables.scss';

.virtualDeviceForm {
    display: flex;
    flex-direction: column;
    padding: 30px;
    margin: 20px 0;

    :global {
        .MuiAutocomplete-input {
            padding: 6px 0 !important;
        }

        .MuiAutocomplete-root {
            margin-right: 20px;
        }

        .MuiButton-root {
            font-size: 16px;
            padding: 5px 14px;
            .MuiSvgIcon-root {
                font-size: 38px;
                margin-right: 5px;
            }
        }

        .MuiInputBase-input {
            line-height: 1.2;
            color: $text-color-black;
        }
    }
}

.backButton {
    font-family: Ezlo Sans;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    font-size: 24px;
    display: flex;
    align-items: center;
    cursor: pointer;
    color: $text-color-black;
    margin-bottom: 40px;

    :global {
        .MuiSvgIcon-root {
            margin-right: 20px;
            font-size: 20px;
        }
    }
    p {
        padding: 0;
        margin: 0;
    }
}

.titleHeader {
    font-family: Ezlo Sans;
    font-size: 20px;
    line-height: 24px;
    color: #1c1e21;
    margin: 10px 0;
    padding: 30px 0;
    border-bottom: 1px solid rgba(144, 160, 175, 0.15);
}

.buttonContainer {
    margin: 60px 30px 0 auto;

    :global {
        button {
            min-width: 280px;
        }
    }
}

.saveButton {
    width: 200px;
}

.virtualDeviceHeader {
    &:global(.header) {
        &:global(.mui-fixed) {
            padding: 19px 10px 5px;
        }
    }
}

.inputWrapper {
    max-width: 500px;

    :global {
        label {
            font-size: 16px;
        }
    }
}
