@import '../../../../../styles/fonts';
@import '../../../../../styles/variables';

.title {
    font-size: 32px;
    line-height: 38px;
    margin-bottom: 16px;
    @include font-semibold();

    @media (max-width: $break-m-xs) {
        font-size: 25px;
    }
}

.subTitle {
    font-size: 16px;
    line-height: 23px;
    width: 100%;
    color: $text-color-black;
    @media (max-width: $break-m-xs) {
        font-size: 14px;
    }
}
