@import '../../styles/mixins.scss';

.root {
    position: relative;
    display: flex;
    gap: 30px;
    margin-right: 20px;
    margin-bottom: 20px;
    margin-top: 30px;
}

.button {
    align-self: flex-start;
}

.loading {
    position: absolute;
    top: -15px;
    width: 100%;
    left: 0;
}

.inspector {
    flex: 1;
    resize: vertical;
    overflow: auto;
    min-height: 130px;

    color: rgba(0, 0, 0, 0.38);
    line-height: 16px;
    border-radius: 4px;
    border-color: rgba(0, 0, 0, 0.26);
    border-style: solid;
    border-width: 1px;
    overflow-y: auto;
    @include inputScrollbar;
}

.inner {
    min-height: 130px;
    padding: 15px 14px;
    max-height: 130px;
}

.preview {
    flex: 1;
}

.textarea {
    border: none;
    background-color: transparent;
    resize: none;
    width: 100%;
    font-size: 14px;
}

.raw {
    color: rgba(0, 0, 0, 0.87);
}
