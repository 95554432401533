@import './../../styles/variables';

.headerClassName {
    h1 {
        @media (max-width: $break-m-s) {
            width: 100%;
            text-align: center;
        }
    }
}
.buttonBox {
    @media (max-width: $break-t-s) {
        width: 100%;
        display: flex;
        justify-content: end;
    }
    @media (max-width: $break-m-s) {
        justify-content: center;
    }
    :global {
        .MuiButton-root {
            @media (max-width: $break-t-s) {
                font-size: 14px;
                padding: 6px 15px;
            }
        }
        .MuiSvgIcon-root {
            @media (max-width: $break-t-s) {
                font-size: 25px !important;
            }
        }
    }
}
