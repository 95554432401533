@import '../../../../../styles/mixins.scss';
@import '../../../../../styles/variables';

.wrapper {
    .select {
        @include selectStyle();

        :global {
            .MuiInputBase-input {
                width: auto;
                max-width: 200px;
            }
        }
    }
}

.serviceImg {
    width: 20px;
}

.list {
    display: flex;
    align-items: center;
    gap: 10px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    span {
        font-size: 12px;
    }
}

.popper {
    width: auto !important;
    min-width: 200px;
}

.currentValueBox {
    display: flex;
    flex-direction: column;
    max-width: 250px;
    white-space: nowrap;
    .currentValueTitle {
        margin-right: 3px;
        text-overflow: ellipsis;
        overflow: hidden;
    }
}

.currentValueBoxWithProgressBar {
    display: flex;
    max-width: 250px;
    white-space: nowrap;
    :global {
        .MuiLinearProgress-root {
            height: 2px;
            width: 50px;
            margin-top: 6px;
        }
    }
    .currentValueTitle {
        margin-right: 3px;
        text-overflow: ellipsis;
        overflow: hidden;
    }
    .currentValueNull {
        color: $text-color-error;
    }
}

.floatToIntegerLabel {
    margin-top: 5px;
    color: $text-color-error;
    white-space: pre-line;
}

.error {
    color: $text-color-error;
}
