.deviceGroupsLayout {
    height: calc(100vh - 90px);
    padding: 40px 30px 0 30px;
    @media (min-width: 768px) {
        padding: 10px 30px 0 30px;
    }
    :global {
        .grid-table-wrapper {
            .MuiDataGrid-root {
                border: none;
                .MuiDataGrid-columnHeaderTitleContainer {
                    &:last-of-type {
                        border-right: none;
                    }
                }
                .MuiDataGrid-cell {
                    &:global(.MuiDataGrid-cell--textLeft) {
                        padding: 0 25px;
                    }
                }
            }
        }
    }
}

.deviceGroupsTable {
    margin-top: 60px;

    :global {
        .MuiDataGrid-cell {
            button {
                svg {
                    font-size: 22px;
                }
            }
        }
    }
}
