@import '../../../../../../styles/variables.scss';
@import '../../../../../../styles/mixins.scss';

.triggerWrapperWithExceptionBlock {
    display: flex;
    flex-direction: column-reverse;
    :global {
        .trigger-block {
            border-bottom-right-radius: 0;
            margin-bottom: 0;
        }
    }
}

.triggerExceptionBlock {
    margin-bottom: 16px;
    margin-top: -16px;
    padding: 11px 16px 16px 16px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    background-color: $trigger_exception_bg;
}

.list_actions {
    margin-left: 20px;
}
