@import '../../../../styles/fonts';
@import '../../../../styles/variables';

.loginBtn {
    @include font-normal();
    display: flex;
    height: 56px;
    font-size: 16px;
    text-transform: none;
    box-shadow: none;
    width: 100%;
    background-color: $text-color-blue;

    &:hover {
        color: $color-white;
    }

    &:global(.MuiButtonBase-root) {
        margin-top: 48px;
    }
}
