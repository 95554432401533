@import '../../../../../../../../../styles/variables.scss';

.devicesFilteringBlock {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 18px;
    .buttonFiltering {
        margin: 0 4px 5px 0;
    }
    :global {
        .MuiButton-root {
            padding: 1px 10px 1px 10px;
            font-size: 12px;
            font-weight: 400;
            border: 2px solid #eee;
            border-radius: 39px;
            text-transform: none;
            background-color: #fff;
            color: #000;
        }
    }

    .buttonFiltering.active {
        border: 2px solid $text-color-blue;
        background-color: #fff;
        color: $text-color-blue;
    }
    .buttonFiltering.disabled {
        border: 2px solid #bcbcbf;
        background-color: #e7e7e7;
        color: rgba(0, 0, 0, 0.38);
        &:hover {
            cursor: default;
        }
    }
}
