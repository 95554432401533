@import '../../styles/mixins.scss';

.meshbotField {
    :global {
        .MuiFormControl-root {
            @include selectStyle();
            max-width: 400px;
        }
        .MuiFormHelperText-root {
            font-size: 10px;
        }

        .MuiSelect-root {
            max-width: 355px;
            padding-right: 20px !important;
        }
    }
}

.menuItem {
    :global {
        .MuiTypography-body1 {
            font-size: 14px;
            font-family: inherit;
        }

        .MuiListItemIcon-root {
            min-width: 40px;
        }
    }
}
