@import "../../../../../styles/fonts";
@import "../../../../../styles/variables";

.disable-remove-settings-block {
  .maintenance-remove-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 0;
  }
  .maintenance-remove__button-box {
    width: 100%;
    padding: 22px 24px;
    .MuiButton-root.Mui-disabled {
      background-color: rgba($text-color-grey, 0.15);
      color: $text-color-grey;
    }
  }
  .maintenance-remove__button,
  .MuiButton-root:hover{
    background-color: $color-select-button-blue;
  }

  .additional,
  .additional.MuiButton-root:hover{
    background-color: $text-color-warning;
  }
  button {
    @include font-regular();
    height: 56px;
    font-size: 16px;
    color: $color-white;
    span {
      text-transform: capitalize;
    }
  }

}

.text-bold {
  font-weight: 900;
}
