.fieldBox {
    position: relative;
}

.fadeBox {
    position: absolute;
    width: 100%;
    z-index: 10;
    padding-top: 4px;
}
