.wrapper {
    display: inline-flex;

    :global {
        .input-block {
            min-width: 35px;
            margin-right: 0;
        }
    }
}
