@import '../../../../../styles/variables';
@import '../../../../../styles/mixins';
@import '../../../../../styles/fonts';

.labelContainer {
    height: 51px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 13px 2px 13px 16px;
    &:hover {
        background-color: #90a0af26;
    }
    :global {
        .MuiIconButton-root {
            padding: 8px;
        }
    }
}

.labelBox {
    position: relative;
    width: 100%;
    cursor: pointer;
}
.selectedLabel {
    background: #90a0af40;
}

.labelContent {
    display: flex;
    align-items: center;
    :global {
        .MuiSvgIcon-root {
            font-size: 16px;
        }
    }
}
.labelName {
    font-size: 14px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 135px;
    margin-left: 5px;
    @include font-medium();
}
