@import '../../../../../styles/variables';
@import '../../../../../styles/fonts';
@import '../../../../../styles/mixins';

.enrollmentsList {
    width: 100%;
    display: flex;
    margin: 0 -8px;
    flex-wrap: wrap;
    padding: 10px 0 18px;
}

.enrollmentListItem {
    width: 350px;
    padding: 8px;
}

.enrollmentBox {
    height: 100%;
    min-height: 105px;
    padding: 10px 15px;
    border-radius: 12px;
    box-shadow: 0 1px 4px 2px rgba(203, 203, 203, 0.25);
    background: $card-bg-color;
    transition: all 0.4s ease;
    &:hover {
        box-shadow: 0 1px 6px 4px rgba(203, 203, 203, 0.35);
    }
}
.enrollment {
    width: inherit;
    height: inherit;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    @include font-regular();
    font-size: 18px;
}

.enrollmentIconContainer {
    svg {
        fill: $text-color-black;
        height: 28px;
        width: 28px;
    }
}

.enrollmentImg {
    width: 25px;
}

.name {
    margin-top: 7px;
    white-space: normal;
    color: $text-color-black;
    font-size: 14px;
    @include font-bold();
    -webkit-line-clamp: 2;
}
.name,
.info {
    max-width: 304px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.info {
    @include font-thin();
    font-size: 12px;
    margin-top: 5px;
    -webkit-line-clamp: 1;
}

.settingsTextButtonBox {
    :global {
        .MuiTypography-body1 {
            font-size: 14px;
            @include font-normal();
            display: flex;
            cursor: pointer;
        }
        .MuiTypography-body1:hover {
            opacity: 0.8;
            transition: opacity 0.3s ease-in-out;
        }
    }
    display: flex;
    align-items: end;
    flex: 1 1 auto;
    margin-top: 7px;
}

.settingsModalContent {
    padding: 25px 40px;
    .title {
        font-size: 24px;
        @include font-semibold();
        @media (max-width: $break-m-m) {
            font-size: 22px;
            overflow-x: scroll;
            @include listScrollbar();
        }
    }
    .subtext {
        font-size: 13px;
        @include font-medium();
        color: $text-color-dark-grey;
        padding: 16px 0;
    }
    button {
        height: 56px;
        max-width: 47%;
        @media (max-width: $break-m-m) {
            max-width: 100%;
        }
    }
    @media (max-width: $break-m-m) {
        padding: 15px 20px;
    }
}
.deleteButtonBox {
    button {
        height: 40px;
        font-size: 14px;
        @include font-medium();
        background: $text-color-warning;
        color: #ffffff;
    }
    button:hover {
        background: $text-color-warning;
        opacity: 0.9;
        transition: opacity 0.3s ease-out;
    }
}
.fieldBox {
    padding: 20px 0;
}
.buttonContainer {
    margin-top: 20px;
    @include flexBox(space-between, center);
    @media (max-width: $break-m-m) {
        flex-wrap: wrap;
        flex-direction: column-reverse;
    }
    button:first-child {
        @media (max-width: $break-m-m) {
            margin-top: 20px;
        }
    }
}
