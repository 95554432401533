@import '../../../../../styles/variables';
@import '../../../../../styles/fonts';
@import '../../../../../styles/mixins';

.userForm {
    max-width: 550px;
    padding: 80px 40px 0;
    & > div {
        padding-bottom: 20px;
    }
    :global {
        .MuiFormHelperText-root {
            font-size: 11px;
            @include font-normal();
        }
    }
}

.passwordTitle {
    font-size: 18px;
    @include font-medium();
    color: $text-color-black;
    padding-top: 42px !important;
    padding-bottom: 25px !important;
}

.passwordField {
    svg {
        margin-bottom: 5px;
    }
}
.fieldInfo {
    font-size: 14px;
    @include font-medium();
    color: $text-color-grey;
    display: flex;
    padding-top: 15px;
}

.changePasswordButton {
    padding-top: 10px;

    :global {
        .MuiButton-root {
            min-height: 56px;
            text-transform: capitalize;
            font-size: 16px;
            @include font-medium();
        }
    }
}
