@import '../../../../../styles/variables.scss';
@import '../../../../../styles/mixins.scss';

.deviceGroupsCreateHeaderWrapper {
    @include flexBox(inherit, center);
    padding: 0 20px;
    @media (min-width: $break-d-burger) {
        padding: 0;
    }
    .title {
        margin: 0;
    }
}
