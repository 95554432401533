@import '../../../../styles/variables';
@import '../../../../styles/fonts';
@import '../../../../styles/mixins';

.integrationHeaderIcon {
    width: 25px;
}
.integrationHeader {
    display: flex;
    align-items: center;
}
.integrationHeaderTitle {
    color: $text-color-real-black;
    font-size: 18px;
    @include font-medium();
}
.integrationHeaderSubtitle {
    color: #6b757f;
    font-size: 12px;
    @include font-normal();
}
.integrationHeaderLine {
    background: #bcbcbf;
    display: flex;
    flex: 1 1 auto;
    height: 1px;
}
.integrationHeaderBox {
    margin: 0 15px;
}
.settingsIntegrationsSection {
    margin: 25px 0;
    height: 100%;
}
.helperText {
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: inherit;
}
