@import '../../../../../../../styles/variables.scss';
@import '../../../../../../../styles/fonts';
@import '../../../../../../../styles/mixins';

.controllerDevices {
    margin-bottom: 35px;

    :global {
        .MuiPaper-elevation1 {
            box-shadow: none;
        }
    }

    .icon {
        height: 8px;
        width: 16px;
        transform: rotate(180deg);
    }

    .accordionDetails {
        display: flex;
        flex-direction: column;
    }

    .devicesWrapper {
        display: flex;
        flex-wrap: wrap;
    }

    .notification {
        display: flex;
        width: 100%;
        margin-top: 10px;
        justify-content: center;
        font-size: 16px;
        font-weight: 500;
    }
}
