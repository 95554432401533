.devicesTreeWrapper {
    margin-top: 40px;
    padding: 30px 40px;
    @media (max-width: 630px) {
        padding: 70px 40px 30px 40px;
    }
    @media (max-width: 475px) {
        padding: 100px 40px 30px 40px;
    }
}

.notification {
    margin-top: 100px;
    width: 100%;
    display: flex;
    justify-content: center;
}
