@import '../../../../../styles/variables.scss';
@import '../../../../../styles/fonts.scss';
@import '../../../../../styles/mixins.scss';

.container {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.block {
    display: flex;
}

.label {
    @include flexBox(inherit, center);
    min-width: 50px;
    margin-right: 16px;

    color: $text-color-grey;
    @include font-medium();
    font-size: 13px;
    white-space: nowrap;
}
