@import '../../../../../../../styles/variables.scss';
@import '../../../../../../../styles/mixins.scss';

.rootWrapper {
    &:global(.MuiTreeView-root) {
        max-width: 500px;
        padding: 0 20px;
    }

    :global(.MuiButtonBase-root) {
        svg {
            max-width: 40px;
            height: 40px;
        }
    }

    :global(.MuiTypography-root) {
        display: flex;
        align-items: center;
        border-bottom: 1px solid rgba(144, 160, 175, 0.25);
    }
}
