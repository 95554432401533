@import '../../../../styles/variables.scss';

.listHeader {
    padding-left: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: 'Ezlo Sans';

    h1 {
        font-weight: 600;
        font-size: 32px;
        line-height: 38px;
        letter-spacing: -0.015em;
        margin-top: 10px;
        line-height: 1;
        width: calc(100% - 160px);
        text-align: center;
    }
}
.headerChild {
    padding: 31px 0px 0px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: 'Ezlo Sans';
    .headingExistingAssociation {
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 29px;
        display: flex;
        align-items: center;
        @media (max-width: 750px) {
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 29px;
            display: flex;
            align-items: center;
            margin-left: -30px;
        }
    }
    @media (max-width: 500px) {
        padding-top: 100px;
        :global {
            .MuiButtonBase-root {
                .MuiButton-label {
                    font-size: 12px;
                }
            }
        }
    }
    @media (max-width: 400px) {
        padding-top: 120px;
        :global {
            .MuiButtonBase-root {
                .MuiButton-label {
                    font-size: 10px;
                }
            }
        }
    }
}
.titleSettings {
    font-family: 'Ezlo Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
}
