@import '../../../../styles/variables.scss';
@import '../../../../styles/mixins.scss';

.triggerItemWrapper {
    @include flexBox(space-between, center);
    border: $border-style;
    border-radius: 12px;
    padding: 10px 20px;
    margin: 20px 0;
}

.triggerItem {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    column-gap: 10px;
    row-gap: 20px;
    background: $color-white;
    box-sizing: border-box;

    @media (max-width: $break-t-m) {
        row-gap: 30px;
        padding-top: 20px;
    }

    :global {
        .MuiFormLabel-root {
            font-size: 16px;
            position: absolute;
            left: 10px;
            top: -10px;
            padding: 0;
            flex-grow: 1;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 90%;
        }

        .MuiSelect-select:focus {
            background-color: transparent;
        }

        .MuiInputBase-root {
            min-width: 200px;
            margin: 0 10px;
        }

        .MuiInputLabel-formControl {
            transform: translate(0, 19px) scale(1);
        }

        .MuiInputLabel-shrink {
            transform: translate(0, 1.5px) scale(0.75);
        }
    }
}

.deleteIcon {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translatey(-50%);
    cursor: pointer;

    :global {
        .MuiSvgIcon-root {
            font-size: 28px;
            color: #999;
        }
    }
}

.codeField {
    padding: 5px;
    margin: 0 5px;

    &:global(.MuiTextField-root) {
        min-width: 350px;

        @media (max-width: $break-m-l) {
            min-width: 250px;
        }
    }

    :global {
        fieldset {
            &.MuiOutlinedInput-notchedOutline {
                border: $border-style;
                border-radius: 12px;
            }
        }
    }
}

.modeSwitch {
    margin: 0 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 30px;

    :global {
        .MuiToggleButtonGroup-groupedHorizontal {
            min-width: 100px;

            &:first-child {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }

            &:last-child {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }

            &:not(:first-child) {
                margin: 0;
            }
        }

        .MuiToggleButton-root {
            padding: 2px 5px;
            border-radius: 8px;
            border: none;
            text-transform: none;

            &:first-child {
                padding-right: 0;
            }

            &:last-child {
                padding-left: 0;
            }

            &.MuiToggleButtonGroup-grouped {
                background-color: $color-button-grey-5;
            }
        }

        .MuiToggleButton-label {
            font-family: Ezlo Sans;
            font-size: 15px;
            color: $text-color-black;
        }

        .Mui-selected {
            .MuiToggleButton-label {
                background-color: $color-white;
                box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.12), 0px 3px 1px rgba(0, 0, 0, 0.04);
                border-radius: 7px;
                padding: 2px 8px;
            }
        }
    }

    p {
        color: #90a0af;
        font-family: Ezlo Sans;
        font-size: 13px;
        padding-top: 5px;
    }
}

.capabilitySelect {
    :global {
        .MuiInputBase-root {
            min-width: 275px;

            @media (max-width: $break-m-l) {
                min-width: 220px;
                padding-top: 20px;
            }
        }

        .MuiSelect-select {
            padding: 6px 24px 6px 0;
        }
    }
}

.variableSelect {
    :global {
        .MuiSelect-select {
            padding: 6px 24px 6px 0;
        }
    }
}

.deleteItemBtn {
    align-self: flex-start;
    margin-top: 25px;
}

:global {
    .MuiAutocomplete-paper {
        .MuiAutocomplete-option {
            font-family: Ezlo Sans;
            font-size: 16px;
            color: $text-color-black;
        }
    }

    .MuiAutocomplete-popper {
        border-radius: 12px;
        box-shadow: 0px 8px 24px rgba(144, 160, 175, 0.3);
    }
}
