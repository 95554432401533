@import '../../../../../styles/variables';
@import '../../../../../styles/mixins';

.devices-header {
    padding-bottom: 30px;
    padding-top: 20px;
    &__block {
        position: relative;
    }
    &__icon {
        width: 24px !important;
        height: 24px !important;
        position: absolute;
        top: 50%;
        left: 6px;
        transform: translateY(-50%);
        fill: $text-color-dark-grey !important;
    }
    .MuiInputBase-root {
        width: 250px;
        border-radius: 16px;
        .MuiInputBase-input {
            font-size: 14px;
            padding: 10px 10px 10px 38px;
        }
    }
    .MuiFormLabel-root {
        font-size: 12px;
        left: 20px;
    }
}
