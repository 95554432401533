@import '../../../../../styles/variables.scss';

.variableBody {
    width: 100%;
    max-width: 500px;
    background-color: $color-white;
    :global {
        .MuiFormLabel-root {
            font-size: 12px;
        }
        .MuiInputLabel-formControl {
            left: -2px;
        }
        .MuiInputLabel-shrink {
            transform: translate(14px, -5px) scale(0.95) !important;
        }
        fieldset {
            font-size: 18px;
        }
    }
}
:global {
    .MuiOutlinedInput-multiline.MuiOutlinedInput-marginDense {
        padding: 7px 12px 6px !important;
    }
}
