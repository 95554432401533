@import '../../../../../../../styles/fonts.scss';
@import '../../../../../../../styles/variables.scss';

.buttonLadel {
    @include font-normal();

    padding: 10px 0;
    font-size: 16px;
    color: $text-color-black;
    margin-left: 7px;
}

.menuButton.inherited {
    width: 84px;
    font-size: 12px;
    padding: 2px 4px 2px 6px;
    border: 2px solid rgba(144, 160, 175, 0.5);
    color: #1c1e21;
    &:hover {
        border: 2px solid rgba(144, 160, 175, 0.5);
    }
    .arrowIcon {
        margin-right: 0;
        height: 25px;
        font-size: 18px;
    }
    :global {
        .MuiButton-endIcon {
            margin-left: 2px;
        }
    }
}

.menuButton {
    &:global(.MuiButton-outlined.Mui-disabled) {
        border-width: 2px;
    }
}

.menuButton.inheritedIncluded {
    width: 84px;
    font-size: 12px;
    padding: 2px 4px 2px 6px;
    border: 2px solid $text-color-blue;
    color: $text-color-blue;
    &:hover {
        border: 2px solid $text-color-blue;
    }
    .arrowIcon {
        margin-right: 0;
        height: 25px;
        font-size: 18px;
        fill: $text-color-blue;
    }
    :global {
        .MuiButton-endIcon {
            margin-left: 2px;
        }
    }
}

.menuButton.included {
    width: 84px;
    font-size: 12px;
    border: 2px solid $text-color-blue;
    background-color: $text-color-blue;
    color: #fff;
    padding: 2px 4px 2px 6px;
    &:hover {
        background-color: $text-color-blue;
        border: 2px solid $text-color-blue;
    }
    .arrowIcon {
        margin-right: 0;
        height: 25px;
        font-size: 18px;
        fill: #fff;
    }
    :global {
        .MuiButton-endIcon {
            margin-left: 2px;
        }
    }
}

.menuButton.inheritedIncludedDisabled {
    width: 84px;
    font-size: 12px;
    background-color: #fff;
    padding: 2px 4px 2px 6px;
    &:global(.MuiButton-outlined.Mui-disabled) {
        border: 2px solid $text-color-blue;
    }
    &:global(.MuiButton-root.Mui-disabled) {
        color: $text-color-blue;
        opacity: 0.4;
    }
    .arrowIcon {
        margin-right: 0;
        height: 25px;
        font-size: 18px;
        fill: $text-color-blue;
    }
    :global {
        .MuiButton-endIcon {
            margin-left: 2px;
        }
    }
}

.deviceIcon {
    width: 18px;
    height: 18px;
    margin-left: 8px;
    fill: $text-color-black;
}

.deviceLabelWrapper.inherited {
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 2px;
    :global(.MuiChip-root) {
        font-size: 10px;
        height: 20px;
        @include font-medium();
        border: 2px solid rgba(144, 160, 175, 0.5);
        color: #1c1e21;
        background-color: #fff;
    }
}

.deviceLabelWrapper.inheritedIncluded {
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 2px;
    :global(.MuiChip-root) {
        font-size: 10px;
        height: 20px;
        @include font-medium();
        border: 2px solid $text-color-blue;
        color: $text-color-blue;
        background-color: #fff;
    }
}

.deviceLabelWrapper.included {
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 2px;
    :global(.MuiChip-root) {
        font-size: 10px;
        height: 20px;
        @include font-medium();
        border: 2px solid $text-color-blue;
        color: #fff;
        background-color: $text-color-blue;
    }
}
