@import 'src/styles/variables';
@import 'src/styles/mixins';

.popper {
    z-index: 1101;
    border-radius: 5px;
}
.paper {
    min-width: 180px;
    max-width: 320px;
    border-radius: 5px;

    ul {
        border-top: 1px solid rgba(144, 160, 175, 0.15);
        border-bottom: 1px solid rgba(144, 160, 175, 0.15);
        padding-top: 0;
        padding-bottom: 0;
        max-height: 290px;
        overflow-y: auto;
        @include listScrollbar();
    }
}

.labelName {
    display: block;
    font-size: 14px;
    font-weight: 500;
    line-height: 1;
    padding: 5px;
    color: rgba(0, 0, 0, 0.6);
    @include ellipsis();
}

.createLabelButtonWrapper {
    :global {
        .MuiButton-root {
            font-size: 14px;
        }
    }
    input::placeholder {
        font-size: 10px !important;
        color: #0f7909;
    }
}

.noOptionsBox {
    text-align: center;
    padding: 15px;
    color: $additional-text-color;
}

.menuItem {
    :global {
        .MuiCheckbox-colorPrimary.Mui-checked:hover,
        .MuiIconButton-root:hover {
            background-color: transparent;
        }
        .MuiCheckbox-root {
            margin-left: -9px;
            svg {
                width: 20px;
                height: 20px;
            }
        }
        .PrivateSwitchBase-root-5 {
            padding: 0 9px;
        }
    }
    .labelBackgroundIcon {
        font-size: 16px;
    }
}
:global(.MuiListItem-gutters) {
    &.menuItem {
        padding: 6px 10px;
    }
}
.selectedItem {
    .labelName {
        color: $primary-text-color;
    }
}
