@import '../../styles/mixins.scss';
@import '../../styles/fonts.scss';

.input_block {
    position: relative;
    @include selectStyle();

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    label {
        display: inline-block;

        &:first-letter {
            text-transform: uppercase;
        }
    }

    input[type='number'] {
        -moz-appearance: textfield;
    }

    input,
    textarea {
        height: auto;
        @include placeholder {
            font-size: 12px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}

.without_margin {
    margin: 0;
}

.select_small {
    min-width: 80px;
}

.select_xs_small {
    min-width: 60px;
    width: min-content;
}

.fullWidth_input {
    width: 100%;
}

.style_for_functionBlock {
    margin-right: 16px;

    input {
        &::placeholder {
            font-size: $field-font-size;
        }
    }
}

.secs_content_type {
    position: relative;

    &:after {
        @include timeInputWithContent('secs');
    }
}

.mins_content_type {
    position: relative;

    &:after {
        @include timeInputWithContent('mins');
    }
}

.hours_content_type {
    position: relative;

    &:after {
        @include timeInputWithContent('hours');
    }
}

.content_of_time_format_style {
    margin-right: 18px;
    font-size: 10px;
}
