@import '../../styles/variables';

.lineProgress {
    position: absolute !important;
    top: -$content-wrapper-padding-top;
    left: 0;
    right: 0;
    z-index: 1200;
}
.lineProgressBox {
    position: relative;
}
