@import '../../../../../styles/variables';

.header {
    @media (min-width: $break-d-burger) {
        padding: 18px 48px 5px !important;
    }
    .deviceGroupCreateBtn {
        &:global(.MuiButtonBase-root) {
            padding: 8px 10px;

            svg {
                font-size: 35px;
            }
        }
    }
    .title {
        margin-bottom: 0;
    }
}
