@import './src/styles/mixins';
@import './src/styles/variables';

.title {
    text-align: center;
}

.creatingCategoryWrapper {
    padding: 0 0 20px 0;
}

.row {
    display: flex;
    justify-content: space-between;

    input {
        border: 1px solid transparent;
        background-color: transparent;

        &.inputEdit {
            border-color: $light-gray;
        }
    }

    .button {
        margin: 10px 0 5px 0;
        @include meshbotButton;

        &.disabled {
            background-color: $button-disabled;
            color: $text-color-disabled;
        }
    }

    .inputWrapper {
        width: 100%;
        max-width: 300px;
    }

    .editButtonsWrapper {
        button {
            cursor: pointer;
            width: 60px;
            margin: 5px;
            @include meshbotButton;
            border: none;
            border-radius: 3px 5px;

            &.cancelEditing {
                background-color: $color-white;
                color: $color-blue-3;
                border: 1px solid $color-blue-3;

                &:hover {
                    background-color: $light-gray;
                }
            }

            &.disabled {
                background-color: $button-disabled;
                color: $text-color-disabled;
            }
        }
    }
}

.categoriesTable {
    max-height: 300px;
    overflow: auto;

    .row {
        border-bottom: 1px solid $border-color;
        align-items: center;
    }
}
