@import '../../../../../../../../../styles/variables';
@import '../../../../../../../../../styles/mixins';

.button_wrapper {
    margin-right: 25px;
    margin-bottom: 5px;

    :global {
        .MuiButton-root {
            display: flex;
            align-items: flex-end;
            height: 44px;
            padding: 0 25px 4px 0;
            background-color: transparent;
            box-shadow: none;
            border-bottom: 2px solid rgba($text-color-grey, 0.5);
            border-radius: 0;
            text-transform: capitalize;
            &:active,
            &:hover,
            &:focus {
                background-color: transparent;
                box-shadow: none;
                border-bottom-color: $color-primary-theme;
            }

            :global {
                .MuiSvgIcon-root {
                    color: $meshbot-icon-color;
                    right: 0;
                    position: absolute;
                    font-size: 24px;
                    pointer-events: none;
                }
                .MuiTouchRipple-root {
                    display: none;
                }
            }
        }
    }
}

.select_icon {
    width: 20px;
    height: 20px;
    margin-right: 5px;
}

.title_container {
    display: inline-block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.title {
    font-size: $field-font-size;
    vertical-align: middle;
}
