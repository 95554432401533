@import './src/styles/variables';
@import './src/styles/mixins';

.versionCell {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;

    .upgradeBtn {
        margin: 5px 5px 0 5px;
        cursor: pointer;
    }

    .upgradeBtn:hover + .tooltipTextUpgrade {
        visibility: visible;
    }

    .tooltipTextUpgrade {
        background-color: $color-white;
        border: 1px solid $color-black;
        font-size: 10px;
        top: 20px;
        word-break: break-word;
        z-index: 1;
        position: absolute;
        right: -70px;
        line-height: 15px;
        padding: 5px;
        text-align: center;
        visibility: hidden;
    }
}
