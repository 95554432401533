@import '../../../styles/fonts';
@import '../../../styles/variables';

.contentContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: auto;
    padding: 24px;
    max-width: 684px;
    height: 100%;
    @include font-regular();
}
.formContainer {
    max-width: 392px;
}

.language {
    position: absolute;
    top: 31px;
    display: flex;
    width: 500px;
    justify-content: right;
    right: 32px;

    @media (max-width: $break-d-l) {
        width: 420px;
        right: 32px;
    }

    @media (max-width: $break-d-s) {
        width: 350px;
        right: 25px;
    }

    @media (max-width: $break-m-l) {
        width: 220px;
        right: 20px;
    }

    @media (max-width: $break-m-s) {
        width: 170px;
    }

    @media (max-width: $break-m-xxs) {
        width: 150px;
        right: 10px;
    }
}
