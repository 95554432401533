@import '../../../../../styles/variables';
@import '../../../../../styles/mixins';
@import '../../../../../styles/fonts';

.back {
    padding: 0;
    color: $text-color-basic-black;

    svg {
        cursor: pointer;
    }
}

.integrationName {
    padding: 0 0 0 28px;
    display: inline-block;
    font-size: 24px;
    font-weight: 600;
    line-height: 28.8px;
}

.selectController {
    font-size: 18px;
    @include font-medium();
    line-height: 22px;
    color: $text-color-black;
    margin: 45px 50px 40px;
}

.configureContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 16px;
    border: $border-style;
    margin: 40px 50px;
    padding: 30px 20px;

    .title {
        font-size: 18px;
        @include font-medium();
        margin: 0 0 30px 0;
        color: $text-color-black;
    }

    .selectController {
        margin: 0 0 0 0;
    }

    :global {
        .MuiInput-formControl {
            margin-top: 3px;
        }

        .MuiSelect-root {
            width: 395px;
        }
    }

    .createBtn {
        margin-top: 40px;
        width: 372px;
    }
}

.manuallyTitle {
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    margin: 40px 0;
    color: $text-color-basic-black;
}

.configureInputs {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .inputField {
        @media screen and (min-width: 1700px) {
            min-width: 600px;
            :global {
                .MuiSelect-root {
                    width: 555px;
                }
            }
        }
    }

    input {
        width: 400px;
        max-width: 400px;
    }

    p {
        font-size: 13px;
        font-weight: 500;
        line-height: 15.6px;
    }

    input {
        font-size: 16px;
        font-weight: 400;
        line-height: 23.04px;
        padding: 0 0 11px 0;
    }
}

.btn {
    width: 372px;
    border-radius: 4px;
    font-size: 16px;
    @include font-medium();
    height: 40px;
    color: $color-white;
}

:global {
    .slick-slider {
        margin: 0 50px;
    }

    .slick-slider .slick-list .slick-track .slick-slide div {
        display: flex !important;
        height: 100%;
        align-items: center;
    }

    .slick-list {
        height: 80px;
    }

    .slick-prev:before {
        background: url('../../../../../assets/icons/controls/ic_controls_arrow-left_56/ic_controls_arrow-left_56.svg')
            50% 50%;
        background-size: cover;
        content: '';
        width: 10px;
        height: 15px;
        display: inline-block;
    }

    .slick-next:before {
        background: url('../../../../../assets/icons/controls/ic_controls_arrow-right_56/ic_controls_arrow-right_56.svg')
            50% 50%;
        background-size: cover;
        content: '';
        width: 10px;
        height: 15px;
        display: inline-block;
    }

    .slick-slide {
        max-width: 200px;
        padding: 16px 16px 16px 22px;
        color: $text-color-basic-black;
        cursor: pointer;
        border: $border-style;
        margin: 0 20px 0 0;
        border-radius: 16px;
        height: 80px;
        @media screen and (max-width: 720px) {
            margin: 0 20px 20px 0;
            padding: 10px;
        }

        circle {
            stroke: $text-color-basic-gray;
        }

        path {
            fill: $text-color-basic-black;
        }

        &.offline {
            cursor: auto;
        }
    }

    .slick-current {
        color: $primary-text-color;
        border-color: $primary-text-color;

        circle {
            stroke: $primary-text-color;
        }

        path {
            fill: $primary-text-color;
        }
    }
}

.notFoundControllers {
    font-size: 20px;
    margin: 40px auto;
    text-align: center;
    max-width: 600px;
}

.configureTableWrapper {
    border-radius: 16px;
    border: $border-style;
    margin: 40px 50px;
    padding: 30px 20px;

    .title {
        font-size: 18px;
        @include font-medium();
        margin: 0 0 30px 0;
        color: $text-color-black;
    }

    .selectController {
        margin: 0 0 0 0;
    }

    :global {
        .MuiInput-formControl {
            margin-top: 3px;
        }

        .MuiSelect-root {
            width: 390px;
        }
    }
}
