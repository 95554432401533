@import '../../../../../styles/mixins';

.modalPluginInstallController {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #c7cfd7;
    border-radius: 12px;
    padding: 5px 6px 5px 16px;
    margin: 0 0 5px 0;

    .modalPluginInstallGroup {
        @include flexBox();
    }

    button {
        border-radius: 8px;
        width: auto;
        height: 40px;
        box-shadow: none;

        .iconInstall {
            display: block;
            margin: 0 10px 0 0;

            svg {
                display: block;
                height: auto;
                width: auto;
            }
        }
    }

    .installedPluginWrapper {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        button {
            margin: 0 5px;
        }

        .upgradeBtn {
            background: $color-upgrade-button;
            color: $color-white;
        }

        .upgradeBtnDisabled {
            background: $color-button-grey;
            color: $text-color-grey;
        }

        .pluginVersion {
            display: inline-block;
            margin: 0 10px 0 0;
        }
    }
}
