@import '../../../../styles/variables';
@import '../../../../styles/mixins';
@import '../../../../styles/fonts';

.integration {
    position: relative;
    min-height: 180px;
    border: $border-style;
    border-radius: 16px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    cursor: pointer;
    list-style: none;

    &:hover {
        box-shadow: 0 6px 30px rgba(0, 0, 0, 0.08);
    }

    .integrationImgEmpty {
        width: 25px;
        height: 25px;
        display: block;
    }

    .cloudRow {
        background-position: 50% 50%;
        background-size: 90px;
        background-repeat: no-repeat;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0;
        position: relative;
        flex: 1;
    }

    .row {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0;
        position: relative;
        flex: 1;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 50%;
        border-radius: 16px 16px 0 0;

        button {
            padding: 0;
        }

        svg {
            fill: $text-color-grey-basic;
        }
    }

    .rowType {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0;
        position: relative;
        height: 50px;
        padding: 12px 12px 8px 12px;

        svg {
            fill: $text-color-grey-basic;
            width: 12px;
            height: 12px;
        }

        .manage {
            cursor: pointer;
            padding: 0;
        }

        .integrationTypeIcon {
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
            width: 100%;

            span {
                padding: 0 0 0 7px;
                font-size: 12px;
                line-height: 12px;
                font-weight: 500;
            }

            span:first-letter {
                text-transform: uppercase;
            }
        }

        .devicesCount {
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
            width: 100%;
            margin: 6px 0 0 0;

            span {
                padding: 0 0 0 8px;
                font-size: 12px;
                line-height: 12px;
                font-weight: 500;
            }
        }

        .integrationName {
            display: block;
            font-size: 16px;
            font-weight: 600;
            flex-wrap: wrap;
            width: 177px;
            word-break: break-word;
            margin: 0 0 0 10px;
            color: $text-color-black;
            @include ellipsis();
        }
    }

    .noIcon {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;

        svg {
            display: inline-block;
            margin: 30px 0 0 0;

            path {
                fill: #90a0af80;
            }
        }
    }
}

.addNewIntegrationItem {
    background-color: $color-tooltip-background;
    height: 180px;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: $text-color-grey;
    cursor: pointer;

    &:hover {
        box-shadow: 0 6px 30px rgba(0, 0, 0, 0.08);
    }

    span {
        @include font-semibold();
        font-size: 16px;
        display: inline-block;
        margin: 10px 0 0 0;
    }

    svg {
        width: 32px;
        height: 32px;

        path {
            fill: $text-color-grey;
        }
    }
}

.prefixWrapper {
    border-radius: 15px;
    position: absolute;
    top: 8px;
    left: 8px;
    font-size: 10px;
    @include font-medium();
    padding: 3px 8px;

    &.certified {
        background-color: $color-select-button-blue;
        color: $color-white;
    }

    &.community {
        background-color: $main-color-color-green;
        color: $color-white;
    }
}
