.limitInfoSection {
    font-size: 16px;
    display: flex;
    justify-content: flex-end;
    align-items: end;
    flex: 1 1 auto;
    padding-bottom: 30px;
    svg {
        width: 20px;
        cursor: pointer;
    }
    span {
        margin-left: 10px;
    }
}
