@import '../../../../../styles/mixins.scss';

.wrapper {
    .select {
        @include selectStyle();
        :global {
            .MuiInputBase-input {
                width: auto;
            }
        }
    }
}

.list {
    display: flex;
    align-items: center;
    gap: 10px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: 'nowrap';
    span {
        font-size: 12px;
    }
}

.popper {
    min-width: 290px !important;
    margin-left: 35px;
}

.cut_text {
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 20em;
    height: 1.2em;
    white-space: nowrap;
}

.field_container {
    padding-bottom: 10px;
}
