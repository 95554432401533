.root {
    display: flex;
    padding-top: 30px;
    flex-direction: column;
    font-family: 'Ezlo Sans';
}

.title {
    display: flex;
    flex-direction: row;
    margin: 10px 0;
    align-items: center;
    margin-bottom: 20px;

    .titleText {
        font-weight: bold;
        margin-right: 10px;
        width: 290px;
    }
}

.picker-component {
    margin-top: 0 !important;

    &,
    & > div {
        width: 400px !important;
        height: 400px !important;
    }
}

.imgPortrait,
.imgLandscape {
    margin-top: 20px;
    max-width: 100%;
    margin-top: 20px;
    margin-left: 20px;
}

.imgPortrait {
    height: auto;
    width: 13%;
}

.imgLandscape {
    width: 50%;
    height: auto;
}

@media (max-width: 920px) {
    .title {
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        margin-bottom: 20px;
        margin-top: 20px;
    }
    .colorValue {
        margin-top: 12px;
        margin-bottom: 15px;
    }

    .picker-component,
    .imgPortrait,
    .imgLandscape {
        margin-top: 25px;
        margin-bottom: 25px;

        width: 80%;
    }
}

.colorValue {
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid gray;
    margin-right: 15px;
    border-radius: 5px;
    padding: 6px;
    cursor: pointer;

    .colorBox {
        width: 20px;
        height: 20px;
        margin-right: 10px;

        border-radius: 50%;
    }

    button {
        border: none;
        background-color: transparent;
        font-weight: bold;
        cursor: pointer;
        padding: 0;

        &:focus {
            outline: none;
        }
    }
}
