@import '../../../../styles/variables';
@import '../../../../styles/fonts';
@import '../../../../styles/mixins';

.headerButton {
    width: 340px;
    height: 40px;
    padding: 11px;
    margin: 30px 0 0 0;
    border-radius: 4px;

    span {
        font-size: 14px;
        line-height: 18px;
        font-weight: 500;
        display: block;
    }

    svg {
        width: 24px;
        height: 24px;
        fill: $color-message-information-background;
        margin: 0 10px;
    }
}

.messageInformation {
    padding: 20px 20px;
    background-color: $color-message-information-background;
    text-align: center;
    color: $text-color-black;

    p {
        font-size: 2rem;

        &:last-of-type {
            margin-bottom: 0;
        }
    }
}

.infoBlock {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    text-align: center;

    .imgWrapper {
        margin: 0 auto;
        width: 190px;
        height: 190px;
    }

    h3 {
        text-align: center;
        font-size: 32px;
        color: $text-color-black;
        font-weight: 600;
    }

    p {
        font-size: 15px;
    }

    :global {
        .confirmation-modal-content {
            position: static;
            transform: none;
            padding: 0;

            .close-confirmation-modal-btn {
                display: flex;
                justify-content: flex-start;
                cursor: pointer;

                svg {
                    width: auto;
                }
            }
        }
    }
}

.buttonsBlock {
    display: flex;
    justify-content: center;
    margin: 30px 0 0 0;
}

.modalWrapper {
    position: absolute;
    min-width: 200px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: $color-white;
    padding: 20px 15px 20px;
    border-radius: 20px;

    svg {
        width: 100%;
    }
}

.closeBtn {
    display: flex;
    justify-content: flex-start;
    margin: 0;

    svg {
        cursor: pointer;
        width: 22px;
        height: 20px;
    }
}

.integrationWrapper {
    @extend .modalWrapper;
    width: 940px;
}

.emptyPage {
    margin-top: 70px;
    overflow-y: auto;
    width: 100%;
}

.search_popover__wrapper {
    @include listScrollbar();
    overflow-y: auto;
    padding: 0 20px;
    @media screen and (max-height: 1200px) {
        max-height: 300px;
    }
}

.search_popover__input {
    position: relative;
    background-color: $color-white;

    :global {
        .MuiInputBase-root {
            position: sticky;
            background-color: #fff;
            top: 0;
            z-index: 9999;
            width: 100%;

            input {
                padding: 10px 10px 10px 23px;

                &::placeholder {
                    color: $text-color-dark-grey;
                }
            }
        }

        .MuiPaper-elevation1 {
            box-shadow: none;
        }
    }
}

.paas__integrations_list {
    margin-top: 20px;
    width: 100%;
    grid-gap: 10px;
    gap: 10px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
}

.search_popover__input_icon {
    position: sticky;
    bottom: calc(100% - 30px);
    left: 0;
    width: 30px;
    z-index: 9999;

    svg {
        font-size: 20px;
    }
}

.auth_explanation {
    font-size: 16px;
    color: $color-black;
    word-break: break-word;
    line-height: 23.04px;
    font-weight: 400;
    margin: 0;
}

.integrationsModalWrapper {
    position: absolute;
    min-width: 200px;
    max-width: 483px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: $color-white;
    padding: 20px 15px 20px;
    border-radius: 20px;

    svg {
        width: 100%;
    }
}
