@import '../../../../../../../styles/variables.scss';
@import '../../../../../../../styles/mixins.scss';

.output {
    margin-top: 30px;
}

.outputRow {
    display: flex;
    margin-bottom: 10px;
    position: relative;
}

.checkboxBlock {
    display: flex;
    align-items: center;
    justify-content: center;

    :global {
        .MuiCheckbox-root {
            padding: 0;
            margin-right: 5px;
        }
    }
}

.outputTitle {
    font-size: 16px;
    margin: 20px 0;
}

.codeField {
    outline: none;
    border: 1px solid rgba(144, 160, 175, 0.25);
    width: 300px;
    position: relative;
    border-radius: 10px;
    resize: vertical;
    padding: 15px;

    &::placeholder {
        color: rgba(144, 160, 175, 0.25);
        font-size: $field-font-size;
        position: absolute;
        top: 50%;
        left: 20px;
        transform: translateY(-50%);
    }
}

.topBlock {
    @include flexBox();
    gap: 50px;
    margin-bottom: 10px;
}

.itemWrapper {
    margin-bottom: 15px;
    padding: 20px;
    background-color: #ececec;
    border: 1px solid #b4b4b4;
    border-radius: 10px;
}

.itemBlock {
    display: flex;
    gap: 20px;
    margin-bottom: 10px;

    :global {
        .MuiAutocomplete-root {
            label {
                padding-top: 0;
            }
        }
    }
    > div {
        flex: 1;
    }
}

.testButtonBlock {
    @include flexBox(inherit, flex-start);
    gap: 20px;
}
.runButton {
    margin-bottom: 10px;
}

.resultBlock {
    display: flex;
    gap: 20px;
    margin: 0 0 8px 0;
}

.checkboxBlock {
    display: flex;
    align-items: center;
}

.mainBlock {
    width: 100%;
}

.switchBlock {
    :global {
        .MuiToggleButtonGroup-root {
            gap: 5px;
        }
        .MuiToggleButton-root {
            border-color: $color-button-blue;
            color: $color-button-blue;
            &:not(:first-child) {
                border-left-color: $color-button-blue;
            }
            &.Mui-selected {
                background-color: $color-button-blue;
                color: $color-white;

                &:hover,
                &:focus {
                    background-color: $color-button-blue;
                    color: $color-white;
                }
            }
            &.Mui-disabled {
                opacity: 0.5;
                color: $color-button-blue;
            }
        }
    }
}

.positionBlock {
    display: flex;
    gap: 20px;
}

.variableBlock {
    display: flex;
    flex-direction: column;
    gap: 5px;

    :global {
        .MuiInputBase-root {
            background-color: $color-white;
        }
        .MuiButton-root {
            font-size: 12px;
        }
        .MuiFormLabel-root {
            font-size: 14px;
        }
    }
}

.deleteBtn {
    width: 40px;
    height: 40px;
    svg {
        width: 25px;
        height: 25px;
    }
}

.table {
    width: 100%;
    :global {
        .MuiTableContainer-root {
            overflow: hidden;
        }
        .MuiTableCell-root {
            font-size: 14px;
        }
        .MuiPaper-elevation1 {
            box-shadow: none;
        }
    }
    svg {
        width: 24px;
        height: 24px;
    }
}

.progress {
    color: rgba($color-black, 0.12);
    margin-right: 10px;
}

.runTextResultBlock {
    :global {
        .MuiOutlinedInput-root {
            padding: 0;
            background-color: $color-white;
        }
        .MuiInputBase-input {
            padding: 12px 14px;
            color: $text-color-black;
            resize: both;
            font-size: 14px;
            @include inputScrollbar();
        }
    }
}

.runTextResultBlockDisabled {
    :global {
        .MuiInputBase-input {
            resize: none;
            min-height: 16px;
            width: 150px;
        }
    }
}

.wrapper {
    width: 100%;
    margin-top: 10px;
}

.error {
    align-self: center;
    color: $color-inValid;
}

.errorBlock {
    margin-top: 10px;
    @extend .error;
}

.option {
    font-size: 14px;
}

.variableDialogTitle {
    :global {
        .MuiTypography-h6 {
            @include font-regular();
            font-size: 24px;
        }
    }
}
.dialogBox {
    :global {
        .MuiDialogActions-root {
            padding: 10px 24px 16px;
        }
        .MuiButton-root {
            min-width: 100px;
        }
    }
}
.variablesChipBox {
    margin: 3px;
    :global {
        .MuiChip-root {
            @include font-regular();
            font-size: 11px;
        }
    }
}
.variablesChipsBox {
    display: flex;
    flex-wrap: wrap;
    margin: 7px 0;
}
.testVariablesContainer {
    margin: 15px 0 0 20px;
}
.variablesListTitle {
    @include font-regular();
    font-size: 16px;
    justify-content: start;
    margin-right: 15px;
}
.variableDialogHeader {
    position: relative;
    width: 100%;
    :global {
        .MuiDialogTitle-root {
            padding-right: 60px;
        }
    }
}
.closeButtonBox {
    position: absolute;
    top: 10px;
    right: 10px;
    svg {
        width: 16px;
        height: 16px;
    }
}
.useVarCheckboxBlock {
    margin-top: 8px;
    :global {
        .MuiTypography-body1 {
            font-size: 16px;
            @include font-regular();
        }
    }
    svg {
        width: 24px;
        height: 24px;
    }
}
