@import '../../styles/fonts';
@import '../../styles/variables';

.logo {
    position: absolute;
    top: 31px;
    left: 32px;
    display: flex;
    @media (max-width: 475px) {
        display: none;
    }

    &__icon {
        width: 180px;
        height: $navigation-logo-height;
    }

    &__version {
        color: $text-color-dark-grey;
        line-height: 1.4;
        font-size: 16px;
        @include font-normal();
        margin-left: 8px;
    }
}

.login-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 15px;
}

.customized-logo {
    width: 180px;
    height: 100px;
    position: relative;
    overflow: hidden;
}
.customized-logo img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

// TODO: avoid copy/paste, let's try to keep logo styles in a single place
//@media screen and (max-width: 768px) {
//    .logo {
//        display: none;
//    }
//}

.language {
    position: absolute;
    top: 31px;
    display: flex;
    width: 500px;
    justify-content: right;
    right: 32px;

    @media (max-width: $break-d-l) {
        width: 420px;
        right: 32px;
    }

    @media (max-width: $break-d-s) {
        width: 350px;
        right: 25px;
    }

    @media (max-width: $break-m-l) {
        width: 220px;
        right: 20px;
    }

    @media (max-width: $break-m-s) {
        width: 170px;
    }

    @media (max-width: $break-m-xxs) {
        width: 150px;
        right: 20px;
    }
}

.link,
.link:hover {
    @include font-medium();
    font-size: 16px;
    color: $text-color-blue;
}

.link:hover {
    opacity: 0.8;
}

.link-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 40px;
    text-align: center;

    @media (max-width: $break-m-m) {
        margin-top: 20px;
    }
}

.content-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 600px;
    height: 100%;
    align-items: center;
    margin: 0 auto;
    padding: 200px 0;
    @include font-regular();

    .disabled-pointer-events {
        pointer-events: none;
    }

    .forgot_password__text {
        font-size: 16px;
        text-align: center;

        @media (max-width: $break-m-m) {
            width: 80%;
        }
    }

    h2 {
        @include font-semibold();
        font-size: 32px;
        line-height: 38px;
        text-align: center;
        margin-top: auto;
        margin-bottom: auto;
        margin-right: 6px;

        @media (max-width: $break-m-m) {
            font-size: 28px;
            width: 90%;
        }

        @media (max-width: $break-m-xs) {
            line-height: 25px;
            font-size: 25px;
        }

        @media (max-width: $break-m-xxs) {
            line-height: 25px;
            font-size: 25px;
        }
    }

    @media (max-width: $break-m-m) {
        margin-top: 30px;
    }

    .form-container {
        padding: 24px;
        width: 75%;

        @media (max-width: $break-m-m) {
            width: 90%;
        }

        .form {
            .text-field {
                height: 74px;
                font-size: 18px;
                line-height: 22px;
                @include font-regular();

                @media (max-width: $break-m-xxs) {
                    height: 74px;
                }

                label {
                    font-size: 13px;
                    line-height: 16px;
                }

                input,
                textarea {
                    padding-top: 4px;
                    padding-bottom: 6px;
                }

                .MuiFormLabel-root {
                    @include font-regular();
                    color: $text-color-dark-grey;
                    font-size: 18px;
                    line-height: 22px;
                    transform: translate(0, 20px) scale(1);
                }

                .MuiInputLabel-shrink {
                    transform: translate(0, 0) scale(0.75) !important;
                }

                .MuiInput-underline:before {
                    border-bottom: 2px solid rgba($text-color-grey, 0.5);
                }

                .MuiInput-underline:after {
                    border-bottom: 2px solid $text-color-black;
                }

                .Mui-error:after {
                    border-bottom-color: $text-color-warning;
                }

                .MuiFormHelperText-root {
                    @include font-regular();
                    margin-top: 1px;
                    font-size: 13px;
                    line-height: 16px;
                }

                .MuiInputAdornment-root {
                    padding-bottom: 2px;
                }
            }

            .toggle-icon {
                height: 24px;
                width: 27px;
            }

            .eye {
                height: 20px;
                padding-bottom: 1px;
            }

            .submit__btn {
                margin-top: 22px;
                @include font-normal();
                height: 56px;
                font-size: 16px;
                text-transform: none;
                background-color: $color-primary-button;
                box-shadow: none;

                @media (max-width: $break-m-xxs) {
                    font-size: 15px;
                    margin-top: 5px;
                }
            }
        }
    }
}

.response-dialog {
    .MuiDialogContent-root,
    .MuiDialogContent-root:first-child {
        padding: 40px 40px 80px 40px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    &__text {
        @include font-regular();
        font-size: 16px;
    }

    &__title {
        @include font-medium();
        font-size: 32px;
    }

    &__img {
        img {
            margin-bottom: 20px;
        }
    }

    &__subtext {
        color: #90a0af;
    }
}

.environment {
    display: flex;
    align-items: center;
    margin-top: 10px;

    p {
        font-size: 16px;
        margin: 0;
        padding-right: 10px;
    }
}
