@import '../../../../styles/variables.scss';

.associationList {
    padding-top: 20px;
    font-family: 'Ezlo Sans';
    max-width: 940px;
    *::-webkit-scrollbar-corner {
        background: rgba(0, 0, 0, 0);
    }

    * {
        scrollbar-width: thin;
        scrollbar-color: none #f6f6f6;
    }

    *::-webkit-scrollbar {
        width: 5px;
    }

    *::-webkit-scrollbar-track {
        background: rgba(0, 0, 0, 0);
    }

    *::-webkit-scrollbar-thumb {
        background-color: #c5c5c5;
        border-radius: 20px;
        width: 3px;
        border: 3px solid #c5c5c5;
    }
    @media (max-width: 767px) {
        width: 100%;
    }

    .associationListContent {
        background-color: white;
        box-shadow: none;
        border: 2px solid rgba(144, 160, 175, 0.15);
        border-radius: 16px;
        margin-bottom: 14px;
        max-width: 940px;
        &:global(.Mui-expanded) {
            border: 2px solid #3e46ae;
            border-radius: 16px;
        }
        &:global(.MuiAccordion-root::before) {
            background-color: transparent;
        }
        &:global(.MuiAccordion-root:first-child) {
            border-top-left-radius: 16px;
            border-top-right-radius: 16px;
        }
        &:global(.MuiAccordion-root:last-child) {
            border-bottom-left-radius: 16px;
            border-bottom-right-radius: 16px;
        }
    }

    .deviceName {
        color: rgba(28, 30, 33, 0.5);
        font-size: 12px;
        font-family: 'Ezlo Sans';
    }

    .deviceSource {
        font-size: 18px;
        font-weight: 500;
        font-family: 'Ezlo Sans';
        color: #1c1e21;
        font-style: normal;
        display: flex;
        margin-top: 5px;
    }

    .targetDeviceName {
        margin-left: 10px;
    }

    .associateWithColumn {
        font-family: 'Ezlo Sans';
        font-size: 12px;
        color: #697580;
    }

    .associationListData {
        display: flex;
        flex-direction: column;
        &:global(.MuiCollapse-entered) {
            border-bottom-left-radius: 13px;
            border-bottom-right-radius: 13px;
        }
    }

    .deviceIcon {
        fill: $color-blue-3;
        width: 20px;
        height: 20px;
    }

    .controllerIconWrapper {
        position: relative;

        svg {
            width: 25px;
            height: 25px;
        }

        &:before {
            position: absolute;
            top: -5px;
            left: 20px;
            z-index: 1;
            content: '';
            border-radius: 50%;
            border: 2px solid white;
            box-sizing: content-box;
            width: 6px;
            height: 6px;
            background-color: #6ba543;
        }

        &.controllerOffline {
            background-color: #fe5c15;
        }
    }
    .withControllerSerial {
        color: $text-color-black;
    }
    .createAssociationBtn {
        display: flex;
        color: #3e46ae !important;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px;
        margin-top: 5px !important;
        font-weight: 500;
        font-size: 16px;
        line-height: 18px;
        font-family: 'Ezlo Sans';
        margin-top: 10px;
    }

    .expandIcon {
        font-size: 2.5rem;
    }
}

.tooltip {
    position: absolute;
    z-index: 4;
    min-width: 210px;
    box-shadow: 0 8px 24px rgba(144, 160, 175, 0.3);
    border-radius: 12px;
    padding: 10px;
    font-size: 13px;
    background-color: $color-white;
    color: $text-color-basic-black;
    bottom: 40px;
    p {
        margin: 0;
    }
}

@media screen and (max-width: 767px) {
    .dataDevice {
        flex-direction: column;
    }
}
.headerRow {
    display: flex;
    align-items: center;
    z-index: 0;
}

.devicesRow {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
    border-radius: 10px;
    background: rgba(144, 160, 175, 0.05);
}
.tableContainer {
    &:global(.MuiTableContainer-root) {
        overflow-x: visible;
    }
}
.sourceDeviceColumn,
.targetDeviceColumn,
.associateWithColumn,
.deleteIconColumn {
    flex: 1;
    padding: 10px;
    box-sizing: border-box;
}

.dataDevice {
    display: flex;
}
.targetDeviceName {
    margin-top: 10px;
}

@media (min-width: 768px) {
    .dataDeviceCol {
        flex-direction: row;
        align-items: center;
    }

    .targetDeviceName {
        margin-top: 0;
        justify-content: center;
        align-items: center;
        margin-left: 2px;
    }
}
.content {
    margin-left: 10px;
}
.iconContainer {
    padding-right: 5px;
    margin-top: 3px;
    justify-content: center;
    align-items: center;
}
.accordionSummary {
    &:global(.MuiAccordionSummary-root) {
        border-radius: 13px;
    }
}
