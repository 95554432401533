@import '../../../../../styles/variables';
@import '../../../../../styles/fonts';
@import '../../../../../styles/mixins';

.usersPage {
    margin-top: -30px;
    position: relative;
    header {
        display: flex;
        justify-content: flex-start;
        @media (max-width: $break-d-m) {
            justify-content: left;
            h1 {
                margin-bottom: 5px;
            }
        }
    }
}

.headerTitle {
    font-size: 2.4rem;
    color: $text-color-black;
    @include font-normal();
}

.headerAction {
    display: flex;
    flex: 1 1 auto;
    justify-content: flex-end;
    align-items: center;
    @media (max-width: $break-d-m) {
        width: 100%;
        padding-right: 40px;
    }
    @media (max-width: $break-m-m) {
        padding-right: 10px;
    }
}

.searchField {
    @media (max-width: $break-d-m) {
        width: calc(100% - 200px);
    }
    @media (max-width: $break-m-m) {
        width: calc(100% - 175px);
    }
    :global {
        .MuiSvgIcon-root {
            width: 24px;
            height: 36px;
            color: $text-color-black;
        }
        .MuiInput-root {
            margin: 0 50px;
            min-width: 330px;
            @media (max-width: $break-d-m) {
                margin: 0 20px;
            }
            @media (max-width: $break-t-l) {
                min-width: calc(100% - 200px);
            }
            @media (max-width: $break-m-m) {
                margin: 0 20px 0 0;
            }
        }
        .MuiInputBase-root .MuiInputBase-input {
            line-height: 22px;
            font-weight: 500;
        }
    }
}

.tableContainer {
    padding: 100px 40px 40px;
    @media (max-width: $break-d-m) {
        padding-top: 110px;
    }
    @media (max-width: $break-m-m) {
        padding: 100px 0 40px;
    }
    :global {
        .MuiTableContainer-root {
            height: calc(100vh - 202px);
            @include listScrollbar();
            @media (max-width: $break-d-m) {
                height: calc(100vh - 190px);
            }
        }
        .MuiTableCell-root {
            font-size: 14px;
            padding: 6px 15px;
            @include font-medium();
            height: 60px;
        }
        .MuiTablePagination-root,
        .MuiTypography-body2 {
            font-size: 14px;
            @include font-regular();
        }

        .MuiTableBody-root:first-child {
            border-top: 1px solid $table-border-color;
        }

        .MuiTableRow-root {
            position: relative;
        }
        .MuiSvgIcon-root {
            font-size: 24px;
        }
    }
}
.buttonCell {
    width: 140px;
}

.pinCodeBtn {
    min-width: 120px !important;
    span {
        @include font-medium();
        font-size: 14px;
        text-transform: capitalize;
    }
}
.changePasswordBtn {
    min-width: 160px !important;
    div {
        @include font-medium();
        font-size: 14px;
        text-transform: capitalize;
        flex-grow: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
.iconCell {
    width: 47px;
    padding: 0 !important;
}
.loginIcon {
    margin-right: 8px;
}
.loginIconHidden {
    opacity: 0;
}
.loginIconVisible {
    opacity: 1;
}
.tableCellBox {
    display: flex;
    align-items: center;
}
.headerIconCell {
    width: 50px;
}
.cellWithFirstName {
    &:global(.MuiTableCell-root) {
        padding-left: 0;
    }
}
