.galleryHeader {
    min-height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    h1 {
        margin-bottom: 0;
    }

    @media (max-width: 900px) {
        flex-direction: column;
    }
}

.galleryActionsBox__right,
.galleryActionsBox__left {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;

    button {
        margin: 0 2px;
    }
    div {
        margin-right: 10px;
        display: flex;
        @media (max-width: 446px) {
            margin-bottom: 5px;
            margin-right: 0;
        }
    }

    @media (max-width: 446px) {
        flex-direction: column;
    }
}

.galleryActionsBox__right {
    display: flex;
    justify-content: end;
    @media (max-width: 446px) {
        flex-direction: column-reverse;
    }
}
