@import '../../../../../styles/variables';
@import '../../../../../styles/mixins';

.input {
    width: 400px;
    .MuiInputBase-input {
        padding: 14px 18px;
    }
}

.buttons {
    text-align: right;
    padding-bottom: 30px;
}

.textarea {
    margin: 20px 0 !important;
    width: 100%;
}

.button {
    font-size: 14px;
    &:not(:last-child) {
        margin-right: 15px;
    }
}

.buttonWhite {
    &:global(.MuiButtonBase-root) {
        background-color: $color-white;
        color: $primary-text-color;
    }
    &:global(.MuiButton-containedPrimary) {
        &:hover {
            background-color: $color-white;
        }
    }
}

.MuiOutlinedInput-notchedOutline {
    legend {
        font-size: 10px;
    }
}

.scriptWrapper {
    position: relative;
    padding-top: 10px;
}

.backBtn {
    top: -38px;
    left: -7px;

    svg {
        font-size: 30px;
        color: $text-color-black;
        transform: translateX(20%);
    }

    &:global(.MuiButtonBase-root) {
        position: absolute;
    }
}
