@import '../../../../../styles/fonts';
@import '../../../../../styles/variables';
@import '../../../../../styles/mixins';

.empty-meshbots {
    height: 75%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .empty-meshbots__icon {
        width: 260px;
        height: 260px;
    }

    .empty-meshbots__title {
        @include font-normal();
        font-size: 32px;
        text-align: center;
        font-weight: 600;
        color: #1c1e21;
    }

    .empty-meshbots__text {
        @include font-normal();
        font-size: 16px;
        text-align: center;
        font-weight: 400;
        color: #000000;
    }
}
