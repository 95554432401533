@import 'src/styles/variables';

.progressBar {
    top: -30px;
    width: 100%;
    z-index: 9999;
}

.wrapper {
    .listWrapper {
        display: flex;
        flex-wrap: wrap;
        padding: 30px;

        img,
        svg {
            width: 40px;
            height: 40px;
            max-width: none;
        }
    }

    .titleWrapper {
        display: flex;
        margin: 0 0 55px 0;
        justify-content: flex-start;
        align-items: center;

        h1 {
            margin: 0 0 0 28px;
            font-size: 32px;
            font-weight: 600;
            line-height: 38px;
            letter-spacing: -0.015em;
        }

        svg {
            cursor: pointer;
        }
    }

    .integrationsCount {
        font-size: 13px;
        line-height: 15.6px;
        color: $integrations-count;
        text-align: center;
        margin: 30px 0 0 0;
    }
}

.integrationCardWrapper {
    max-width: 380px;
    min-width: 330px;
    min-height: 180px;
    border: $border-style;
    border-radius: 16px;
    margin: 0 0 24px 0;
    padding: 16px;
    word-break: break-word;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    position: relative;
    @media screen and (max-width: 1370px) {
        min-width: 280px;
    }

    div {
        display: flex;
        align-items: center;

        div {
            display: block;

            p {
                font-size: 12px;
            }

            p + p {
                font-size: 10px;
            }
        }
    }

    button {
        display: none;
    }
}
