@import '../../../../../styles/variables';
@import '../../../../../styles/fonts';
@import '../../../../../styles/mixins';

.container {
    header {
        display: flex;
        justify-content: flex-start;

        @media (max-width: $break-d-m) {
            justify-content: left;
            h1 {
                font-size: 14px;
            }
        }
    }
}

.accountSectionWrapper {
    padding: 50px 0px 0px 40px;
    width: 100%;
}
