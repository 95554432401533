@import 'src/styles/variables';

.changelogWrapper {
    height: 200px;

    .btn {
        color: $link-color;
        background-color: transparent;
        border: none;
        font-weight: bold;
        font-size: 16px;
        cursor: pointer;
        padding: 0 0 5px 0;
        text-decoration: underline;
    }

    .title {
        font-size: 16px;
        font-weight: bold;
    }

    .listWrapper {
        overflow-y: auto;
        height: 170px;

        p {
            margin-bottom: 0;
        }

        span:first-child {
            padding: 0 15px 0 0;
        }
    }

    .descriptionWrapper {
        margin-bottom: 5px;
    }
}
