@import '../../styles/mixins.scss';

.menuItem {
    :global {
        .MuiTypography-body1 {
            font-size: 14px;
            font-family: inherit;
        }

        .MuiListItemIcon-root {
            min-width: 40px;
        }
    }
}

.area {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    margin-top: 5px;

    :global {
        .MuiFormControl-root {
            flex-direction: row;
        }

        .MuiInput-root {
            width: 100%;
        }

        .MuiInput-multiline {
            textarea {
                @include inputScrollbar;
                overflow: auto !important;
                resize: vertical;
                max-height: 100vh;
            }
        }
    }
}

.subject {
    display: flex;
    flex-direction: row;
    margin-bottom: 5px;
    margin-top: 5px;
    max-width: 450px;
    width: 100%;

    :global {
        .input-block {
            max-width: 450px;
            width: 100%;
            margin-right: 0;
        }
    }
}

.label {
    width: 70px;
    padding-right: 20px;
}

.body_wrapper {
    display: flex;
    flex-direction: row;
    margin-bottom: 5px;
    margin-top: 5px;
    max-width: 450px;
    width: 100%;

    :global {
        .MuiOutlinedInput-multiline {
            padding: 18.5px 17px;
        }

        .input-block {
            max-width: 450px;
            width: 100%;
            margin-right: 0;
        }
    }
}

.body_label {
    width: 70px;
    align-self: flex-start;
    margin-top: 5px;
    padding-right: 20px;
}

.button_wrapper {
    .buttonPreview {
        margin-top: 20px;
        margin-bottom: 10px;
        @include meshbotAdditionButton;
    }
}
