@import '../../../../../../styles/fonts';
@import '../../../../../../styles/variables';

.contentContainer {
    padding: 50px 0;
    min-width: 390px;
}

.fieldsContainer {
    display: flex;
    align-items: end;
}

.colorInput {
    margin-left: 15px;
    margin-bottom: -4px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 36px;
    height: 32px;
    border: none;
    background-color: transparent;
    cursor: pointer;

    &::-webkit-color-swatch {
        border: 1px solid #90a0af80;
        border-radius: 2px;
    }
}

.previewLabelContainer {
    display: flex;
    margin-bottom: 20px;
    align-items: center;
    font-size: 16px;
    color: $text-color-black;
    span {
        color: $text-color-grey-basic;
    }
    div,
    span {
        margin-right: 6px;
    }
}
