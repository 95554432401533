@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.integration {
    @include flexBox(flex-start, center);
    max-height: 50px;
    border-radius: 4px;
    background-color: $color-white;
    cursor: pointer;
    padding: 8px;
    font-size: 16px;
    font-weight: 600;
    box-shadow: 0px 4px 10px rgba($text-color-blue, 0.1);
    position: relative;
    transition: 0.3s;

    &:hover {
        box-shadow: 0px 4px 10px rgba($text-color-blue, 0.5);
    }
}

.integrationImgEmpty {
    width: 25px;
    height: 25px;
    display: block;
}

.help_icon {
    position: absolute;
    top: 9px;
    right: 0px;
    z-index: 1000;
    font-size: 12px;
}

.integration__content {
    display: flex;
    align-items: center;

    img,
    svg {
        max-width: 25px;
        margin-right: 5px;
    }

    p {
        margin: 0;
    }
}

.description {
    background: $color-tooltip-background;
    width: 100%;
    padding: 5px;
}

.name {
    font-size: 14px;
    margin: 0;
    padding: 0;
}

.title {
    color: $text-color-grey;
    margin: 0;
    font-size: 10px;
    padding: 0;
    width: 100px;
}

.integration_large {
    width: 290px;
}

.description_large {
    padding: 10px 5px;
}
