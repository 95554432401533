@import 'src/styles/variables';

.noControllers {
    font-size: 20px;
    text-align: center;
}

.modal {
    width: 500px;
    position: relative;
    padding: 10px;

    h2,
    h3 {
        font-weight: bold;
    }

    .title {
        font-size: 22px;
        border-bottom: 1px solid $color-button-grey-1;
        padding-bottom: 8px;
        margin-bottom: 5px;
    }

    .group {
        display: flex;

        section {
            flex: 1;
        }
    }

    .label {
        font-size: 16px;
    }

    .section {
        min-width: 40%;
        padding-right: 30px;
    }

    .empty {
        text-align: center;
        padding: 40px 0;
    }

    li {
        font-size: 14px;
        margin-bottom: 4px;

        span {
            font-weight: bold;
            display: inline-block;

            &:first-letter {
                text-transform: uppercase;
            }
        }
    }
}

.buttonWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
}
