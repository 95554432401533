@import "../../../../../styles/variables";

.device-alarm__title {
    font-size: 1.4rem;
    text-transform: capitalize;
    margin-bottom: 0;
    line-height: 0.8;
}

.device-alarm__status {
    font-size: 1.1rem;
    color: gray;
}

.device-alarm-info__status {
    font-size: 1.6rem;

    svg {
        fill: #ccc !important;
    }

    .device-alarm-info__tooltip {
        span {
            font-weight: 600;
        }
    }
}

.device-settings-row {
    border-bottom: 1px solid #ccc;
    border-top: 1px solid #ccc;
    padding-top: 7px;
    padding-bottom: 7px;
}

.device-configurator__box {
    max-width: 350px;

    .form-input {
        margin-bottom: 10px;
    }

    .device-settings-row {
        margin-bottom: 10px;
    }
}

.device-alarm__text {
    font-size: 1.5rem;
    color: #393939;
    margin-bottom: 20px;
}

.device-range-slider__box {
    position: relative;
    max-width: 300px;
    margin-bottom: 20px;
    padding-bottom: 10px;
}

.device-range-slider {
    width: 100%;
}
.device-alarm__wrapper {
    margin-bottom: 20px;
}

.device-range__button {
    background-color: #e3e3e3;
    border: 0;
    margin-right: 20px;
}

.device-range-footer {
    margin-bottom: 10px;
}

.device-range-restore {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
}

.device-range-start {
    position: absolute;
    left: 0;
    bottom: 0;
}

.device-range-finish {
    position: absolute;
    right: 0;
    bottom: 0;
}

.device-alarm__block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    max-width: 300px;
    padding-bottom: 3px;
    border-bottom: 1px solid #cccccc;
    margin-bottom: 20px;
}

.device-alarm__item {
    margin: 20px 0;
}

.device-alarm__key {
    color: #575D70;
    font-size: 17px;
}

.device-alarm__value {
    color: $additional-text-color;
    font-size: 18px;
    font-weight: 600;
}

.ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content {
    overflow: inherit;
}