@import '../../../../../styles/variables';
@import '../../../../../styles/mixins';

.headerContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1 1 auto;
    h1 {
        @media (max-width: $break-d-xx) {
            width: 100%;
            text-align: center;
        }
        @media (max-width: $break-t-s) {
            text-align: start;
        }
    }
}
.listHeaderButtons {
    text-align: right;
    button {
        height: 5.6rem;
        font-family: $ezloSans;
        font-size: 16px;
        font-weight: 500;
        text-transform: initial;
        white-space: nowrap;
        @media (max-width: $break-t-m-expression) {
            height: 4.6rem;
            padding: 6px 10px 6px 6px;
            svg {
                width: 18px;
            }
        }
        @media (max-width: $break-m-m) {
            font-size: 14px;
            svg {
                display: none;
            }
        }
        @media (max-width: $break-m-s) {
            font-size: 0;
            svg {
                display: flex;
            }
            :global(.MuiButton-startIcon) {
                margin: 0;
            }
        }
    }
    svg {
        width: 24px;
        height: 24px;
        margin: 0 10px;
    }
}
