@import '../../../../styles/variables.scss';
@import '../../../../styles/mixins.scss';

.body {
    @include flexBox(flex-start, flex-start);
    @include flexFlow(wrap);
    min-height: 50px;
    max-width: calc(100% - #{$functionBtnWidth} - #{$deleteBtnWidth});
    padding: 0;
}

.body.actionSectionBody {
    max-width: 50%;
}

.inner {
    @include flexBox();
    @include flexFlow(wrap);
    flex: 1;
}

.trigger_block {
    display: flex;
    border-radius: 4px;
    border: 1px solid transparent;
    border-left: 4px solid $left-border-inValid;
    background: $color-white;
    @include meshbotBoxShadow;
    margin-bottom: 16px;
    width: 100%;
}
.drag_handle {
    background-image: url('../../../../../src/assets/icons/ellipse.svg');

    background-size: 4px;
    background-repeat: repeat;
    width: 8px;
    opacity: 0.4;
}

.fields_block {
    @include flexBox(space-between, flex-start);
    position: relative;
    min-height: 48px;
    width: 100%;
    padding: 5px 10px 0;
}

.disabled_validation {
    border-left-color: transparent;
}

.editing {
    border-left-color: $left-border-saved;
}
.is_valid {
    border-left-color: $left-border-valid;
}

.trigger_block__container {
    @include flexBox(space-between, flex-start);
    flex-wrap: wrap;
    flex-direction: row;
    width: 100%;
    padding: 5px 10px 0;
}
.activeConnection {
    & > .connection {
        &:after,
        &:before {
            display: block;
        }
    }
}
.connection {
    position: relative;
    &:after,
    &:before {
        display: none;
        content: '';
        position: absolute;
        left: -14px;
        width: 14px;
        height: calc(50% + 12px);
        border-color: $trigger_connection_color;
        border-style: solid;
        box-sizing: border-box;
    }
    &:before {
        top: -10px;
        left: -17px;
        border-width: 0 0 2px 2px;
    }
    &:after {
        top: 50%;
        border-width: 0 0 0 2px;
        left: -17px;
    }
    &:last-child {
        &:after {
            display: none;
        }
        &:before {
            border-radius: 0 0 0 4px;
            left: -17px;
        }
    }
}
.activeConnection_exception {
    @extend .activeConnection;
    padding: 0 0 0 20px !important;
}

.mesh_bot__group {
    background-color: $trigger_group_bg;
    border-radius: 5px;
    padding: 8px 15px 16px 20px;
    margin-bottom: 16px;
    @include meshbotBoxShadow;
    &.connection {
        border-left: 4px solid $left-border-inValid;
    }
    &.connection.is_valid {
        border-left: 4px solid $left-border-valid;
    }
    &.connection.editing {
        border-left: 4px solid $left-border-saved;
    }
    &:last-child {
        &:after {
            display: none;
        }
    }
}

.mesh_bot__group_operators_wrap {
    @include flexBox();
    position: relative;
    @include meshbotBoxShadow;
}
.mesh_bot__group_operators_inner {
    @include flexBox();
    gap: 1px;
}
.show {
    &:after {
        display: block;
    }
}
.mesh_bot__group_operators {
    @include flexBox();
    gap: 1px;
}

.mesh_bot__group_header {
    @include flexBox(space-between, flex-end);
    flex-wrap: wrap;
    margin-bottom: 10px;
}
.mesh_bot__group_block {
    @include flexBox(inherit, flex-end);
    margin-right: 10px;
    .group_name {
        margin-left: 15px;
        :global {
            .MuiInputBase-root .MuiInputBase-input {
                font-weight: 500;
            }
        }
    }
}

.mesh_bot__group_buttons {
    @include flexBox(inherit, center);
    gap: 15px;

    button {
        height: 40px;
        text-transform: capitalize;
        color: $text-color-black;
    }

    :global {
        .MuiButton-outlined {
            margin-right: 10px;
            padding: 4px 10px;
        }
        .MuiIconButton-colorSecondary {
            color: $color-button-grey-3;
        }
        .MuiSvgIcon-root {
            font-size: 24px;
        }
        .MuiIconButton-root {
            padding: 7px;
        }
    }
}

.collapse_button {
    button {
        svg {
            fill: $text-color-black;
        }
    }
}

.mesh_bot__group_buttons_header {
    justify-content: flex-end;
    button {
        width: 40px;
    }
}

.mesh_bot__group_deleteBtn {
    svg {
        width: 25px;
        height: 25px;
    }
}
