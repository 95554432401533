@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.wrapper {
    display: inline-flex;
    align-items: center;
    padding: 0 15px;
    border: 1px solid $delay-block-border;
    border-top: none;
    border-bottom: none;
    margin-right: 25px;
    height: 45px;

    .label {
        font-size: 12px;
        color: $color-black;
        text-transform: capitalize;
    }
    :global {
        .MuiSwitch-root {
            width: 54px;
        }
        .MuiSwitch-thumb {
            width: 10px;
            height: 10px;
        }
        .MuiSwitch-track {
            background: rgba($color-black, 0.54);
            opacity: 1;
        }

        .Mui-checked + .MuiSwitch-track {
            opacity: 1 !important;
        }
        .MuiSwitch-switchBase {
            top: 50%;
            transform: translate(0, -51%);
            left: 6px;
            &.Mui-checked {
                transform: translate(14px, -51%);
                color: $color-white;
            }
        }
    }
}
