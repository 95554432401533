@import '../../../../../styles/variables.scss';
@import '../../../../../styles/mixins.scss';

.wrapper {
    .select {
        @include selectStyle();
    }
}

.footer {
    width: 100%;
    padding: 0px 0 10px;
}

.footer_group {
    @include flexBox(inherit, flex-end);
    .button {
        min-width: 122px;
        color: #fff;
        font-size: 14px;
        padding: 10px 20px;
    }
    .button.MuiButton-contained.Mui-disabled {
        color: rgba(0, 0, 0, 0.26);
    }
}

.text_area {
    width: 100%;
}
