@import '../../styles/mixins.scss';

.menuItem {
    :global {
        .MuiTypography-body1 {
            font-size: 14px;
            font-family: inherit;
        }

        .MuiListItemIcon-root {
            min-width: 40px;
        }
    }
}

.limitWrapper {
    display: inline-block;
}

.area {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    margin-top: 20px;

    :global {
        .MuiInput-root {
            width: 100%;
        }

        .MuiInput-multiline {
            textarea {
                @include inputScrollbar;
                overflow: auto !important;
                resize: vertical;
                min-height: 164px;
                height: 164px;
                background-color: $trigger_group_bg;
                border: 2px solid #90a0af80;
                padding: 15px;
                max-height: 100vh;
            }
        }

        .MuiInput-underline:before {
            display: none;
        }
    }
}

.subject {
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;
    margin-top: 5px;
    max-width: 450px;
    width: 100%;

    :global {
        .input-block {
            max-width: 450px;
            width: 100%;
            margin-right: 0;
        }
    }

    input {
        border-bottom: 2px solid #90a0af80;
    }
}

.label {
    padding-right: 20px;
    font-size: 14px;
}

.body_wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;
    margin-top: 5px;
    width: 100%;

    :global {
        .MuiOutlinedInput-multiline {
            padding: 18.5px 17px;
        }

        .input-block {
            max-width: 450px;
            width: 100%;
            margin-right: 0;
        }
    }
}

.body_label {
    width: 70px;
    align-self: flex-start;
    margin-top: 5px;
    padding-right: 20px;
}

.button_wrapper {
    margin: 0 20px 0 0;

    .button {
        margin-top: 10px;
        padding: 5px;
        font-size: 12px;
    }
}

.character_information {
    color: $text-color-dark-grey;
    font-size: 12px;
    padding: 0 0 15px 0;

    p {
        margin: 0;
    }

    span {
        color: $text-color-blue;
    }
}
