@import '../../../../../styles/variables';
@import '../../../../../styles/fonts';

.newUserBtn {
    min-width: 179px;
    height: 5.6rem;
    @media (max-width: $break-m-m) {
        height: 4.6rem;
    }

    svg {
        fill: #ffffff;
        width: 24px;
        height: 24px;
        margin: 0 10px;
        @media (max-width: $break-m-m) {
            width: 14px;
            height: 14px;
        }
    }
    span {
        text-transform: none;
        @include font-medium();
        font-size: 16px;
        display: flex;
        @media (max-width: $break-m-m) {
            font-size: 14px;
        }
    }
}
