@import '../../../../../../../styles/variables.scss';

.moreButtonWrapper {
    max-width: 500px;
    padding: 0 20px;
    display: flex;
    justify-content: end;
    .moreButton {
        &:global(.MuiButton-root) {
            width: 100%;
            margin-left: 20px;
            font-size: 14px;
        }
        :global(.MuiButton-endIcon) {
            margin-left: 0;
        }
    }
}
