@import '../../../../../styles/fonts';
@import '../../../../../styles/variables';

.title {
    margin-top: 0;
}

.form {
    margin-top: 46px;
    display: flex;
    flex-direction: column;

    .textField {
        font-size: 18px;
        line-height: 22px;
        @include font-regular();

        label {
            font-size: 13px;
            line-height: 16px;
        }

        input,
        textarea {
            padding-top: 4px;
            padding-bottom: 6px;
            font-size: 18px;
            line-height: 22px;
        }

        :global(.MuiFormLabel-root) {
            @include font-regular();
            color: $text-color-dark-grey;
            font-size: 18px;
            line-height: 22px;
        }

        :global(.MuiInputLabel-shrink) {
            color: $text-color-grey;
            //transform: translate(0, 0) scale(0.75) !important;
        }

        :global(.MuiInput-underline:before) {
            border-bottom: 2px solid rgba($text-color-grey, 0.5);
        }

        :global(.MuiInput-underline:after) {
            border-bottom: 2px solid $text-color-black;
        }

        :global(.Mui-error:after) {
            border-bottom-color: $text-color-warning;
        }

        :global(.MuiFormHelperText-root) {
            margin-top: 1px;
            font-size: 12px;
            line-height: 16px;
            @include font-regular();
        }

        :global(.MuiInputAdornment-root) {
            padding-bottom: 2px;
        }
    }

    .toggleIcon {
        height: 24px;
        width: 27px;
        margin: 2px;
    }

    .eye {
        height: 20px;
    }

    .slideContainer {
        height: 200px;
    }

    .submitBtn {
        @include font-normal();
        height: 56px;
        font-size: 16px;
        text-transform: none;
        background-color: $text-color-blue;
        box-shadow: none;

        &:global(.MuiButtonBase-root) {
            margin-top: 32px;
        }
    }
    :global {
        .MuiPaper-rounded {
            border-radius: 16px;
        }
        .MuiPaper-elevation1 {
            box-shadow: 0 8px 16px rgba(144, 160, 175, 0.3);
        }
    }
    @media (max-width: $break-m-s) {
        margin-top: 30px;
    }
}
.inputContainer {
    height: 72px;
    :global {
        .MuiTypography-body1 {
            font-weight: 400;
        }
    }
}
