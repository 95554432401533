@import '../../../styles/variables';

.flag_img {
    width: 20px;
    height: 20px;
    padding: 0px;
    margin: 0px 5px 0px 5px;
    border-radius: 50%;
}
.dropdownchild {
    padding-left: 10px !important;
}

.languageDropDown {
    width: 85%;
    background-color: white;
    box-shadow: 1px 1px 15px rgba(160, 160, 160, 0.3);
    border-radius: 10px;
    padding: 15px;
}

.select_outline {
    padding: 10px;
}

.dropdownContainer {
    @media (min-width: $break-d-x) {
        width: 25%;

        .dropdown {
            .languageName {
                font-size: 1.5rem;
            }
        }
    }

    @media (max-width: $break-d-l) {
        width: 30%;
    }

    @media (max-width: $break-d-s) {
        width: 35%;

        .dropdown {
            .languageName {
                font-size: 1.4rem;
            }
        }
    }

    @media (max-width: $break-m-l) {
        width: 55%;

        .dropdown {
            .languageName {
                font-size: 1.4rem;
            }
        }
    }

    @media (max-width: $break-m-s) {
        width: 70%;

        .dropdown {
            .languageName {
                font-size: 1.4rem;
            }
        }
    }
}

.buttonContainer {
    padding-top: 20px;
    width: 85%;
    text-align: right;
}

.buttonContainer {
    button {
        @media (min-width: $break-d-x) {
            width: 12%;
            font-size: 1.5rem;
        }

        @media (max-width: $break-d-l) {
            width: 14%;
            font-size: 1.4rem;
        }

        @media (max-width: $break-d-s) {
            width: 20%;
            font-size: 1.4rem;
        }

        @media (max-width: $break-m-l) {
            width: 35%;
            font-size: 1.3rem;
        }

        @media (max-width: $break-m-s) {
            width: 45%;
            font-size: 1.2rem;
        }
    }
}
