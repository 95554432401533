@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/fonts';

.container {
    position: relative;
    height: 100vh;
    display: flex;
    justify-content: center;
    .logo {
        position: absolute;
        top: 31px;
        left: 32px;
        display: flex;
        .logoIcon {
            width: $navigation-logo-width;
            height: $navigation-logo-height;
        }
        .logoImg {
            position: relative;
            width: 80px;
            margin-top: -15px;
            height: 60px;
        }
        .logoVersion {
            color: $text-color-dark-grey;
            line-height: 1.4;
            font-size: 16px;
            @include font-normal();
            margin-left: 8px;
        }
    }
    .language {
        position: absolute;
        top: 31px;
        display: flex;
        width: 500px;
        justify-content: right;
        right: 32px;

        @media (max-width: $break-d-l) {
            width: 420px;
            right: 32px;
        }
        @media (max-width: $break-d-s) {
            width: 350px;
            right: 25px;
        }
        @media (max-width: $break-m-l) {
            width: 220px;
            right: 20px;
        }
        @media (max-width: $break-m-s) {
            width: 170px;
        }

        @media (max-width: $break-m-xxs) {
            width: 150px;
            right: 10px;
        }
    }
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 100px;
    color: #1c1e21;
    h1 {
        margin-bottom: 8px;
        font-weight: 600;
        font-size: 32px;
        text-align: center;
    }
    p {
        margin-bottom: 48px;
        font-weight: 400;
        font-size: 16px;
        text-align: center;
        @media (max-width: $break-m-m) {
            width: 80%;
        }
    }
    :global {
        .MuiButton-root {
            width: 392px;
            height: 56px;
            font-family: 'Ezlo Sans';
            font-size: 16px;
            font-weight: 400;
            text-transform: none;
        }
    }
}

@media screen and (max-width: 600px) {
    .content {
        :global {
            .MuiButton-root {
                width: 200px;
            }
        }
    }
}
