@import '../../../../../styles/variables.scss';

.variablesDataType {
    background: $color-white;
    :global {
        .MuiInputBase-root {
            min-width: 200px;
        }
        .MuiFormLabel-root {
            font-size: 12px;
        }
        .MuiInputLabel-formControl {
            left: -2px;
        }
        .MuiInputLabel-shrink {
            transform: translate(14px, -5px) scale(0.95) !important;
        }
        fieldset {
            font-size: 18px;
        }
    }
}
