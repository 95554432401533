@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/fonts';

.mesh_bot__builder {
    width: 100%;
    border-radius: 5px;
    padding: 12px 20px 20px;
    background-color: $color-meshbot-section;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.mesh_bot__builder_trigger {
    border: 2px solid $meshbot-trigger-section;
    border-top: none;
    background-color: $color-trigger-action-background;
}
.mesh_bot__builder_action {
    border: 2px solid $meshbot-action-section;
    border-top: none;
    background-color: $color-trigger-action-background;
}

.mesh_bot__list_triggers {
    padding: 0 0 0 20px;
    margin-bottom: 10px;
    transition: all 0.5s;
}

.mesh_bot__list_action_cloud {
    @extend .mesh_bot__list_triggers;
    padding: 0;
}

.collapse_open {
    .mesh_bot__list_triggers {
        opacity: 1;
        max-height: 1500vh;
        transition: all 0.17s ease;
    }
}
.collapse_close {
    .mesh_bot__list_triggers {
        opacity: 0;
        max-height: 0;
        transition: all 0.2s ease;
    }
    .mesh_bot__group_collapse {
        button {
            svg {
                transform: rotate(-180deg);
            }
        }
    }
}

.mesh_bot__list_actions {
    padding: 17px 0px 17px 0px;
    margin-bottom: 17px;
}
.mesh_bot__list_actions_empty {
    padding: 0;
}

.activeConnection {
    & > .connections {
        &:after,
        &:before {
            display: block;
        }
    }
}

.mesh_bot__tab {
    position: relative;

    &:before {
        position: absolute;
        display: block;
        content: '';
        bottom: 0;
        left: 0;
        width: 100%;
        height: 10px;
        background-color: $meshbot-action-tab-border-false;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
    }
    :global {
        .MuiTabs-indicator {
            background-color: $meshbot-action-tab-border-active;
            height: 10px;
            border-top-left-radius: 4px;
        }

        .MuiTabs-flexContainer {
            justify-content: space-between;
        }
        .MuiTab-root {
            width: 50%;
            padding: 0;
            font-size: 14px;
            font-weight: 600;
            max-width: none;
            min-height: 10px;
            overflow: inherit;
        }
        .MuiTab-wrapper {
            @include font-semibold();
            font-size: 20px;
            color: $text-color-grey;
        }
        .Mui-selected {
            .MuiTab-wrapper {
                color: $meshbot-action-tab-border-active;
            }
        }
    }
}

.trueTab {
    :global {
        .MuiTabs-indicator {
            border-top-left-radius: 0px;
            border-top-right-radius: 4px;
        }
    }
}

.list_actions {
    padding-left: 20px;
}
