@import '../../../../../styles/variables.scss';

.variablesActionTypeInput {
    background-color: $color-white;
    margin-right: 30px !important;
    @media (max-width: $break-d-s) {
        width: 100%;
        max-width: 500px;
        margin-top: 10px !important;
        &:first-of-type {
            margin-right: 10px !important;
        }
        &:last-of-type {
            margin-right: 0 !important;
        }
    }
    :global {
        .MuiFormLabel-root {
            font-size: 12px;
        }
        .MuiInputLabel-formControl {
            left: -2px;
        }
        .MuiInputLabel-shrink {
            transform: translate(14px, -5px) scale(0.95) !important;
        }
        fieldset {
            font-size: 18px;
        }
    }
}
