@import '../../../../../../../../../styles/variables.scss';
@import '../../../../../../../../../styles/mixins.scss';

.dragAndDropContainer {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 50px;
    background: $drop-box-bg-color;
    border: 2px dashed $drop-box-border-color;
    margin: 0 0 0 20px;
    padding: 10px;
    transition: all 0.3s;
    :global {
        .MuiButton-root {
            font-size: 14px;
        }
    }
}

.button {
    @include flexBox(end, end);
}

.dragAndDropText {
    @include flexBox(center, center);
    flex: 1 1 auto;
    font-size: 14px;
    margin-top: 40px;
    margin-bottom: 5px;
    opacity: 0.7;
    height: 100%;
}
