@import '../../../../styles/variables';

.actionsWrapper {
    width: 393px;
    @media (max-width: $break-m-m) {
        width: 350px;
    }
    @media (max-width: $break-m-s) {
        width: 100%;
    }
}
