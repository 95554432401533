@import "../../../../../styles/variables";
@import "../../../../../styles/fonts";
@import "../../../../../styles/mixins";

.controller-settings-dialog {
  @include font-regular();
  font-size: 18px;

  &__title {
    position: relative;
  }

  &__info-description {
    font-size: 13px;
    color: $text-color-dark-grey;
  }

  .dialog__item {
    min-width: 350px;
    padding: 20px 24px;
    border-bottom: 1px solid rgba($text-color-grey, .15);
    &:last-child {
      margin-bottom: 19px;
    }
    &:first-child {
      padding: 15px 24px 20px;
    }
  }
  .dialog-hub-settings {
    .device-hub-settings__wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .device-hub-settings__title {
      text-transform: capitalize;
      font-size: 1.6rem;
      margin-right: 10px;
      margin-bottom: 0;
    }
  }

  &__icon-hide {
    position: absolute;
    top: 15px;
    right: 17px;
    cursor: pointer;
    &.MuiSvgIcon-root {
      width: 20px;
      height: 20px;
    }
  }
}