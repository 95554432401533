@import '../../../../../../../../../styles/variables.scss';
@import '../../../../../../../../../styles/mixins.scss';
@import '../../../../../../../../../styles/fonts.scss';

.container {
    margin: 20px 0;
    display: flex;
    flex-direction: column;
    max-height: 450px;
    gap: 10px;
}

.paas__method {
    @include flexBox(flex-start, center);
    gap: 10px;
    padding: 10px;
    background: $color-white;
    border-radius: 12px;
    width: 100%;
    gap: 10px;
    padding: 10px;
    background: $color-white;
    border-radius: 12px;
    cursor: pointer;
    border: 1px solid rgba($text-color-grey, 0.25);
    box-shadow: 0px 0px 6px 1px rgba($color-black, 0.05);
    transition: box-shadow 0.3s linear;
    max-width: 700px;

    &:hover {
        box-shadow: 0px 0px 6px 1px rgba($color-black, 0.25);
    }
}

.icon {
    width: 20px;
    height: 20px;
}

.summary {
    margin: 0;
    @include font-medium();
    font-size: 16px;
}

.description {
    margin: 0;
    @include font-light();
    font-size: 12px;
}
