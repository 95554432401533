@import '../../../../../styles/variables';

.toolbarButton {
    svg {
        width: 14px;
        margin-left: 3px;
    }
    path {
        fill: $color-primary-theme;
    }
    margin-left: 50px;
}
