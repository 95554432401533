@import '../../../styles/variables.scss';

.ezloOfflineWrapper {
    display: flex;
    justify-content: center;
    margin-left: 280px;
    padding-top: 150px;
    text-align: center;
    transition: margin 0.3s ease;
    @media (max-width: $break-d-burger) {
        margin-left: 0;
    }
}
.ezloOfflineContent {
    padding: 4.4rem 0;
    h1 {
        font-size: 32px;
    }
}
.ezloOfflineHeader {
    @media (min-width: $break-d-burger) {
        justify-content: flex-end;
    }
}
.ezloOfflineIcon {
    display: block;
    margin: 0 auto;
    width: 155px;
    height: 155px;

    :global {
        path {
            &:nth-child(-n + 2) {
                fill: $text-color-warning;
            }
            &:nth-child(n + 3) {
                fill: $text-color-warning-3;
            }
            &:last-child {
                fill: $color-white;
            }
        }
    }
}
.ezloOfflineSubtitle {
    margin-bottom: 48px;
    font-size: 16px;
    color: $text-color-black;
}
.ezloOfflineRemoveBtn {
    padding: 1.2em 10em !important;
    margin-bottom: 24px;
    background: $text-color-warning !important;
    border: none !important;
    border-radius: 4px !important;
    color: $color-white !important;
    font-size: 16px !important;
    &:hover {
        background: $text-color-warning-3 !important;
    }
}
.actionDescription {
    font-size: 16px;
    color: $text-color-dark-grey;
}
