@import '../../../../../styles/variables.scss';

.keyword {
    font-size: 12px;
    color: $color-blue-1;
    border: none;
    background-color: transparent;

    &:hover {
        cursor: pointer;
        color: $color-blue-3;
    }
}
