@import '../../../../../styles/variables.scss';

.content {
    position: relative;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding: 15px 0;

    .serial_wrapper {
        position: absolute;
        right: 0;
        padding-left: 10px;
        background: #fff;
    }

    .inputWrap {
        min-width: 364px;
        padding: 0 10px;
        background-color: $color-white;
        &:after {
            content: '';
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: -43px;
            width: calc(100vw - 281px);
            height: 1px;
            background: rgba(144, 160, 175, 0.15);
            z-index: -1;
            @media (max-width: $break-d-burger) {
                width: 100vw;
            }
            @media (max-width: $break-d-m-meshbot) {
                left: -12px;
            }
        }
    }
}

@media screen and (max-width: $break-d-m) {
    .content {
        padding: 15px 6px;
        .serial_wrapper {
            right: 6px;
        }
    }
}

@media screen and (max-width: $break-t-l) {
    .content {
        flex-wrap: wrap;
        flex-direction: column-reverse;
        justify-content: flex-start;
        align-items: flex-start;
        .serial_wrapper {
            position: static;
            padding-left: 0;
            margin-bottom: 10px;
        }
        .inputWrap {
            padding: 0;
            min-width: auto;
            width: 100%;
            :global {
                .MuiInputBase-input {
                    padding: 15.5px 14px;
                }
            }
            &::before {
                display: none;
            }
        }
    }
}
