@import '../../../../../../../../../styles/variables.scss';
@import '../../../../../../../../../styles/mixins.scss';
@import '../../../../../../../../../styles/fonts';

.dynamicFields {
    width: 100%;
}

.dynamicField {
    margin: 10px 0;
    position: relative;
    p {
        margin-bottom: 10px;
        font-size: 14px;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.85);
    }
    :global {
        .MuiSelect-select {
            padding: 9px 10px 7px;
        }
        .MuiChip-label {
            font-size: 16px;
            @include font-regular();
        }
    }
}

.dynamicFieldAutocomplete {
    :global {
        .MuiAutocomplete-inputRoot {
            padding: 0 39px 0 0;
        }
        .MuiInputBase-input {
            padding: 9px 10px 7px;
        }
    }
}

.dynamicFieldInput {
    margin-right: 0;
}
.numberInput {
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}

.fieldDescription {
    color: $color-inValid;
}

.option_name {
    margin: 0;
    @include autocompleteOption;
}

.select_option {
    margin: 0;
}
