.createNewAssociation {
    color: #1c1e21;
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    width: 100%;
    padding-bottom: 8px;
}
.associationItemData {
    display: flex;
    flex-wrap: wrap;
    margin: 5px -8px;
}

.column {
    flex-basis: 100%;
    margin-bottom: 16px;
    padding: 0 8px;
}

@media (min-width: 768px) {
    .column {
        flex-basis: calc(25% - 16px);
        margin-right: 16px;
        margin-bottom: 16px;

        &:nth-child(4n) {
            margin-right: 0;
        }
    }
}
.sourceDeviceColumn {
    display: flex;
    flex-direction: column;
    margin-bottom: 23px;
}
.deviceName {
    font-family: 'Ezlo Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    display: flex;
    align-items: center;
    color: rgba(28, 30, 33, 0.5);
}
.targetDeviceName {
    font-family: 'Ezlo Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    color: #1c1e21;
}
