@import '../../../../../styles/variables.scss';

.content {
    padding: 0px 0px 0;

    background-color: $color-white;
}

@media screen and (max-width: 940px) {
    .content {
        padding: 30px 6px 0;
    }
}
