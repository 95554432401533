@import '../../../../../styles/variables.scss';

.keywordsTitle {
    display: inline-block;
    margin-right: 5px;
    font-size: 14px;
}

.keywordsUsageDescription {
    font-size: 12px;

    span {
        color: $color-blue-1;
    }
}
