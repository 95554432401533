@import '../../../../../styles/variables.scss';
@import '../../../../../styles/mixins.scss';

.wrapper {
    width: 100%;
    margin: 10px 0;
}

.section {
    margin-bottom: 15px;
    .buttonHeader {
        @include meshbotAdditionButton;
    }
}

.title {
    font-size: 14px;
    margin-bottom: 10px;
}

.input_block {
    max-width: 450px;
    width: 100%;
}

.security {
    margin-top: 10px;
    label {
        margin-right: 0;
        color: $text-color-black;
    }
}

.header_item {
    @include flexBox(inherit, center);
    gap: 20px;
    margin-bottom: 15px;

    :global {
        .input-block {
            margin-right: 0;
        }
    }
}

.header_delete_button {
    align-self: self-end;
    margin-bottom: 4px;
    padding: 0;
    svg {
        width: 15px;
    }
}

.passwordIcon {
    width: 26px;
}
