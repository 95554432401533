@import 'src/styles/variables';
@import 'src/styles/mixins';

.associationsItemData {
    margin: 0;
    align-items: center;
    display: flex;

    .deviceWrapper {
        display: flex;
        align-items: center;

        .dataDevice {
            border: $border-style;
            border-radius: $border-radius;
            color: $text-color-grey-basic-dark;
            width: 320px;
            min-height: 135px;
            padding: 15px;
            margin: 10px 0;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;

            .withControllerSerial {
                color: $text-color-black;
            }

            .withControllerIsConnected {
                color: $text-color-grey-basic-dark;
                font-size: 13px;
            }

            .dataDeviceRow {
                display: flex;
                justify-content: space-between;
                margin: 0;

                .dataDeviceRowControllerInfo {
                    display: flex;
                    align-items: center;
                    position: relative;

                    .dataDeviceRowControllerInfoBlock {
                        position: absolute;
                        left: 120px;
                        top: -15px;
                        padding: 15px 10px;
                        min-width: 210px;
                        box-shadow: 0 8px 24px rgba(144, 160, 175, 0.3);
                        border-radius: 12px;
                        display: none;
                        font-size: 13px;
                        background-color: $color-white;
                        color: $text-color-basic-black;

                        p {
                            margin: 0;
                        }
                    }

                    .dataDeviceRowControllerInformation {
                        display: inline-flex;
                        background-color: #3f51b5;
                        padding: 5px 10px;
                        border-radius: 50%;
                        color: #fff;
                        font-size: 16px;
                        cursor: pointer;
                        align-items: center;
                        justify-content: center;
                        width: 20px;
                        height: 20px;

                        &:hover {
                            & + .dataDeviceRowControllerInfoBlock {
                                display: block;
                                z-index: 2;
                                cursor: pointer;
                            }
                        }
                    }

                    .dataDeviceRowControllerInfoNumber {
                        font-size: 13px;
                        margin: 0 15px 0 0;
                        display: flex;
                        align-items: center;

                        .dataDeviceRowControllerInfoNumberStatus {
                            display: inline-flex;
                            width: 7px;
                            height: 7px;
                            border-radius: 50%;
                            background-color: $color-online;
                            margin: 0 7px 0 0;

                            .dataDeviceRowControllerInfoNumberStatusOffline {
                                background-color: $text-color-warning;
                            }
                        }
                    }
                }
            }

            .dataDeviceCol {
                display: flex;
                flex-direction: row;
                line-height: 1.3;
                margin: 0;
                min-height: 103px;
                justify-content: space-between;

                .dataDeviceColName {
                    color: $text-color-basic-black;
                    margin: 5px 0 0 0;
                    display: block;
                    display: -webkit-box;
                    font-weight: 500;
                    line-height: 20px;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    word-break: break-word;

                    span {
                        display: block;
                        box-sizing: border-box;
                        border-bottom: 2px solid transparent;
                    }
                }

                .dataDeviceColNameAssignedDevices {
                    font-size: 13px;
                    margin-bottom: 0;
                }

                .controllerIconWrapper {
                    position: relative;

                    svg {
                        width: 25px;
                        height: 25px;
                    }

                    &:before {
                        position: absolute;
                        top: -5px;
                        left: 20px;
                        z-index: 1;
                        content: '';
                        border-radius: 50%;
                        border: 2px solid white;
                        box-sizing: content-box;
                        width: 6px;
                        height: 6px;
                        background-color: #6ba543;
                    }

                    &.controllerOffline {
                        background-color: #fe5c15;
                    }
                }

                .deviceIcon {
                    fill: $color-blue-3;
                    width: 25px;
                    height: 25px;
                }
                @media screen and (min-width: 768px) {
                    flex-direction: column;

                    .deviceIcon,
                    .dataDeviceColName {
                        margin-right: 10px;
                    }
                }
            }
        }

        .associateWith {
            margin: 15px;
            padding: 0;
        }
    }

    .deleteBtn {
        margin: 0 0 0 25px;
    }
}

.iconHide {
    position: absolute;
    top: 4px;
    right: 4px;
    cursor: pointer;
}
