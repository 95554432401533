@import '../../../../../styles/variables';

.device-alarm__text {
    font-size: 1.5rem;
    color: #393939;
    margin-bottom: 20px;
}

.device-range-slider__box {
    position: relative;
    max-width: 300px;
    margin-bottom: 20px;
    padding-bottom: 10px;
}

.radio-button label {
    margin-left: 0;
}

.radio-button.content-left {
    width: 300px;
    max-width: 300px;
    border-bottom: 1px solid #cccccc;
    display: flex;
    justify-content: space-between;
    padding-top: 10px;
    padding-bottom: 10px;
}

.device-range-slider {
    width: 100%;
}

.device-range__button {
    background-color: #e3e3e3;
    border: 0;
    margin-right: 20px;
}

.device-range-footer {
    margin-bottom: 10px;
}

.device-range-restore {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
}

.device-range-start {
    position: absolute;
    left: 0;
    bottom: 0;
}

.device-range-finish {
    position: absolute;
    right: 0;
    bottom: 0;
}

.device-alarm__item {
    margin: 20px 0;
}

.device-alarm__key {
    color: #575d70;
    font-size: 17px;
}

.device-alarm__value {
    color: $additional-text-color;
    font-size: 18px;
    font-weight: 600;
}

.btn-basic.small.device-range__button {
    color: #fff;
}

.btn-rounded.device-range__button-save {
    color: #fff;
    &:hover {
        color: #fff;
    }
}
